import React, { FC } from 'react';
import { getGoogleMerchant, deleteGoogleMerchant, postGoogleMerchant, putGoogleMerchant } from '@/service';
import { CrudList } from "@/component/crud-list";
import { GoogleMerchantColumns, googleMerchantFilters, adaptGoogleMerchantCreation, googleMerchantFormFields } from './config';
import { GoogleMerchant, GoogleMerchantQuery, GoogleMerchantCreation } from '@/interface';
import { GoogleMerchantStatus } from "@/enum";

const GoogleMerchantList: FC = () => (
  <CrudList<GoogleMerchant, GoogleMerchantCreation, GoogleMerchantQuery>
    title="GoogleMerchant"
    rowKey="id"
    auditable
    filters={googleMerchantFilters}
    columns={GoogleMerchantColumns}
    read={getGoogleMerchant}
    deletable={({ status }) => status !== GoogleMerchantStatus.Delete}
    del={({ id }, actionConfig) => deleteGoogleMerchant({
      configId: id,
      ...actionConfig,
    })}
    update={({ id }, creation) => putGoogleMerchant({
      configId: id,
      data: creation
    })}
    create={postGoogleMerchant}
    adaptCreation={adaptGoogleMerchantCreation}
    fields={googleMerchantFormFields}
    tableScroll={{
      x: 960
    }}
  />
);

export default GoogleMerchantList;