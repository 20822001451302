import { ImageUploaderSrc } from '@/interface';
import { RichContentImg } from '@/interface/rich-content';
import { ObjectUtils } from '@reversible/common/es/utils';
import { Modal } from 'antd';
import React, { FC, useMemo } from 'react';
import { CrudListFormItemConfig } from '../crud-list';
import { CreateUpdateForm } from '../crud-list/create-update-form';
import { FormModalFooter } from '../form-modal-footer';
import { RichContentModalProps } from './interface';
import { t } from "@/lang";
import { useSlate } from 'slate-react';
import { Transforms, Editor } from 'slate';
import { getFirstMatchedNode, isElementOfType } from './utils';
import { uploadImage } from '@/service';
import { useFlow } from '@reversible/common/es/react-hooks';

export type ImgModalProps = RichContentModalProps<RichContentImg>;

interface ImgFormValues {
  src: ImageUploaderSrc;
  alt: string;
  caption?: string;
}

const FORM_ID = 'rich-content-img-form';

export const ImgModal: FC<ImgModalProps> = ({ data, onClose }) => {
  const editor = useSlate();
  const { selection } = editor;
  const [isEditing, initialValues]: [boolean, ImgFormValues] = useMemo(() => {
    if (data.src) {
      return [true, ObjectUtils.pick(data, ['src', 'alt', 'caption'])];
    }

    return [false, {
      src: null,
      alt: '',
      caption: '',
    }];
  }, []);

  const fields = useMemo((): CrudListFormItemConfig<ImgFormValues>[] => {
    return [{
      name: 'src',
      label: t('form.src'),
      disabled: isEditing,
      type: 'image',
    },
    {
      name: 'alt',
      label: t('form.alt'),
      type: 'input',
    },
    {
      name: 'caption',
      label: t('form.caption'),
      optional: true,
      type: 'input',
    }]
  }, [isEditing]);

  const getFileRatio = (file) => {
    return new Promise((resolve) => {
      let reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = (data) => {
        let img = new Image()
        img.src = data.target.result as string
        img.onload = () => {
          resolve(Math.round(img.height / img.width * 100) / 100)
        }
      }
    })
  }

  const [{ loading }, dispatch] = useFlow<void, ImgFormValues>(null, function* ({ call }, values) {
    const image = {
      ...ObjectUtils.pick(values, ['src', 'alt', 'caption']),
      type: 'img',
      children: [{ text: '' }],
    };

    if (isEditing) {
      const match = getFirstMatchedNode(editor, n => isElementOfType('img', n));

      if (!match) return;

      image['ratio'] = match[0]['ratio']; // CHECK:

      Transforms.setNodes(editor, image);
    } else {
      if (values.src instanceof File) {
        const s3url = yield call(uploadImage, { 'file': values.src });
        const ratio = yield call(getFileRatio, values.src);
        image['src'] = s3url;
        image['ratio'] = ratio;
      }
      const match_node = Editor.node(editor, editor.selection);
      if (match_node[0]['text'] == '') {
        Transforms.removeNodes(editor)
      }
      Transforms.insertNodes(editor, image);
    }

    onClose()
  });

  return (
    <Modal title={isEditing ? '编辑图片' : '插入图片'} visible onCancel={onClose} footer={
      <FormModalFooter form={FORM_ID} onCancel={onClose} loading={loading} />
    } >
      <CreateUpdateForm<ImgFormValues>
        id={FORM_ID}
        initialValues={initialValues}
        fields={fields}
        onFinish={dispatch}
        labelAlign="left"
        labelCol={{
          span: 3
        }}
      />
    </Modal>
  )
};
