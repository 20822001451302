import React from 'react';
import { Modal } from 'antd';
import { ActionConfig } from '@/interface';
import { useFlow } from '@@/react-hooks';
import { CrudListProps } from './interface';

export interface DeleteModalProps<T extends object>
  extends Required<Pick<CrudListProps<T, any, any>, 'title' | 'auditable' | 'del'>> {
  visible: boolean;
  data: T;
  onReload(): void;
  onClose(): void;
}

export function DeleteModal<T extends object>({
  visible,
  data,
  title,
  del,
  onReload,
  onClose
}: DeleteModalProps<T>) {
  const [{ loading }, dispatch] = useFlow<void, ActionConfig>(null, function* ({ call, block }, config) {
    yield block();
    yield call(del, data, config);
    onReload();
    onClose();
  });

  return (
    <Modal
      visible={visible}
      title={<>删除 {title}</>}
      onCancel={onClose}
      onOk={() => dispatch({})}
      confirmLoading={loading}
      okButtonProps={{
        danger: true,
      }}
    >
      确定要删除该{title}吗？
    </Modal>
  )
} 