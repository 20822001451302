import React, { FC } from 'react';
import { deleteProduct, getProducts, postProduct, putProduct, postSpuLock, deleteSpuLock } from '@/service';
import { CrudList } from "@/component/crud-list";
import { adaptProductCreation, productColumns, productFilters, productFormFields } from './config';
import { Product, ProductCreation, ProductsQuery } from '@/interface';
import { SpuStatus } from '@reversible/schema';
import { SpuAttributeLock } from "@/enum";

const lock_attr = [SpuAttributeLock.INFO, SpuAttributeLock.IMAGE, SpuAttributeLock.DESCRIPTION]

const updateProduct = async (id, creation) => {
  if (creation.spuLock) {
    await deleteSpuLock({ spuId: id, lockAttr: lock_attr })
    await putProduct({ spuId: id, data: creation })
    return postSpuLock({
      spuId: id,
      lockAttr: lock_attr
    })
  } else {
    await deleteSpuLock({ spuId: id, lockAttr: lock_attr })
    return putProduct({
      spuId: id,
      data: creation
    })
  }
}

const ProductList: FC = () => (
  <CrudList<Product, ProductCreation, ProductsQuery>
    title="商品"
    rowKey="id"
    auditable
    isRequests={false}
    filters={productFilters}
    columns={productColumns}
    read={getProducts}
    del={({ id }, actionConfig) => deleteProduct({
      spuId: id,
      ...actionConfig,
    })}
    deletable={({ spuStatus }) => spuStatus !== SpuStatus.Deleted}
    update={({ id }, creation) => updateProduct(id, creation)}
    create={postProduct}
    adaptCreation={adaptProductCreation}
    fields={productFormFields}
    tableScroll={{
      x: 1400
    }}
  />
);

export default ProductList;

