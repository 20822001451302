import SparkMD5 from "spark-md5";
import { uniqueId } from "@@/utils";


export const getFileMD5 = (file: File): Promise<string> =>
  new Promise<string>((resolve, reject) => {
    const CHUNK_SIZE = 2097152; // Read in chunks of 2MB
    const chunkCount = Math.ceil(file.size / CHUNK_SIZE);
    const spark = new SparkMD5.ArrayBuffer();
    const fileReader = new FileReader();
    let currentChunk = 0;

    const loadNext = () => {
      const start = currentChunk * CHUNK_SIZE;
      const end =
        start + CHUNK_SIZE >= file.size ? file.size : start + CHUNK_SIZE;

      fileReader.readAsArrayBuffer(file.slice(start, end));
    };

    fileReader.onload = (e) => {
      spark.append(e.target.result);
      currentChunk++;

      if (currentChunk < chunkCount) {
        loadNext();
      } else {
        const hash = spark.end();

        resolve(hash);
      }
    };

    fileReader.onerror = (e) => {
      reject(e);
    };

    loadNext();
  });

/**
 * use a weak map to cache blob key globally
 */
const blobKeyWeakMap = new WeakMap<Blob, string>();

export const getBlobKey = (blob: Blob): string => {
  if (blobKeyWeakMap.has(blob)) {
    return blobKeyWeakMap.get(blob);
  }
  const uniqueKey = uniqueId();
  blobKeyWeakMap.set(blob, uniqueKey);
  return uniqueKey;
};
