import React, { FC, Fragment, ReactNode, useMemo } from 'react';
import styles from './json-display.module.less';;

export interface JSOnDisplayProps {
  data: any;
}

export const JSONDisplay: FC<JSOnDisplayProps> = ({
  data
}) => {
  const str = useMemo((): ReactNode => {
    try {
      const rows = JSON.stringify(data, null, 4).split('\n');
      return <>
        {
          rows.map((row, i) => (
            <div className={styles.row} key={i + 1}>
              <i className={styles.index}>{i + 1}</i>
              {row}
              <br />
            </div>
          ))
        }
      </>
    } catch {
      return <i>unable to display</i>;
    }
  }, [data]);

  return (
    <pre className={styles.wrapper} >
      <code>
        {str}
      </code>
    </pre>
  )
}
