import React, { FC } from 'react';
import { getCampaign, deleteCampaign, postCampaign, putCampaign } from '@/service';
import { CrudList } from "@/component/crud-list";
import { campaignColumns, campaignFilters, adaptCampaignCreation, campaignFormFields } from './config';
import { Campaign, CampaignQuery, CampaignCreation } from '@/interface';
import { CampaignStatus } from '@/enum';

const getFileRatio = (file) => {
  return new Promise((resolve) => {
    let reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = (data) => {
      let img = new Image()
      img.src = data.target.result as string
      img.onload = () => {
        resolve(Math.round(img.height / img.width * 100) / 100)
      }
    }
  })
}

const createCampaign = async (data) => {
  if (data.wideDetailImageUrl instanceof File) {
    data.wideDetailImageRatio = await getFileRatio(data.wideDetailImageUrl)
  }
  if (data.narrowDetailImageUrl instanceof File) {
    data.narrowDetailImageRatio = await getFileRatio(data.narrowDetailImageUrl)
  }
  return postCampaign(data)
}

const updateCampaign = async (id, creation) => {
  if (creation.wideDetailImageUrl instanceof File) {
    creation.wideDetailImageRatio = await getFileRatio(creation.wideDetailImageUrl)
  }
  if (creation.narrowDetailImageUrl instanceof File) {
    creation.narrowDetailImageRatio = await getFileRatio(creation.narrowDetailImageUrl)
  }
  return putCampaign({
    campaignId: id,
    data: creation
  })
}

const CampaignList: FC = () => (
  <CrudList<Campaign, CampaignCreation, CampaignQuery>
    title="campaign"
    rowKey="id"
    auditable
    filters={campaignFilters}
    columns={campaignColumns}
    read={getCampaign}
    deletable={({ status }) => status !== CampaignStatus.Unavailable}
    del={({ id }, actionConfig) => deleteCampaign({
      campaignId: id,
      ...actionConfig,
    })}
    update={({ id }, creation) => updateCampaign(id, creation)}
    create={(data) => createCampaign(data)}
    adaptCreation={adaptCampaignCreation}
    fields={campaignFormFields}
    tableScroll={{
      x: 960
    }}
  />
);

export default CampaignList;