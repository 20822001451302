import React, { useCallback, useMemo } from 'react';
import { Form } from 'antd';
import { CrudListFormConfig } from './interface';
import { FormItemCore } from './form-item';
import styles from './crud-list.module.less';
import { ObjectUtils } from '@reversible/common/es/utils';

export interface CrudFilterItemProps<T> extends CrudListFormConfig<T> {
  filters: T;
  onChangeFilters(overrides: Partial<T>): void;
}

export function CrudFilterItem<T>(props: CrudFilterItemProps<T>) {
  const {
    name,
    label,
    filters,
    onChangeFilters,
    deps = [],
  } = props;

  const value = filters[name] as any;
  const onChange = useCallback((value: any) => onChangeFilters({
    [name]: value,
  } as Partial<T>), [onChangeFilters]);

  const depsMapped = deps.map(key => filters[key]);
  const depValues = useMemo(() => ObjectUtils.pick(filters, deps), depsMapped);

  return (
    <Form.Item label={label} className={styles.filter_form_item} >
      <FormItemCore value={value} onChange={onChange} depValues={depValues} {...ObjectUtils.pick(props, ['type', 'options', 'debounce', 'render'])} />
    </Form.Item>
  )
}