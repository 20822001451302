import React from 'react';
import { CrudListColumnConfig, CrudListFormConfig } from "@/component/crud-list";
import { GoogleMerchant, GoogleMerchantCreation, GoogleMerchantQuery } from "@/interface";
import { t } from "@/lang";
import { GOOGLE_MERCHANT_STATUSES, GoogleMerchantStatus, GOOGLE_MERCHANT_TYPE } from "@/enum";
import { ObjectUtils } from "@@/utils";
import { CrudListFormItemConfig } from "@/component/crud-list";

export const googleMerchantFilters: CrudListFormConfig<GoogleMerchantQuery>[] = [
  {
    name: 'type',
    label: '类型',
    type: 'select',
    options: GOOGLE_MERCHANT_TYPE.map(value => ({
      label: t(`value.googleMerchantType.${value}`),
      value,
    })),
  },
  {
    name: 'value',
    label: 'value',
    type: 'input',
  },
  {
    name: 'status',
    label: '状态',
    type: 'select',
    options: GOOGLE_MERCHANT_STATUSES.map(value => ({
      label: t(`value.googleMerchantStatus.${value}`),
      value,
    })),
  },
]

export const GoogleMerchantColumns: CrudListColumnConfig<GoogleMerchant>[] = [
  {
    title: 'ID',
    width: 50,
    dataIndex: 'id',
    fixed: 'left',
  },
  {
    title: '类型',
    width: 100,
    dataIndex: 'type',
    display: type => t(`value.googleMerchantType.${type}`),
  },
  {
    title: 'value',
    width: 120,
    dataIndex: 'value',
  },
  {
    title: '状态',
    width: 90,
    dataIndex: 'status',
    display: status => t(`value.googleMerchantStatus.${status}`),
  },
  {
    title: '创建时间',
    width: 160,
    dataIndex: 'createTime',
    display: 'time',
  },
  {
    title: '更新时间',
    width: 160,
    dataIndex: 'updateTime',
    display: 'time',
  },
];

export const adaptGoogleMerchantCreation = (data?: GoogleMerchant): GoogleMerchantCreation => data ? ObjectUtils.pick(
  data,
  [
    'type',
    'value',
    'status',
    'createTime',
    'updateTime',
    'id',
  ]
) : {
  type: undefined,
  value: '',
  status: GoogleMerchantStatus.Available,
  createTime: undefined,
  updateTime: undefined,
  id: undefined,
}

export const googleMerchantFormFields: CrudListFormItemConfig<GoogleMerchantCreation>[] = [
  {
    divider: 'googleMerchant 信息',
  },
  {
    name: 'type',
    label: '类型',
    type: 'select',
    options: GOOGLE_MERCHANT_TYPE.map(value => ({
      value,
      label: t(`value.googleMerchantType.${value}`),
    })),
  },
  {
    name: 'value',
    label: 'value',
    type: 'input',
  },
  {
    name: 'status',
    label: '状态',
    type: 'select',
    options: GOOGLE_MERCHANT_STATUSES.map(value => ({
      value,
      label: t(`value.googleMerchantStatus.${value}`),
    })),
  },

]

