import { OfferStatus, SpuStatus, OrderStatus } from '@reversible/schema';

export * from '@reversible/schema';


export enum AuthStatus {
  Unknown,
  LoggedIn,
  NotLoggedIn,
}

export enum ActionStatus {
  Wait = 'WAIT',
  Pass = 'PASS',
  Auto = 'AUTO',
  Reject = 'REJECT',
  Launch = 'LAUNCH',
}

export const ACTION_STATUSES = [
  ActionStatus.Wait,
  ActionStatus.Pass,
  ActionStatus.Auto,
  ActionStatus.Reject,
]

export const OFFER_STATUSES = [
  OfferStatus.Available,
  OfferStatus.Unavailable,
  OfferStatus.Deleted,
  OfferStatus.Rejected,
  OfferStatus.Completed,
  OfferStatus.Expired,
  OfferStatus.Preapproved,
  OfferStatus.Removed,
]

export const SPU_STATUSES = [
  SpuStatus.Available,
  SpuStatus.Unclassified,
  SpuStatus.Unavailable,
  SpuStatus.PendingAsk,
  SpuStatus.PendingBid,
  SpuStatus.Deleted,
]

export enum AuditStatus {
  Wait = 0,
  Pass = 1,
  Reject = 2,
}

export enum ReportType {
  Spu = 0,
  Offer = 1,
  Post = 2,
}

export const AUDIT_STATUSES = [
  AuditStatus.Wait,
  AuditStatus.Pass,
  AuditStatus.Reject,
]

export enum ActionType {
  Create = 'CREATE',
  Update = 'UPDATE',
  Delete = 'DELETE',
}

export const ACTION_TYPES = [
  ActionType.Create,
  ActionType.Update,
  ActionType.Delete,
]

export enum AuthorityId {
  Member = 1,
  Admin = 2
}

export const AUTHORITY_IDS = [
  AuthorityId.Member,
  AuthorityId.Admin,
];

export enum SpuAttributeLock {
  IMAGE = 0,
  DESCRIPTION = 1,
  STYLE_ID = 2,
  INFO = 3,
}

export enum BlogStatus {
  Available = 1,
  Deleted = 0,
}

export enum CopyStatus {
  Available = 1,
  Deleted = 0,
}

export const BLOG_STATUSES = [
  BlogStatus.Available,
  BlogStatus.Deleted
]

export const COPY_STATUSES = [
  CopyStatus.Available,
  CopyStatus.Deleted
]

export const ORDER_STATUSES = [
  OrderStatus.PaymentPending,
  OrderStatus.PendingCapture,
  OrderStatus.ShipmentPending,
  OrderStatus.DeliveryPending,
  OrderStatus.Completed,
  OrderStatus.Cancelled,
  OrderStatus.RefundPending,
  OrderStatus.Refunded,
  OrderStatus.PartialRefunded,
]

export enum OmApiResponseCode {
  UserInteractionsErrorCode = 499,
  CombineSpuBrandErrorCode = 498
}

export enum CampaignStatus {
  Available = 1,
  Unavailable = 0,
  Expired = 2
}

export enum CampaignType {
  Look = 0
}

export const CAMPAIGN_STATUSES = [
  CampaignStatus.Available,
  CampaignStatus.Unavailable,
  CampaignStatus.Expired,
]

export const CAMPAIGN_TYPE = [
  CampaignType.Look
]

export enum TopicStatus {
  Available = 1,
  Unavailable = 0,
}

export const TOPIC_STATUSES = [
  TopicStatus.Available,
  TopicStatus.Unavailable,
]

export enum GoogleMerchantStatus {
  Available = 1,
  Delete = 0,
}

export const GOOGLE_MERCHANT_STATUSES = [
  GoogleMerchantStatus.Available,
  GoogleMerchantStatus.Delete,
]

export enum GoogleMerchantType {
  BrandBlackList = 0,
  BrandWhiteList = 1,
  SpuIdBlackList = 2,
  SpuIdWhiteList = 3,
  UserIdBlackList = 4,
  UserIdWhiteList = 5,
}

export const GOOGLE_MERCHANT_TYPE = [
  GoogleMerchantType.BrandBlackList,
  GoogleMerchantType.BrandWhiteList,
  GoogleMerchantType.SpuIdBlackList,
  GoogleMerchantType.SpuIdWhiteList,
  GoogleMerchantType.UserIdBlackList,
  GoogleMerchantType.UserIdWhiteList,
]

export const DESIGNER_STYLE_WOMEN = [
  'Luxury',
  'Artistic',
  'Avant Garde',
  'Streetwear',
  'Casual',
  'Classic',
  'Utilitarian',
  'Gothic',
  'Athleisure',
  'Glam',
  'Feminine',
  'Bohemian',
  'Baroque',
  'Grunge',
  'Punk',
  'Minimalism',
  'Maximalism',
  'Preppy',
  'Eveningwear',
  'Eclectic',
]

export const DESIGNER_STYLE_MEN = [
  'Luxury',
  'Avant Garde',
  'Streetwear',
  'Techwear',
  'Gothic',
  'Glam Rock',
  'Artistic',
  'Athleisure',
  'Classic',
  'Bohemian',
  'Punk',
  'Grunge',
  'Minimalism',
  'Denim',
  'Workwear',
  'Preppy',
  'Maximalism',
  'Utilitarian',
  'Eclectic',
  'Casual',
]