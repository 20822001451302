import React, { FC, ReactNode, useContext, useMemo } from 'react';
import { RichContentElement, RichContentElementType, RichContentImg, RichContentLeaf, RichContentLink, RichText } from '@/interface/rich-content';
import { Button } from 'antd';
import { Editor, Transforms, Element as SlateElement, Range, BaseText } from 'slate';
import { useSlate } from 'slate-react';
import styles from './rich-content-editor.module.less';
import { editContext } from './edit-context';
import { getFirstMatchedNode, getSelectedText, isElementActive, isElementOfType, isLink } from './utils';
import { ObjectUtils, prevent } from '@reversible/common/es/utils';
import { CodeOutlined, DeleteOutlined, DisconnectOutlined, EditOutlined, LinkOutlined, PictureOutlined } from '@ant-design/icons';

export const ToolbarButton: FC<{
  className?: string,
  active?: boolean;
  disabled?: boolean;
  onClick(): void;
}> = ({
  className,
  disabled = false,
  active = false,
  children,
  onClick
}) => (
    <Button
      type={active ? 'primary' : 'default'}
      disabled={disabled}
      className={className}
      onClick={prevent(onClick)}
    >
      {children}
    </Button>
  );

const ElementButton: FC<{
  type: RichContentElementType,
  children: ReactNode;
}> = ({
  type,
  children,
}) => {
    const editor = useSlate();

    const isActive = isElementActive(editor, type);

    return (
      <ToolbarButton
        active={isActive}
        onClick={() => Transforms.setNodes<SlateElement>(editor, {
          type: isActive ? 'p' : 'h2',
        } as Partial<RichContentElement>)
        }
      >
        {children}
      </ToolbarButton>
    );
  }


// const { createOrEditDescendant } = useContext(editContext);

const Title2Button: FC = () => (
  <ElementButton type="h2" >
    H2
  </ElementButton>
)

const LinkAddButton: FC = () => {
  const editor = useSlate();

  const { createOrEditDescendant } = useContext(editContext);

  return (
    <ToolbarButton onClick={() => {
      createOrEditDescendant({
        text: getSelectedText(editor),
        href: '',
      })
    }}>
      <LinkOutlined /> 添加链接
    </ToolbarButton>
  );
}

const EmbeddedAddButton: FC = () => {
  const { createOrEditDescendant } = useContext(editContext);
  return (
    <ToolbarButton onClick={() => {
      createOrEditDescendant({
        type: 'embedded',
        html: '',
        children: [{ text: '' }]
      })
    }}>
      <CodeOutlined /> 插入内嵌代码
    </ToolbarButton>
  )
}

const ImgAddButton: FC = () => {
  const { createOrEditDescendant } = useContext(editContext);

  return (
    <ToolbarButton onClick={() => createOrEditDescendant({
      type: 'img',
      alt: '',
      src: '',
      ratio: undefined,
      children: [{ text: '' }]
    })}>
      <PictureOutlined /> 插入图片
    </ToolbarButton>
  )
}

const ImgEditButtons: FC = () => {
  const { createOrEditDescendant } = useContext(editContext);

  const editor = useSlate();

  const match = getFirstMatchedNode(editor, n => isElementOfType('img', n));

  return match ? (
    <>
      <ToolbarButton onClick={() => createOrEditDescendant(match[0] as RichContentImg)} >
        <EditOutlined /> 编辑图片
      </ToolbarButton>
      <ToolbarButton onClick={() => {
        Transforms.removeNodes(editor, { at: match[1] })
        const new_node = {
          type: 'p',
          children: [{ text: '' }],
        };
        Transforms.insertNodes(editor, new_node, { at: match[1] })
      }} >
        <DeleteOutlined /> 删除图片
      </ToolbarButton>
    </>
  ) : null
}

const EmbeddedEditButtons: FC = () => {
  const { createOrEditDescendant } = useContext(editContext);

  const editor = useSlate();

  const match = getFirstMatchedNode(editor, n => isElementOfType('embedded', n));

  return match ? (
    <>
      <ToolbarButton onClick={() => createOrEditDescendant(match[0] as RichContentImg)} >
        <EditOutlined /> 编辑内嵌代码
      </ToolbarButton>
      <ToolbarButton onClick={() => {
        Transforms.removeNodes(editor, { at: match[1] })
        const new_node = {
          type: 'p',
          children: [{ text: '' }],
        };
        Transforms.insertNodes(editor, new_node, { at: match[1] })
      }} >
        <DeleteOutlined /> 删除内嵌代码
      </ToolbarButton>
    </>
  ) : null
}

const LinkEditButtons: FC = () => {
  const editor = useSlate();

  const { createOrEditDescendant } = useContext(editContext);

  return (
    <>
      <ToolbarButton onClick={() => {
        const matched = getFirstMatchedNode(editor, isLink);
        if (!matched) return;
        createOrEditDescendant(matched[0] as RichContentLink);
      }} >
        <EditOutlined /> 编辑链接
      </ToolbarButton>
      <ToolbarButton onClick={() => {
        Transforms.setNodes(editor, {
          href: undefined,
        } as Partial<RichContentLeaf>, {
          at: editor.selection,
          match: isLink,
        })
      }} >
        <DisconnectOutlined /> 取消链接
      </ToolbarButton>
    </>
  )
}

const createTextDecoButton = (attr: keyof Omit<RichText, keyof BaseText>, content: ReactNode): FC => () => {
  const editor = useSlate();
  const { selection } = editor;
  const match = Editor.node(editor, selection);

  const onToggle = () => {
    const text = getSelectedText(editor);

    const match = Editor.node(editor, editor.selection);
    if (!match) return;
    const [node] = match;

    if (node[attr]) {
      Transforms.delete(editor, { at: match[1] });
      const nextNode = ObjectUtils.omit(node as RichText, [attr]);
      Transforms.insertNodes(editor, nextNode);
    } else {
      const match_node = Editor.node(editor, editor.selection);
      const values: RichText = {
        ...node,
        text,
        [attr]: 1,
      };
      if (match_node[1][0] != match[1][0]) {
        Transforms.insertNodes(editor, values, { at: match[1] });
      } else {
        Transforms.insertNodes(editor, values);
      }
    }
  };

  const active = match?.[0]?.[attr];

  return (
    <ToolbarButton active={active} onClick={onToggle}>
      {content}
    </ToolbarButton>
  )
}
const BoldButton = createTextDecoButton('b', <b>B</b>);
const UnderlineButton = createTextDecoButton('u', <u>U</u>);
const ItalicButton = createTextDecoButton('i', <i>I</i>);


export type RichAttr = 'h2' | 'img' | 'embedded' | 'link' | 'bold' | 'underline' | 'italic';

export interface ToobarProps {
  attribute?: RichAttr[];
}

const ALL_RICH_ATTR: RichAttr[] = [
  'h2',
  'img',
  'embedded',
  'link',
  'bold',
  'underline',
  'italic'
]

const attrCompMap: Record<RichAttr, FC> = {
  h2: Title2Button,
  img: ImgAddButton,
  embedded: EmbeddedAddButton,
  link: LinkAddButton,
  bold: BoldButton,
  underline: UnderlineButton,
  italic: ItalicButton,
};


export const Toolbar: FC<ToobarProps> = ({ attribute = ALL_RICH_ATTR }) => {
  const editor = useSlate();

  const { selection } = editor;

  const renderAttrs = useMemo(() => {
    const attrSet = new Set(attribute);
    return (attrs: RichAttr[]) => attrs.map((attr) => {
      if (!attrSet.has(attr)) return null;
      const Comp = attrCompMap[attr];
      return <Comp key={attr} />
    })
  }, []);

  const operations = useMemo(() => {
    if (!selection) return null;

    if (Range.isCollapsed(selection)) {
      const [node] = Editor.node(editor, selection);
      const [parent] = Editor.parent(editor, selection);

      if (isElementOfType('img', parent)) {
        return <ImgEditButtons />
      }

      if (isElementOfType('h2', parent)) {
        return renderAttrs(['h2']);
      }

      if (isElementOfType('embedded', parent)) {
        return <EmbeddedEditButtons />
      }

      const textAttrs: RichAttr[] = [];
      if ((node as RichText).b) {
        textAttrs.push('bold');
      }
      if ((node as RichText).u) {
        textAttrs.push('underline');
      }
      if ((node as RichText).i) {
        textAttrs.push('italic');
      }

      if (isLink(node)) {
        return [
          <LinkEditButtons key="link" />,
          ...renderAttrs(textAttrs)
        ]
      }

      return [
        ...renderAttrs(['h2', ...textAttrs, 'img', 'embedded']),
        <LinkAddButton key="link" />,
      ];
    }

    const at = Editor.unhangRange(editor, selection)

    const [, moreEl] = Editor.nodes(editor, {
      at,
      match: (n) => !Editor.isEditor(n) &&
        SlateElement.isElement(n)
    });

    if (moreEl) return null;

    const [[leaf], moreLeaf] = Editor.nodes(editor, {
      at,
      match: (n) => !Editor.isEditor(n) &&
        !SlateElement.isElement(n)
    });

    if (!moreLeaf && isLink(leaf)) {
      return <LinkEditButtons />;
    }

    return (
      <>
        <Title2Button />
        {
          !moreLeaf ? [
            renderAttrs(['bold', 'underline', 'italic']),
            <LinkAddButton key="link" />
          ] : null
        }
      </>
    )
  }, [selection, renderAttrs]);

  return (<header className={styles.toolbar} >
    <Button.Group>
      {operations}
    </Button.Group>
  </header>
  )
};
