/**
 * get account and login status
 */

import { useEffect } from "react";
import { useFlow } from "@@/react-hooks";
import { ApiResponseCode, AuthStatus } from "@/enum";
import { Account, AccountCreation, AccountInfoUpdate } from "@/interface";
import * as httpService from "@/service";
import { message } from "antd";
import { createModel } from ".";

export type AccountAction =
  | {
    type: "info" | "logout";
  }
  | {
    type: "login";
    username: string;
    password: string;
  } | {
    type: "put";
    data: AccountInfoUpdate;
  } | {
    type: 'post';
    data: AccountCreation;
  };

export const useAccount = createModel(() => {
  const [accountState, dispatchAccountAction] = useFlow<
    {
      status: AuthStatus;
      account: Account;
    },
    AccountAction
  >(
    {
      status: AuthStatus.Unknown,
      account: null,
    },
    function* ({ put, call, block, cancel, dispatch }, accountAction) {
      // action direct effects
      switch (accountAction.type) {
        case "info": {
          try {
            const { data } = yield call(httpService.getAccountInfo);
            yield put({
              status: AuthStatus.LoggedIn,
              account: data,
            });
          } catch (e) {
            if (e.code === ApiResponseCode.Unauthorized) {
              yield put({
                status: AuthStatus.NotLoggedIn,
                account: null,
              }); // not authorized
            } else {
              throw e;
            }
          }
          break;
        }
        case "login": {
          try {
            const { username, password } = accountAction;
            yield call(httpService.login, {
              username,
              password,
            });
            yield dispatch({
              type: 'info',
            });
          } catch (e) {
            message.warn(e?.msg);
            yield put({
              status: AuthStatus.NotLoggedIn,
              account: null,
            });
          }
          break;
        }
        case "logout": {
          yield cancel();
          yield call(httpService.logout);
          yield put({
            status: AuthStatus.NotLoggedIn,
            account: null,
          });
          break;
        }
        case "put":
          yield block(({ type }) => type === "put");
          yield call(httpService.putAccount, accountAction.data);
          yield put(prev =>
            prev.status === AuthStatus.LoggedIn
              ? {
                status: AuthStatus.LoggedIn,
                account: {
                  ...prev.account,
                  ...accountAction.data,
                },
              }
              : prev
          );
          break;
        default:
          break;
      }
    }
  );

  useEffect(() => {
    dispatchAccountAction({ type: "info" });
  }, []);

  return [accountState, dispatchAccountAction] as const;
});
