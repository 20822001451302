import { Button, Input } from 'antd';
import React, { FC } from 'react';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import styles from './form-item-list.module.less';
import { RichContentEditor } from '@/component/rich-content-editor';

export interface FormItemListProps {
  value: string[];
  onChange(value: string[]): void;
}

export const FormItemList: FC<FormItemListProps> = ({
  value = [],
  onChange
}) => {
  return (
    <ol className={styles.list} >
      {
        value.map(
          (item, index) => (
            <li className={styles.row} key={index} >
              <span className={styles.index} >{index + 1}.</span>
              <Input value={item} onChange={e => onChange(value.map((v, i) => i === index ? e.target.value : v))} />
              <Button className={styles.del_btn} danger onClick={() => onChange(value.filter((_, i) => i !== index))} >
                <MinusOutlined />
              </Button>
            </li>
          )
        )
      }
      <li className={styles.add_row}>
        <Button className={styles.add_btn} onClick={() => onChange([...value, ''])} >
          <PlusOutlined />
        </Button>
      </li>
    </ol>
  );
};

export interface FormItemRichListProps {
  value: string[];
  onChange(value): void;
}

export const FormItemRichList: FC<FormItemRichListProps> = ({
  value = [],
  onChange
}) => {
  return (
    <ol className={styles.list}>
      {
        value.map(
          (item, index) => (
            <li key={index} className={styles.rich}>
              <span className={styles.row} >
                <span className={styles.index} >{index + 1}.</span>
                <Input value={item['title']} placeholder="title" onChange={e => onChange(value.map((v, i) => i === index ? { title: e.target.value, content: v['content'] } : v))} />
                <Button className={styles.del_btn} danger onClick={() => onChange(value.filter((_, i) => i !== index))} >
                  <MinusOutlined />
                </Button>
              </span>
              <RichContentEditor
                attribute={['link', 'h2']}
                value={item['content']}
                onChange={(e) => onChange(value.map((v, i) => i === index ? { title: v['title'], content: e } : v))}
                placeholder="footer"
              />
            </li>
          )
        )
      }
      <li className={styles.add_row}>
        <Button className={styles.add_btn} onClick={() => onChange([
          ...value,
          { title: '', content: [{ type: 'p', children: [{ text: '' }] }] }
        ])} >
          <PlusOutlined />
        </Button>
      </li>
    </ol>
  );
};

export interface FormItemDictListProps {
  value: string[];
  onChange(value): void;
}

export const FormItemDictList: FC<FormItemDictListProps> = ({
  value = [],
  onChange
}) => {
  return (
    <ol className={styles.list}>
      {
        value.map(
          (item, index) => (
            <li key={index} className={styles.row}>
                <Input value={item['key']} onChange={e => onChange(value.map((v, i) => i === index ? { key: e.target.value, value: v['value'] } : v))} />
                <Input value={item['value']} onChange={e => onChange(value.map((v, i) => i === index ? { key: v['key'], value: e.target.value } : v))} />
                <Button className={styles.del_btn} danger onClick={() => onChange(value.filter((_, i) => i !== index))} >
                  <MinusOutlined />
                </Button>
            </li>
          )
        )
      }
      <li className={styles.add_row}>
        <Button className={styles.add_btn} onClick={() => onChange([
          ...value,
          { key: '', value: '' }
        ])} >
          <PlusOutlined />
        </Button>
      </li>
    </ol>
  );
};
