import { DatePicker, Input, Select, Switch, Checkbox, FormInstance, Form, Radio, Space, Spin } from 'antd';
import React, { FC, ReactElement, useEffect, useMemo } from 'react';
import { useFlow, CallNext, useCountEffect } from '@@/react-hooks';
import { CrudListFieldConfig, CrudListFormConfig } from './interface';
import styles from './crud-list.module.less';
import { Option } from '@/interface';
import { ObjectUtils, ScheduleUtils } from '@@/utils';
import { matchOptions } from '@/util/data';
import { ImagesUploader, ImageUploader } from '@/ui/images-uploader';
import moment from 'moment'
import 'antd/dist/antd.css';
import { LoadingOutlined } from '@ant-design/icons';

interface FormItemProps extends Pick<CrudListFieldConfig<any>, 'type' | 'options' | 'debounce' | 'render' | 'disabled' | 'mode'> {
  depValues?: any;
  value?: any;
  load?: any;
  onChange?(value: any): void;
}

export const FormItemSelect: FC<FormItemProps> = ({
  mode,
  value,
  onChange,
  depValues,
  debounce,
  disabled,
  options: getOptions,
  load = false,
}) => {
  type Action = {
    type: 'set-keyword';
    keyword: string
  } | {
    type: 'update-options';
    debounce: number;
    getOptions: FormItemProps['options'];
  }

  const [state, dispatch] = useFlow<{
    keyword: string;
    options: Option[];
  }, Action>({
    keyword: '',
    options: [],
  }, function* ({ get, call, cancel, put, dispatch: innerDispatch }, action) {
    switch (action.type) {
      case 'set-keyword':
        yield put(prev => ({
          ...prev,
          keyword: action.keyword,
        }));
        yield innerDispatch({
          type: 'update-options',
          debounce,
          getOptions,
        });
        break;
      case 'update-options': {
        const { keyword } = yield get();
        const { debounce, getOptions } = action;
        if (Array.isArray(getOptions)) {
          yield put(prev => ({
            ...prev,
            options: matchOptions(getOptions, keyword)
          }));
          return;
        }
        yield cancel(({ type }) => type === 'update-options');
        if (debounce) {
          yield call(ScheduleUtils.timeout, debounce);
        }
        const res: CallNext<typeof getOptions> = yield call(getOptions, keyword, depValues);
        yield put(prev => ({
          ...prev,
          options: Array.isArray(res) ? res : res.data
        }));
        break;
      }
      default:
        break;
    }
  });

  useEffect(() => {
    dispatch({
      type: 'update-options',
      debounce: 0,
      getOptions,
    });
  }, [getOptions]);

  useCountEffect(count => {
    if (count) {
      dispatch({
        type: 'update-options',
        debounce,
        getOptions,
      });
    }
  }, [depValues]);

  const { data: { keyword, options }, loading } = state;

  // LATER: auto clear if options not includes the currency selection

  return (
    <Select
      mode={mode ? mode : ''}
      className={styles.select}
      value={value}
      onChange={onChange}
      searchValue={keyword}
      defaultValue={[]}
      onSearch={keyword => dispatch({
        type: 'set-keyword',
        keyword
      })}
      showSearch
      autoClearSearchValue
      filterOption={false}
      loading={load ? load : loading}
      allowClear
      disabled={disabled}
    >
      {
        options.map(
          ({ value, label }) => (
            <Select.Option value={value} key={value} >
              {label}
            </Select.Option>
          )
        )
      }
    </Select>
  )
}

const FormItemRatio: FC<FormItemProps> = ({
  value,
  onChange,
  depValues,
  options: getOptions,
}) => {

  const [state, dispatch] = useFlow<{
    keyword: string;
    options: Option[];
  }, { getOptions }>({
    keyword: '',
    options: [],
  }, function* ({ get, call, cancel, put, dispatch: innerDispatch }, action) {
    if (Array.isArray(getOptions)) {
      yield put(prev => ({
        ...prev,
        options: matchOptions(getOptions, keyword)
      }));
      return;
    }
    const res: CallNext<typeof getOptions> = yield call(getOptions, keyword, depValues);
    yield put(prev => ({
      ...prev,
      options: Array.isArray(res) ? res : res.data
    }));
  })

  useEffect(() => {
    dispatch({ getOptions });
  }, [getOptions]);

  const { data: { keyword, options }, loading } = state;
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  return <>
    <Spin indicator={antIcon} spinning={loading} />
    <Radio.Group onChange={onChange} value={value}>
      <Space direction="vertical">
        {
          options.map(
            ({ value, label, disabled }) => (
              <Radio value={value} key={value} disabled={disabled}>
                {label}
              </Radio>
            )
          )
        }
      </Space>
    </Radio.Group>
  </>
}

const str2num = (str: string) => {
  const num = parseInt(str, 10);
  return Number.isNaN(num) ? undefined : num;
}
const num2str = (num: number) => num == null ? '' : String(num);

export const FormItemCore: FC<FormItemProps> = (props) => {
  const { type, value, onChange, render, disabled, depValues } = props;

  switch (type) {
    case 'input':
      return <Input
        disabled={disabled}
        className={styles.input}
        value={value}
        onChange={onChange ? e => onChange(e.target.value) : undefined}
        allowClear
      />;
    case 'select':
      return <FormItemSelect {...props} />;
    case 'mult_select':
      return <FormItemSelect {...props} mode='multiple' />;
    case 'checkbox':
      return <Checkbox.Group disabled={disabled} options={props.options as any} value={typeof (value) == 'string' ? [value] : value} onChange={onChange} />
    case 'int':
      return <Input
        disabled={disabled}
        value={num2str(value)}
        onChange={e => onChange(str2num(e.target.value))}
        className={styles.input}
        allowClear
      />;
    case 'images':
      return <ImagesUploader value={value} onChange={onChange} />;
    case 'image':
      return <ImageUploader value={value} onChange={onChange} deletable={!disabled} />;
    case 'textarea':
      return <Input.TextArea
        disabled={disabled}
        allowClear
        className={styles.textarea}
        value={value}
        onChange={onChange ? e => onChange(e.target.value) : undefined}
      />;
    case 'time':
      return <DatePicker disabled={disabled} allowClear showNow={false} value={value} onChange={onChange} showTime={{
        defaultValue: moment('00:00:00', 'HH:mm:ss'),
      }} />
    case 'switch':
      return <Switch disabled={disabled} checked={value} onChange={onChange} />;
    case 'custom':
      return <>
        {render({ value, depValues, onChange })}
      </>
    case 'radio':
      return <FormItemRatio {...props} />;
    default:
      return null;
  }
}

function FormItemDepWrapper<T extends object>({
  value,
  onChange,
  deps = [],
  form,
  ...rest
}: {
  value?: any;
  onChange?(value: any): void;
  form: FormInstance<T>;
} & Pick<CrudListFieldConfig<T>, 'type' | 'options' | 'deps' | 'debounce' | 'render' | 'disabled'>) {
  const depsMapped = deps.map(key => form.getFieldValue(key));
  const depValues = useMemo(() => form.getFieldsValue(deps), depsMapped);

  return <FormItemCore value={value} onChange={onChange} depValues={depValues} {...rest} />
}

export function FormItem<T extends object>(props: CrudListFieldConfig<T>): ReactElement {
  const {
    name, label, rules = [], deps = [], optional = false, type
  } = props;
  const required = !optional;
  const combinedRules = [
    ...(
      required ? [
        {
          required: true,
          message: `请${['input', 'int', 'textarea'].includes(type) ?
            '输入' :
            ['select', 'images'].includes(type) ?
              '选择' : '设置'
            }${label}`
        }
      ] : []
    ),
    ...rules,
  ]
  return (
    <Form.Item required={required} label={label} dependencies={deps} >
      {
        (formInstance: FormInstance<T>) => (
          <Form.Item name={name} rules={combinedRules} noStyle >
            <FormItemDepWrapper form={formInstance} {...ObjectUtils.pick(props, ['type', 'options', 'debounce', 'deps', 'render', 'disabled'])} />
          </Form.Item>
        )
      }
    </Form.Item>
  )
}
