import { Modal } from "antd"
import { CreateUpdateForm } from "../crud-list/create-update-form"
import { CrudListFormItemConfig } from "../crud-list";
import { RichContentEmbedded } from "@/interface/rich-content";
import React, { FC, useCallback } from "react";
import { RichContentModalProps } from "./interface";
import { useSlate } from "slate-react";
import { Editor, Transforms } from "slate";
import { getFirstMatchedNode, isElementOfType } from "./utils";
import { FormModalFooter } from "../form-modal-footer";

const FORM_ID = 'rich-content-embedded-form';

export type EmbeddedModalProps = RichContentModalProps<RichContentEmbedded>;

type EmbeddedFormValues = RichContentEmbedded;

const fields: CrudListFormItemConfig<EmbeddedFormValues>[] = [
  {
    name: 'html',
    label: '代码',
    type: 'textarea'
  }
]

export const EmbeddedModal: FC<EmbeddedModalProps> = ({
  data, onClose
}) => {
  const isEditing = !!data.html; // CHECK:

  const editor = useSlate();
  const { selection } = editor;

  const onSubmit = useCallback((values: EmbeddedFormValues) => {
    const embeddedNode: RichContentEmbedded = {
      type: 'embedded',
      html: values.html,
      children: [{ text: '' }]
    }

    if (isEditing) {
      const match = getFirstMatchedNode(editor, n => isElementOfType('embedded', n));

      if (!match) return;

      Transforms.setNodes(editor, embeddedNode);
    } else {
      // insert a new node
      const match_node = Editor.node(editor, editor.selection);
      if (match_node[0]['text'] == '') {
        Transforms.removeNodes(editor)
      }
      Transforms.insertNodes(editor, embeddedNode);
    }
    onClose()
  }, [isEditing, selection]);

  return (
    <Modal title={isEditing ? '编辑内嵌代码' : '插入内嵌代码'} visible onCancel={onClose} footer={<FormModalFooter form={FORM_ID} onCancel={onClose} />} >
      <CreateUpdateForm<EmbeddedFormValues>
        id={FORM_ID}
        initialValues={data}
        fields={fields}
        onFinish={onSubmit}
        labelAlign="left"
        labelCol={{
          span: 3
        }}
      />
    </Modal>
  )
}