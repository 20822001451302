import { CrudList } from '@/component/crud-list';
import { Account, AccountCreation } from '@/interface';
import { getAccounts, postAccount } from '@/service';
import React, { FC } from 'react';
import { adaptAccountCreation, sysUserColumns, sysUserFormFields } from './config';

const AdminSysUser: FC = () => {
  return (
    <CrudList<Account, AccountCreation, {}>
      title="系统用户"
      rowKey="email"
      read={getAccounts}
      columns={sysUserColumns}
      fields={sysUserFormFields}
      adaptCreation={adaptAccountCreation}
      create={postAccount}
    />
  )
}

export default AdminSysUser;
