import { RichContentElement, RichContentElementType, RichContentImg, RichContentLink, RichContentTitle2 } from "@/interface/rich-content";
import { isString } from "@@/utils";
import { Editor, Node, NodeMatch, Element as SlateElement } from "slate";

export function isLink(node: any): node is RichContentLink {
  return isString(node?.href);
}

export function isElementOfType<T extends RichContentElement['type']>(type: T, node: any): node is RichContentElement & { type: T } {
  return node?.type === type;
}

export function getFirstMatchedNode(editor: Editor, match: NodeMatch<Node>) {
  const { selection } = editor;

  if (!selection) return null;

  const [matched] = Editor.nodes(editor, {
    at: selection,
    match: (n, p) => !Editor.isEditor(n) && match(n, p),
  });

  return matched;
};


export function isLinkActive(editor: Editor) {
  return !!getFirstMatchedNode(editor, n => !SlateElement.isElement(n) && isLink(n));
};

export function isElementActive(editor: Editor, type: RichContentElementType) {
  return !!getFirstMatchedNode(editor, n => isElementOfType(type, n));
}

export function getSelectedText(editor: Editor): string {
  const { selection } = editor
  if (!selection) return '';
  const [match] = Array.from(
    Editor.fragment(editor, selection)
  );
  const selected_data = match['children'][0].text
  return selected_data
};
