import { useEffect } from "react"
import { PagiQuery } from "@/interface"
import { useFlow, CallNext } from "@@/react-hooks";
import { CrudListProps } from "./interface";

export type CrudReadAction<Filters> = {
  type: 'filter' | 'reload';
  overrides?: Partial<Filters & PagiQuery>;
} | {
  type: 'submit' | 'reset' | 'query';
}

export interface CrudListState<ListItem, Filters> {
  filtersQuery: Filters & PagiQuery;
  filtersDisplay: Filters & PagiQuery;
  list: ListItem[];
  total: number;
}

function createInitialState(): CrudListState<any, any> {
  const filters = {
    page: 1,
  };

  return {
    filtersQuery: filters,
    filtersDisplay: filters,
    list: [],
    total: 0,
  }
}

export function useCrudRead<ListItem extends object, Filters>({
  read,
  pageSize,
  isRequests,
}: CrudListProps<ListItem, any, Filters>) {
  const tuple = useFlow<CrudListState<ListItem, Filters>, CrudReadAction<Filters>>(
    createInitialState,
    function* ({ get, put, call, cancel, dispatch }, action) {
      switch (action.type) {
        case 'query': {
          const { filtersQuery } = yield get();
          if (String(Object.keys(filtersQuery)) == 'page' && isRequests == false) {
            break
          }
          yield cancel(({ type }) => type === 'query');
          const { data }: CallNext<typeof read> = yield call(read, {
            ...filtersQuery,
            pageSize,
          });
          yield put(prev => ({
            ...prev,
            ...data,
          }));
          break;
        }
        case 'reload': {
          const { overrides = {} } = action;
          yield put(prev => ({
            ...prev,
            filtersQuery: {
              ...prev.filtersQuery,
              ...overrides,
            },
            filtersDisplay: {
              ...prev.filtersDisplay,
              ...overrides,
            }
          }));
          yield dispatch({ type: 'query' });
          break;
        }
        case 'filter': {
          const { overrides = {} } = action;
          yield put(prev => ({
            ...prev,
            filtersDisplay: {
              ...prev.filtersDisplay,
              ...overrides,
            }
          }));
          break;
        }
        case 'reset': {
          yield put(createInitialState());
          yield dispatch({ type: 'query' });
        }
        case 'submit': {
          yield put(prev => {
            const filtersDisplay = {
              ...prev.filtersDisplay,
              page: 1,
            };
            return {
              filtersQuery: filtersDisplay,
              filtersDisplay,
              list: [],
              total: 0,
            };
          });
          yield dispatch({ type: 'query' });
        }
        default:
          break;
      }
    },
  )

  useEffect(() => {
    tuple[1]({
      type: 'query',
    })
  }, []);

  return tuple;
}