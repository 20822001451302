import React, { Fragment } from 'react';
import { CrudListColumnConfig, CrudListFormConfig } from "@/component/crud-list";
import { Order, OrderQuery } from "@/interface";
import { t } from "@/lang";
import { ORDER_STATUSES } from "@/enum";
import { Popover } from "antd";
import styles from '@/component/crud-list/crud-list.module.less';
import { Image } from 'antd';
import { WEB_ORIGIN } from '@/url';
import { getInernalUser } from "@/service";

export const orderFilters: CrudListFormConfig<OrderQuery>[] = [
  {
    name: 'orderId',
    label: '订单 ID',
    type: 'int',
  },
  {
    name: 'orderSn',
    label: 'orderSn',
    type: 'input',
  },
  {
    name: 'startTime',
    label: '开始时间',
    type: 'time',
  },
  {
    name: 'endTime',
    label: '结束时间',
    type: 'time',
  },
  {
    name: 'orderStatus',
    label: '订单状态',
    type: 'select',
    options: ORDER_STATUSES.map(value => ({
      label: t(`value.orderStatus.${value}`),
      value,
    })),
  },
  {
    name: 'internalUserId',
    label: '代购user',
    type: 'select',
    options: (keyword) => getInernalUser({ keyword })
  },
]

export const orderColumns: CrudListColumnConfig<Order>[] = [
  {
    title: '订单 ID',
    width: 100,
    dataIndex: 'id',
    fixed: 'left',
  },
  {
    title: t('table.offerImg'),
    dataIndex: 'orderOffer',
    width: 120,
    display: (orderOffer, item) => {
      return (
        orderOffer ?
          <Image className={styles.cell_img} src={orderOffer['img_url']} /> : null
      )
    }
  },
  {
    title: t('table.offerName'),
    width: 240,
    dataIndex: 'orderOffer',
    display: (orderOffer, item) => {
      const brandAlias = orderOffer['brand'].toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/[^a-z0-9 /-]+/g, '').replace(/[ /]+/g, '')
      // const offer_url = WEB_ORIGIN + '/shopping/' + orderOffer['gender'] + '/item/' + orderOffer['spu_id']
      const offer_detail_url = WEB_ORIGIN + '/user/' + brandAlias + '/listings/' + orderOffer['spu_id']
      return (
        orderOffer ?
          <Popover>
            <a href={offer_detail_url} target="_blank">{orderOffer['offer_name']}</a>
            <br></br>
            <span>Size: {orderOffer['size']}</span>
          </Popover>
          : null
      )
    }
  },
  {
    title: '订单状态',
    width: 85,
    dataIndex: 'orderStatus',
    display: orderStatus => t(`value.orderStatus.${orderStatus}`),
  },
  {
    title: '最终价格',
    width: 100,
    dataIndex: 'finalPrice',
    display: (finalPrice, item) => {
      return (
        <span>{finalPrice} {item['currency']}</span>
      )
    }
  },
  {
    title: '买家',
    width: 100,
    dataIndex: 'buyerName',
  },
  {
    title: '卖家',
    width: 100,
    dataIndex: 'sellerName',
  },
  {
    title: '收件人',
    width: 120,
    dataIndex: 'orderAddress',
    display: (orderAddress, item) => {
      return (
        orderAddress ?
          <Popover content={
            <dl>
              <Fragment>
                <dl>
                  {['country', 'city', 'address', 'mobile', 'zip_code'].map(
                    key => (
                      <Fragment key={key} >
                        <dt>
                          {t(`form.${key}`)}
                        </dt>
                        <dl>
                          {orderAddress[key]}
                        </dl>
                      </Fragment>
                    )
                  )}
                </dl>
              </Fragment>
            </dl>
          }>
            <span>{orderAddress['first_name']}{orderAddress['last_name']}</span>
          </Popover>
          : null
      )
    }
  },
  {
    title: t('table.createTime'),
    width: 140,
    dataIndex: 'createTime',
    display: 'time',
  },
  {
    title: t('table.updateTime'),
    width: 140,
    dataIndex: 'updateTime',
    display: 'time',
  },
];