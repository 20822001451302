import React, { createContext, FC, useMemo } from 'react';
import { RichContentDescendant } from '@/interface/rich-content';
import { useModal } from '@@/react-hooks';
import { LinkModal } from './link-modal';
import { ImgModal } from './img-modal';
import { isElementOfType, isLink } from './utils';
import { RichContentModalProps } from './interface';
import { EmbeddedModal } from './embedded-modal';

export interface EditContextValue {
  createOrEditDescendant(descendant?: RichContentDescendant): void;
}

export const editContext = createContext<EditContextValue>(null);

export const EditContextProvider: FC = ({
  children,
}) => {
  const [{
    visible,
    data,
  }, openModal, closeModal] = useModal<RichContentDescendant>(null);

  const editContextValue: EditContextValue = useMemo(() => ({
    createOrEditDescendant: openModal,
  }), []);

  const modal = useMemo(() => {
    if (!visible) return null;
    const commonProps: Omit<RichContentModalProps<any>, 'data'> = {
      onClose: closeModal,
    };
    if (isLink(data)) {
      return (
        <LinkModal {...commonProps} data={data} />
      );
    }
    if (isElementOfType('img', data)) {
      return (
        <ImgModal {...commonProps} data={data} />
      )
    }
    if (isElementOfType('embedded', data)) {
      return (
        <EmbeddedModal {...commonProps} data={data} />
      )
    }
    return null;
  }, [visible, data]);

  return (
    <>
      <editContext.Provider value={editContextValue} >
        {children}
      </editContext.Provider>
      {modal}
    </>
  )
};
