import { CrudListColumnConfig, CrudListFormConfig } from "@/component/crud-list";
import { Blog, BlogQuery } from "@/interface";
import { t } from "@/lang";
import { BLOG_STATUSES } from "@/enum";

export const blogFilters: CrudListFormConfig<BlogQuery>[] = [
  {
    name: 'id',
    label: t('placeholder.blogId'),
    type: 'int',
  },
  {
    name: 'blogStatus',
    label: t('placeholder.blogStatus'),
    type: 'select',
    options: BLOG_STATUSES.map(value => ({
      label: t(`value.blogStatus.${value}`),
      value,
    })),
  },
]

export const blogColumns: CrudListColumnConfig<Blog>[] = [
  {
    title: t('table.blogId'),
    width: 85,
    dataIndex: 'id',
    fixed: 'left',
  },
  {
    title: t('table.title'),
    width: 120,
    dataIndex: 'title',
  },
  {
    title: t('table.thumbnail'),
    width: 120,
    dataIndex: 'thumbnail',
    display: 'image',
  },
  {
    title: t('table.author'),
    width: 85,
    dataIndex: 'author',
  },
  {
    title: t('table.brief'),
    width: 240,
    dataIndex: 'brief',
  },
  {
    title: 'key',
    width: 120,
    dataIndex: 'key',
  },
  {
    title: t('table.blogStatus'),
    width: 85,
    dataIndex: 'blogStatus',
    display: blogStatus => t(`value.blogStatus.${blogStatus}`),
  },
  {
    title: t('table.createBy'),
    width: 120,
    dataIndex: 'createBy',
  },
  {
    title: t('table.updateBy'),
    width: 120,
    dataIndex: 'updateBy',
  },
  {
    title: t('table.createTime'),
    width: 120,
    dataIndex: 'createTime',
    display: 'time',
  },
  {
    title: t('table.updateTime'),
    width: 120,
    dataIndex: 'updateTime',
    display: 'time',
  },
];
