import React, { FC } from 'react';
import { message, Drawer } from 'antd';
import { useFlow } from '@@/react-hooks';
import { putProduct, deleteSpuLock, getWebUserInfo, postSpuLock } from '@/service';
import { SpuAttributeLock, SpuState } from "@/enum";
import { Offer, Mapping } from '@/interface';
import { CreateUpdateForm } from '@/component/crud-list/create-update-form';
import { productUpdateFormFields } from '../product/config';
import styles from './update-modal.module.less';
import { Async } from '@/component/async';
import { useFlowEffect } from '@reversible/common/es/react-hooks';
import { ProductCreation } from '@/interface';
import { FormModalFooter } from '@/component/form-modal-footer';

const FORM_ID = 'spu-update-form';
export interface SpuUpdateModalProps {
  visible: boolean;
  data: Offer | Mapping;
  onSuccess(): void;
  onClose(): void;
}

const lock_attr = [SpuAttributeLock.INFO, SpuAttributeLock.IMAGE, SpuAttributeLock.DESCRIPTION]


export const SpuUpdateModal: FC<SpuUpdateModalProps> = ({
  visible,
  onClose,
  onSuccess,
  data,
}) => {
  const dataState = useFlowEffect<ProductCreation>(null, function* ({
    call,
    put
  }) {
    if (data) {
      yield put(null);
      const user_info = yield call(getWebUserInfo, { userId: data.userId })

      yield put({
        'name': data.offerName,
        'brand': data.brand,
        'country': data.offerCountry,
        'images': data.images,
        'description': data.offerDescription,
        'price': data.offerPrice,
        'currency': data.offerCurrency,
        'sourceUrl': data.offerUrl,
        'retailer': user_info.data.username,
        'userId': data.userId,
        'sourceId': data.crawlerId,
        'color': data.color,
        'gender': undefined,
        'category1': undefined,
        'category2': undefined,
        'sizeUnit': undefined,
        'composition': undefined,
        'publishTime': undefined,
        'retailerSpuSn': data.retailerSpuSn,
        'spuStatus': SpuState.Available,
        'unclassified': undefined,
        'styleId': data.styleId,
        'createBy': undefined,
        'updateBy': undefined,
        'spuLock': false,
      })
    }
  }, [data]);

  const [{ loading }, dispatch] = useFlow<void, ProductCreation>(null, function* ({ call, block }, creation) {
    yield block();
    try {
      if (creation.spuLock) {
        yield call(deleteSpuLock, { spuId: data.spuId, lockAttr: lock_attr })
        yield call(putProduct, { spuId: data.spuId, data: creation })
        yield call(postSpuLock, {
          spuId: data.spuId,
          lockAttr: lock_attr,
        })
      } else {
        yield call(deleteSpuLock, { spuId: data.spuId, lockAttr: lock_attr })
        yield call(putProduct, {
          spuId: data.spuId,
          data: creation,
        })
      }
      onSuccess();
      onClose();
    }
    catch (error) {
      message.error(error.msg);
    }
  });

  return (
    <Drawer
      visible={visible}
      title={<>更新当前offer信息到spu</>}
      onClose={onClose}
      destroyOnClose
      width="500px"
      footerStyle={{
        textAlign: 'right',
      }}
      footer={<FormModalFooter form={FORM_ID} onCancel={onClose} loading={loading} />}
    >
      <div className={styles.modal}>
        <Async source={dataState} >
          {
            data => (
              <CreateUpdateForm
                initialValues={data}
                fields={productUpdateFormFields}
                id={FORM_ID}
                onFinish={fields => dispatch(fields)}
                labelAlign="left"
                labelCol={{
                  span: 6
                }}
              />
            )
          }
        </Async>
      </div>
    </Drawer>
  )
} 