import { message } from 'antd';
import { authorizationCacher } from "@/util/cacher";
import { headers, rejectOn, httpFetch, HttpMiddleware, httpUrl, httpOnion, cache, HttpRequestObject, HttpResponseObject, method, jsonBody, combine, HttpMiddlewareGetKey } from "@@/http";
import { API_URL, NODE_ENV } from '@/env';
import { ApiResponseCode, Gender } from '@/enum';
import { MaybePromiseUtils, ObjectUtils, Resolvable, ScheduleUtils, DateDataFormat, compressImageFile } from '@@/utils';
import { MaybePromise, ObjectPath } from '@@/util-types';
import { deepMapCamelToSnake, deepMapSnakeToCamel } from '@/util/format';
import { ImageUploaderSrc, Option } from '@/interface';
import { matchOptions } from '@/util/data';
import { getFileMD5 } from '@/util/blob';
import { GetS3Fields } from './interface';
import { MomentUtils } from '@/util/moment';

const { map } = MaybePromiseUtils;

export const defaultGetHeadersKey: HttpMiddlewareGetKey = ({ headers }) => JSON.stringify(headers);

export const mapResolvedData = (mapper: (data: any, req: HttpRequestObject) => any): HttpMiddleware => (req, next) => map(
  next(req),
  res => 'resolve' in res ? ObjectUtils.immutableSet(res, ['resolve', 'data'], prev => mapper(prev, req)) : res,
)

const AUTH_HEADER_KEY = 'Authorization';

/**
 * auth header check and unauth callback
 */
export const auth = (reloadOnUnauth = true): HttpMiddleware => (req, next) => {
  function onUnauth() {
    if (reloadOnUnauth) {
      message.warn('登录状态已过期，请重新登录', 1).then(() => window.location.reload());
    }
  }

  if (!req.headers[AUTH_HEADER_KEY]) {
    onUnauth();
    return {
      headers: {},
      status: ApiResponseCode.Unauthorized,
      statusText: '',
      reject: {
        code: ApiResponseCode.Unauthorized,
      },
    };
  }

  return map(
    next(req),
    res => {
      if ('reject' in res && res.reject?.code === ApiResponseCode.Unauthorized) {
        onUnauth();
      }

      return res;
    }
  );
};

export const shortcut = (when: (req: HttpRequestObject) => boolean, data: (req: HttpRequestObject) => any): HttpMiddleware =>
  (req, next) => {
    return when(req) ? {
      headers: {},
      status: ApiResponseCode.Success,
      statusText: 'Ok',
      resolve: {
        code: ApiResponseCode.Success,
        data: data(req),
        success: true,
      },
    } : next(req);
  }

/**
 * adapt PagiQuery and PagiList
 * @param listKey the list key
 * @returns 
 */
export const adaptPagi = (listKey: string = 'list'): HttpMiddleware => (req, next) => map(
  next(
    ObjectUtils.immutableSet(req, ['params'], ({ page, pageSize, ...rest }) => ({
      ...rest,
      offset: page && (page - 1) * pageSize,
      limit: pageSize,
    }))
  ),
  res => 'resolve' in res ? ObjectUtils.immutableSet(res, ['resolve', 'data'], raw => ({
    total: raw?.total,
    list: raw?.[listKey],
  })) : res,
);

/**
 * adapt camel cases and snake cases
 * @returns 
 */
export const adaptCase = (): HttpMiddleware => (req, next) => map(
  next(
    ObjectUtils.immutableSet(req, ['params'], deepMapCamelToSnake)
  ),
  res => 'resolve' in res ?
    ObjectUtils.immutableSet(res, ['resolve', 'data'], deepMapSnakeToCamel) :
    res
);

export const urlMiddleware = httpUrl(
  `${NODE_ENV === "production" ? API_URL : ""}/api/v1`
);

export const urlTaskMiddleware = httpUrl(
  `${NODE_ENV === "production" ? API_URL : ""}/api`
);

export const createRequest = (...mws: HttpMiddleware[]) => httpOnion(
  headers(() => {
    const currentToken = authorizationCacher.get();
    return currentToken
      ? {
        [AUTH_HEADER_KEY]: currentToken,
      }
      : {};
  }),
  ...mws,
  combine(),
  rejectOn(res => 'resolve' in res && typeof res.resolve === 'object' && !res.resolve.success),
  httpFetch(),
).fork();

export const categoriesMws: HttpMiddleware[] = [
  cache({
    getKey: defaultGetHeadersKey,
  }),
  urlMiddleware('/web/property/category'),
  mapResolvedData(({ categories }) => {
    const genderData: Record<Gender, {
      list: Option[];
      dict: Record<string, Option>;
    }> = {
      [Gender.Men]: {
        list: [],
        dict: {},
      },
      [Gender.Women]: {
        list: [],
        dict: {},
      },
    };
    for (const { category1, category2, gender } of categories) {
      const { list, dict } = genderData[gender as Gender];
      if (!dict[category1]) {
        const category1Option: Option = {
          value: category1,
          label: category1,
          children: [],
        };
        list.push(category1Option);
        dict[category1] = category1Option;
      }
      dict[category1].children.push({
        value: category2,
        label: category2
      });
    }
    return {
      [Gender.Men]: genderData[Gender.Men].list,
      [Gender.Women]: genderData[Gender.Women].list,
    };
  }),
  combine({
    getKey: defaultGetHeadersKey,
  }),
];

export const categoriesListMws: HttpMiddleware[] = [
  cache({
    getKey: defaultGetHeadersKey,
  }),
  urlMiddleware('/web/property/category'),
  mapResolvedData(({ categories }) => {
    const category1List = []
    const category2List = []
    const keys = []
    for (const { category1, category2 } of categories) {
      if (!!!keys.includes(category2)) {
        category2List.push({
          value: category2,
          label: category2,
        });
      }
      if (!!!keys.includes(category1)) {
        category1List.push({
          value: category1,
          label: category1,
        });
      }
      keys.push(category2)
      keys.push(category1)
    }
    return {
      'category1': category1List,
      'category2': category2List,
    }
  }),
  combine({
    getKey: defaultGetHeadersKey,
  }),
];

export const matchKeyword = (): HttpMiddleware => mapResolvedData(
  (list, { params: { keyword } }) => matchOptions(list, keyword),
)

export const uploadCampaignImages = (() => {
  const getS3Fields: GetS3Fields = createRequest(
    method("post"),
    urlMiddleware("/web/campaign/image"),
    jsonBody(),
  );

  /**
   * a weak map to pre avoid multi time file uploading
   */
  const fileUrlWeakMap: WeakMap<File, string> = new WeakMap();

  return (path: ObjectPath, maxWidthOrHeight?: number): HttpMiddleware =>
    (req, next) => {
      const uploadTasks: {
        file: File;
        resolvable: Resolvable<string>;
      }[] = [];

      const nextReqMaybePromise = ObjectUtils.immutableSetAsyncable(
        req,
        ["params", ...path],
        (imageSource: ImageUploaderSrc) => {
          if (imageSource instanceof File) {
            if (fileUrlWeakMap.has(imageSource)) {
              return fileUrlWeakMap.get(imageSource);
            }
            return (async () => {
              const resolvable = ScheduleUtils.createResolvable<string>();
              uploadTasks.push({
                file: imageSource,
                resolvable,
              });
              const url = await resolvable.promise;
              fileUrlWeakMap.set(imageSource, url);
              return url;
            })();
          }
          return imageSource;
        }
      );

      // batched uploading
      if (uploadTasks.length) {
        // run async task
        (async () => {
          const md5TaskDict: Record<
            string,
            {
              file: File;
              resolvables: Resolvable<string>[];
            }
          > = {};
          await Promise.all(
            uploadTasks.map(async ({ file, resolvable }) => {
              const md5 = await getFileMD5(file);
              if (!md5TaskDict[md5]) {
                md5TaskDict[md5] = {
                  file,
                  resolvables: [],
                };
              }
              md5TaskDict[md5].resolvables.push(resolvable);
            })
          );
          // transform dict -> list
          const tasksWithMd5 = Object.entries(md5TaskDict).map(
            ([checkSum, { file, resolvables }]) => ({
              checkSum,
              file,
              resolvables,
            })
          );
          const { data } = await getS3Fields(
            tasksWithMd5.map(({ checkSum, file }) => ({
              checkSum,
              mime: file.type,
              fileDir: req.params.campaignId || req.params.id,
            }))
          );
          data.forEach(async ({ fields, url, upload }, index) => {
            const { file, resolvables } = tasksWithMd5[index];
            if (upload) {
              const compressedFile = await compressImageFile(
                file,
                maxWidthOrHeight
              );
              const formData = new FormData();
              Object.entries(fields).forEach(([k, v]) => {
                formData.append(k, v);
              });
              formData.append("file", compressedFile);
              await fetch(url, {
                method: "post",
                body: formData,
              });
            }
            const s3Link = `${url.replace(/\/$/, "")}/${fields.key.replace(
              /^\//,
              ""
            )}`;
            resolvables.forEach((resolvable) => resolvable.resolve(s3Link));
          });
        })();
      }

      return map(nextReqMaybePromise, (nextReq) =>
        next(nextReq)
      ) as MaybePromise<HttpResponseObject>;
    };
})();


export const uploadImages = (() => {
  const getS3Fields: GetS3Fields = createRequest(
    method("post"),
    urlMiddleware("/image"),
    jsonBody(),
  );

  /**
   * a weak map to pre avoid multi time file uploading
   */
  const fileUrlWeakMap: WeakMap<File, string> = new WeakMap();

  return (path: ObjectPath, fileDir?: string, maxWidthOrHeight?: number): HttpMiddleware =>
    (req, next) => {
      const uploadTasks: {
        file: File;
        resolvable: Resolvable<string>;
      }[] = [];

      let filePath = undefined
      if (fileDir == 'designer') {
        const param = req.method === 'post' ? req.params : req.params.data
        filePath = param.urlAlias ? `logo/designer/${param.urlAlias}` : undefined
      }

      const nextReqMaybePromise = ObjectUtils.immutableSetAsyncable(
        req,
        ["params", ...path],
        (imageSource: ImageUploaderSrc) => {
          if (imageSource instanceof File) {
            if (fileUrlWeakMap.has(imageSource)) {
              return fileUrlWeakMap.get(imageSource);
            }
            return (async () => {
              const resolvable = ScheduleUtils.createResolvable<string>();
              uploadTasks.push({
                file: imageSource,
                resolvable,
              });
              const url = await resolvable.promise;
              fileUrlWeakMap.set(imageSource, url);
              return url;
            })();
          }
          return imageSource;
        }
      );

      // batched uploading
      if (uploadTasks.length) {
        // run async task
        (async () => {
          const md5TaskDict: Record<
            string,
            {
              file: File;
              resolvables: Resolvable<string>[];
            }
          > = {};
          await Promise.all(
            uploadTasks.map(async ({ file, resolvable }) => {
              const md5 = await getFileMD5(file);
              if (!md5TaskDict[md5]) {
                md5TaskDict[md5] = {
                  file,
                  resolvables: [],
                };
              }
              md5TaskDict[md5].resolvables.push(resolvable);
            })
          );
          // transform dict -> list
          const tasksWithMd5 = Object.entries(md5TaskDict).map(
            ([checkSum, { file, resolvables }]) => ({
              checkSum,
              file,
              resolvables,
            })
          );
          const { data } = await getS3Fields(
            tasksWithMd5.map(({ checkSum, file }) => ({
              checkSum,
              mime: file.type,
              filePath: filePath,
            }))
          );
          data.forEach(async ({ fields, url, upload }, index) => {
            const { file, resolvables } = tasksWithMd5[index];
            if (upload) {
              const compressedFile = await compressImageFile(
                file,
                maxWidthOrHeight
              );
              const formData = new FormData();
              Object.entries(fields).forEach(([k, v]) => {
                formData.append(k, v);
              });
              formData.append("file", compressedFile);
              await fetch(url, {
                method: "post",
                body: formData,
              });
            }
            const s3Link = `${url.replace(/\/$/, "")}/${fields.key.replace(
              /^\//,
              ""
            )}`;
            resolvables.forEach((resolvable) => resolvable.resolve(s3Link));
          });
        })();
      }

      return map(nextReqMaybePromise, (nextReq) =>
        next(nextReq)
      ) as MaybePromise<HttpResponseObject>;
    };
})();


/**
 * middleware for parse certain response field as Moment object
 * @param paths a list of path to target field
 * @param immutable should the process be immutable, default to false
 * @returns
 */
export const enMoment =
  (
    pathFormats: Record<string, DateDataFormat>,
    immutable = true
  ): HttpMiddleware =>
    async (req, next) => {
      const res = await next(req);

      if ("resolve" in res) {
        let nextData = res.resolve.data;
        for (const [path, format] of Object.entries(pathFormats)) {
          if (immutable) {
            nextData = ObjectUtils.immutableSet(
              nextData,
              path.split("."),
              (value: any) => MomentUtils.parse(value, format),
            );
          } else {
            const parentPath = path.split(".");
            const last = parentPath.pop();
            const parent = ObjectUtils.prop(nextData, parentPath);
            parent[last] = MomentUtils.parse(parent[last]);
          }
        }
        return ObjectUtils.immutableSet(res, ["resolve", "data"], nextData);
      }

      return res;
    };

export const deMoment =
  (
    pathFormats: Record<string, DateDataFormat>,
    immutable = true
  ): HttpMiddleware =>
    (req, next) => {
      let nextParams = req.params;
      if (!nextParams) {
        return next(req);
      }
      for (const [path, format] of Object.entries(pathFormats)) {
        if (immutable) {
          nextParams = ObjectUtils.immutableSet(
            nextParams,
            path.split("."),
            (date) => MomentUtils.serialize(date, format as any)
          );
        } else {
          const parentPath = path.split(".");
          const last = parentPath.pop();
          const parent = ObjectUtils.prop(nextParams, parentPath);
          parent[last] = MomentUtils.serialize(parent[last], format as any);
        }
      }
      return next({
        ...req,
        params: nextParams,
      });
    };

export const webUserSourceMws = [
  combine({
    getKey: defaultGetHeadersKey,
  }),
  cache({
    getKey: defaultGetHeadersKey,
  }),
  urlMiddleware('/web/user/all'),
  mapResolvedData(({ users }) => users.map(item => ({
    label: item.username,
    value: item.id,
    ...item,
  }))),
];

export const webUserSourceListMws = [
  combine({
    getKey: defaultGetHeadersKey,
  }),
  cache({
    getKey: defaultGetHeadersKey,
  }),
  urlMiddleware('/web/user/all'),
  mapResolvedData(({ users }) => users.map(item => ({
    label: item.username,
    value: item.username,
    ...item,
  }))),
];

export const webInternalUserSourceMws = [
  combine({
    getKey: defaultGetHeadersKey,
  }),
  cache({
    getKey: defaultGetHeadersKey,
  }),
  urlMiddleware('/web/user/internal'),
  mapResolvedData(({ users }) => users.map(item => ({
    label: item.username,
    value: item.id,
    ...item,
  }))),
];
