import { useMemo, useState } from 'react';
import { AuthorityId } from '@/enum';
import type { UrlKey } from '@/url';
import { useAccount } from './use-account';
import { createModel } from '.';

export type MenuKey = UrlKey;

export type MenuNode = MenuKey | [MenuKey, MenuNode[]];

const getMenuNodes = (authorityId: AuthorityId): MenuNode[] => {
  switch (authorityId) {
    case AuthorityId.Admin:
      return [
        [
          'product',
          [
            'productList',
            'productAudit',
            'productCombine',
            'productWebsiteList',
          ],
        ],
        [
          'offer',
          [
            'offerList',
            'offerAudit',
            'offerWebsiteList',
            'productMappingList',
          ]
        ],
        [
          'coupon',
          [
            'couponList',
          ]
        ],
        [
          'admin',
          [
            'adminSysUserList'
          ]
        ],
        [
          'report',
          [
            'reportAudit',
            'reportOfferAudit',
          ]
        ],
        [
          'designer',
          [
            'designerList',
            'designerSizeList',
          ]
        ],
        [
          'blog',
          [
            'blogList',
          ]
        ],
        [
          'copy',
          [
            'copyList',
          ]
        ],
        [
          'campaign',
          [
            'topicList',
            'campaignList',
          ]
        ],
        [
          'webUser',
          [
            'emailForm',
            'webUserList',
            'userMigrateForm',
          ]
        ],
        [
          'order',
          [
            'orderList',
          ]
        ],
        [
          'googleMerchant',
          [
            'googleMerchantList',
          ]
        ],
      ];
    case AuthorityId.Member:
      return [
        [
          'product',
          [
            'productList',
            'productAudit',
            'productCombine',
            'productWebsiteList',
          ],
        ],
        [
          'offer',
          [
            'offerList',
            'offerAudit',
            'offerWebsiteList',
            'productMappingList',
          ]
        ],
        [
          'coupon',
          [
            'couponList',
          ]
        ],
        [
          'report',
          [
            'reportAudit',
            'reportOfferAudit',
          ]
        ],
        [
          'designer',
          [
            'designerList',
            'designerSizeList',
          ]
        ],
        [
          'blog',
          [
            'blogList',
          ]
        ],
        [
          'copy',
          [
            'copyList',
          ]
        ],
        [
          'campaign',
          [
            'topicList',
            'campaignList',
          ]
        ],
        [
          'webUser',
          [
            'emailForm',
            'webUserList',
            'userMigrateForm',
          ]
        ],
        [
          'order',
          [
            'orderList',
          ]
        ],
        [
          'googleMerchant',
          [
            'googleMerchantList',
          ]
        ],
      ];
    default:
      return [];
  }
}

export const useLayoutMenu = createModel(() => {
  const [{ data: { account } }] = useAccount();

  const menuNodes = useMemo(() => getMenuNodes(account?.authorityId), [account]);

  const [menuStack, setMenuStack] = useState<MenuKey[]>([]);

  return [menuNodes, menuStack, setMenuStack] as const;
});
