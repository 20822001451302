import React from 'react';
import { CrudListFormItemConfig } from "@/component/crud-list";
import { FormItemList } from "@/component/form-item-list";
import { BlogCreation } from "@/interface";
import { t } from "@/lang";
import { RichContentEditor } from '@/component/rich-content-editor';
import { getAllDesignersList, getCategory1OptionsList, getCategory2OptionsList } from "@/service";

export const blogFormFields: CrudListFormItemConfig<BlogCreation>[] = [
  {
    divider: '文档信息',
  },
  {
    name: 'title',
    label: t('form.title'),
    type: 'input',
  },
  {
    name: 'thumbnail',
    label: t('form.thumbnail'),
    type: 'image',
  },
  {
    name: 'brief',
    label: t('form.brief'),
    type: 'textarea',
  },
  {
    name: 'author',
    label: t('form.author'),
    type: 'input',
    optional: true,
  },
  {
    name: 'key',
    label: 'key',
    type: 'input',
  },
  {
    divider: 'Meta 信息',
  },
  {
    name: ['meta', 'title'],
    label: 'title',
    type: 'input',
  },
  {
    name: ['meta', 'description'],
    label: 'description',
    type: 'textarea',
  },
  {
    name: ['meta', 'keywords'],
    label: 'keywords',
    type: 'custom',
    render: ({ value, onChange }) => {
      const keywords = typeof (value) == 'string' ? value.split(',') : value
      return (
        <FormItemList value={keywords} onChange={onChange} />
      )
    }
  },
  {
    divider: '关联信息',
  },
  {
    name: ['relatedInfo', 'designer'],
    label: t('placeholder.brand'),
    type: 'mult_select',
    options: (keyword) => getAllDesignersList({ keyword }),
    optional: true,
  },
  {
    name: ['relatedInfo', 'category1'],
    label: t('form.category1'),
    type: 'mult_select',
    options: (keyword) => getCategory1OptionsList({ keyword }),
    optional: true,
  },
  {
    name: ['relatedInfo', 'category2'],
    label: t('form.category2'),
    type: 'mult_select',
    options: (keyword) => getCategory2OptionsList({ keyword }),
    optional: true,
  },
  {
    name: ['relatedInfo', 'spuId'],
    label: 'spuId',
    type: 'custom',
    optional: true,
    render: ({ value, onChange }) => {
      const spuId = typeof (value) == 'string' ? value.split(',') : value
      return (
        <FormItemList value={spuId} onChange={onChange} />
      )
    }
  },
  {
    name: ['relatedInfo', 'keyword'],
    label: 'keyword',
    type: 'custom',
    optional: true,
    render: ({ value, onChange }) => {
      return (
        <FormItemList value={value} onChange={onChange} />
      )
    }
  },
  {
    divider: '正文',
  },
  {
    name: 'content',
    label: '',
    type: 'custom',
    render: ({ value, onChange }) => (
      <RichContentEditor
        value={value}
        onChange={onChange}
        placeholder="Blog 正文"
      />
    )
  }
];
