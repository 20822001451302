import React, { FC } from 'react';
import { Typography, Form, Input, Button, FormItemProps } from 'antd';
import { useCallback } from 'react';
import { AccountLoginInfo } from '@/interface';
import { useAccount } from '@/store/use-account';
import { t } from '@/lang';
import styles from './login.module.less';

const { Title } = Typography;

interface LoginFormValues {
  username: string;
  password: string;
}

const FORM_ITEM_COMMON_PROPS: FormItemProps = {
  labelAlign: 'left',
  labelCol: {
    span: 6,
  },
};

const Login: FC = () => {
  const [{ loading }, dispatchAccountAction] = useAccount();

  const onLogin = useCallback(
    ({ username, password }: LoginFormValues) =>
      dispatchAccountAction({
        type: 'login',
        username,
        password,
      }),
    [dispatchAccountAction]
  );

  return (
    <div className={styles.container}>
      <Form<AccountLoginInfo> className={styles.form} size="large" onFinish={onLogin} >
        <Title level={2}>{t('login.title')}</Title>
        <Form.Item
          {...FORM_ITEM_COMMON_PROPS}
          className={styles.form_item}
          label={t('login.username')}
          name="username"
          rules={[{ required: true, message: 'Please enter username' }]}
        >
          <Input className={styles.input} tabIndex={1} autoComplete="on" placeholder={t('login.username')} />
        </Form.Item>
        <Form.Item
          {...FORM_ITEM_COMMON_PROPS}
          className={styles.form_item}
          label={t('login.password')}
          name="password"
          rules={[{ required: true, message: 'Please enter password' }]}
        >
          <Input.Password placeholder={t('login.password')} />
        </Form.Item>
        <Button loading={loading} htmlType="submit" type="primary" size="large" className={styles.button}>
          {t('login.logIn')}
        </Button>
      </Form>
    </div>
  );
};

export default Login;