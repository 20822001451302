import React from 'react';
import { Modal, Tabs } from 'antd';
import { t } from '@/lang';
import { Action, ActionQuery } from '@/interface';
import { CrudList, CrudListProps, CrudListRowOperation } from '@/component/crud-list';
import { ActionStatus, ACTION_STATUSES, ACTION_TYPES } from '@/enum';
import { MaybePromise } from '@@/util-types';
import { useModal } from '@@/react-hooks';
import { JSONDisplay } from '../json-display';

export interface ActionCrudListProps<T extends Action, F extends ActionQuery>
  extends Required<Pick<
    CrudListProps<T, null, F>,
    'title' | 'filters' | 'columns' | 'read'
  >> {
  onOperate(operation: ActionStatus, listItem: T, onReload: () => void): MaybePromise<void>;
}

const generateOperation = (actionStatus: ActionStatus) => {
  const operations: CrudListRowOperation[] = [];

  if (actionStatus !== ActionStatus.Wait) {
    operations.push({
      operation: ActionStatus.Wait,
      name: t('button.reset'),
    });
  }

  if (actionStatus !== ActionStatus.Pass && actionStatus !== ActionStatus.Auto) {
    operations.push({
      operation: ActionStatus.Launch,
      name: t('button.launch'),
    }, {
      operation: ActionStatus.Pass,
      name: t('button.pass'),
    });
  }

  if (actionStatus !== ActionStatus.Reject) {
    operations.push({
      operation: ActionStatus.Reject,
      name: t('button.reject'),
    });
  }

  return operations;
}

const VIEW_PARAMS_OPERATION = 'view-params';

export function AuditCrudList<T extends Action, F extends ActionQuery>({
  title,
  filters,
  columns,
  onOperate,
  read
}: ActionCrudListProps<T, F>) {
  const [paramsModal, openParamsModal, closeParamsModal] = useModal<T>(null);

  return (
    <>
      <Tabs>
        {
          ACTION_STATUSES.map(actionStatus => (
            <Tabs.TabPane key={actionStatus} tab={t(`value.actionStatus.${actionStatus}`)} >
              <CrudList<T, null, F>
                title={title}
                rowKey="id"
                filters={[
                  ...filters,
                  {
                    name: 'actionType',
                    label: t('placeholder.actionType'),
                    type: 'select',
                    options: ACTION_TYPES.map(value => ({
                      value,
                      label: t(`value.actionType.${value}`),
                    })),
                  }
                ]}
                columns={[{
                  title: t('table.id'),
                  width: 100,
                  dataIndex: 'id',
                },
                ...columns,
                {
                  title: t('table.actionType'),
                  width: 110,
                  dataIndex: 'action_type',
                  display: type => t(`value.actionType.${type}`),
                }, {
                  title: t('table.description'),
                  width: 110,
                  dataIndex: 'description',
                }, {
                  title: t('table.createBy'),
                  dataIndex: 'create_by',
                  width: 140,
                }, {
                  title: t('table.auditBy'),
                  dataIndex: 'audit_by',
                  width: 140,
                }]}
                read={query => read({
                  ...query,
                  actionStatus
                })}
                operations={[
                  ...generateOperation(actionStatus),
                  {
                    operation: VIEW_PARAMS_OPERATION,
                    name: t('button.viewParams'),
                  }
                ]}
                onOperate={(operation, listItem, onReload) => {
                  if (operation === VIEW_PARAMS_OPERATION) {
                    return openParamsModal(listItem);
                  }
                  onOperate(operation as ActionStatus, listItem, onReload);
                }}
              />
            </Tabs.TabPane>
          ))
        }
      </Tabs>
      <Modal width="50vw" title="参数" visible={paramsModal.visible} footer={null} onCancel={closeParamsModal} >
        {
          paramsModal.data ? (
            <JSONDisplay data={paramsModal.data.parameter} />
          ) : null
        }
      </Modal>
    </>
  )
}
