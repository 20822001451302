import { ObjectUtils } from '@@/utils';
// import Cookies from 'js-cookie'
import enDict from './en'
import zhDict from './zh'

export type Lang = 'en' | 'zh';

export type LangDict = typeof zhDict;

const dicts: Record<Lang, LangDict> = {
  en: enDict as unknown as LangDict, // LATER:
  zh: zhDict,
}

// export function getLanguage(): Lang {
//   const chooseLanguage = Cookies.get('language')
//   if (chooseLanguage) return chooseLanguage

//   // if has not choose language
//   const language = (navigator.language).toLowerCase()
//   const locales = Object.keys(dicts)
//   for (const locale of locales) {
//     if (language.indexOf(locale) > -1) {
//       return locale as Lang;
//     }
//   }
//   return 'zh'
// }

export const translate = (path: string, source: Record<string, any> = {}) => {
  return (ObjectUtils.strProp(zhDict, path) || '').replace(/\{\{([^\][]+?)\}\}/g, (_, field) =>
    source[field] !== undefined ? String(source[field]) : ''
  );
};

export const t = translate;
