import { FlowObject } from '@reversible/common/es/flow';
import { Empty, Spin } from 'antd';
import React, { FC, ReactNode } from 'react';

function isEmpty(data: any) {
  if (Array.isArray(data)) return !data.length;

  return data == null;
}

export function Async<T>({
  source: {
    loading,
    ready,
    data,
  },
  children
}: {
  source: FlowObject<T>;
  children(data: T): ReactNode;
}) {
  if (loading) {
    return <Spin />
  }

  if (isEmpty(data)) {
    return <Empty />
  }

  return (
    <>
      {children(data)}
    </>
  );
};
