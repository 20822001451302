import React, { Fragment } from "react";
import { CrudListColumnConfig, CrudListFormConfig, CrudListFormDivider } from "@/component/crud-list";
import { Product, ProductCreation, ProductsQuery } from "@/interface";
import { t } from "@/lang";
import { SPU_STATUSES } from "@/enum";
import { Input, Popover } from "antd";
import { ObjectUtils } from "@reversible/common/es/utils";
import { GENDERS, SpuState } from "@reversible/schema";
import { getAllDesigners, getCategory1Options, getCategory2Options, getCurrencies, getRegions, getSizes, getAllWebUsers } from "@/service";

export const productFilters: CrudListFormConfig<ProductsQuery>[] = [
  {
    name: 'id',
    label: t('placeholder.spuId'),
    type: 'int',
  },
  {
    name: 'gender',
    label: t('placeholder.gender'),
    type: 'select',
    options: GENDERS.map(value => ({
      value,
      label: t(`value.gender.${value}`),
    })),
  },
  {
    name: 'brand',
    label: t('placeholder.brand'),
    type: 'select',
    deps: ['gender'],
    options: (keyword, { gender }) => gender ? getAllDesigners({ keyword, gender }) : [],
  },
  {
    name: 'category1',
    label: t('form.category1'),
    type: 'select',
    deps: ['gender'],
    options: (keyword, { gender }) => gender ? getCategory1Options({ keyword, gender }) : [],
  },
  {
    name: 'category2',
    label: t('form.category2'),
    type: 'select',
    deps: ['gender', 'category1'],
    options: (keyword, { gender, category1 }) => gender ? getCategory2Options({ keyword, gender, category1 }) : [],
  },
  {
    name: 'spuStatus',
    label: t('placeholder.spuStatus'),
    type: 'select',
    options: SPU_STATUSES.map(value => ({
      value,
      label: t(`value.spuStatus.${value}`),
    }))
  },
]

export const websiteProductColumns: CrudListColumnConfig<Product>[] = [
  {
    title: t('table.spuId'),
    dataIndex: 'id',
    width: 90,
    fixed: 'left',
  },
  {
    title: t('table.productImg'),
    dataIndex: 'imgUrl',
    width: 120,
    display: 'image',
  },
  {
    title: t('table.spuName'),
    dataIndex: 'name',
    width: 120,
    display: (name, item) => (
      <Popover content={
        <dl>
          {(['country', 'sizeUnit', 'sizes', 'color', 'composition', 'description'] as const).map(
            key => (
              <Fragment key={key} >
                <dt>
                  {t(`form.${key}`)}
                </dt>
                <dl>
                  {item[key]}
                </dl>
              </Fragment>
            )
          )}
        </dl>
      }>
        {name}
      </Popover>
    )
  },
  {
    title: t('table.brand'),
    dataIndex: 'brand',
    width: 100,
  },
  {
    title: t('table.gender'),
    dataIndex: 'gender',
    width: 60,
  },
  {
    title: t('table.category1'),
    dataIndex: 'category1',
    width: 80,
  },
  {
    title: t('table.category2'),
    dataIndex: 'category2',
    width: 80,
  },
  {
    title: t('table.spuStatus'),
    dataIndex: 'spuStatus',
    width: 100,
    display: spuStatus => t(`value.spuStatus.${spuStatus}`),
  },
];

export const productColumns: CrudListColumnConfig<Product>[] = [
  ...websiteProductColumns,
  {
    title: t('table.launchTime'),
    dataIndex: 'launchTime',
    display: 'time',
    width: 100,
  },
  {
    title: t('table.auditStatus'),
    dataIndex: 'auditStatus',
    width: 80,
    display: auditStatus => t(`value.auditStatus.${auditStatus}`),
  },
  {
    title: t('table.isOnline'),
    dataIndex: 'isOnline',
    width: 80,
    display: isOnline => t(`value.isOnline.${isOnline ? 1 : 0}`),
  },
];

export const adaptProductCreation = (product?: Product): ProductCreation => product ? {
  ...ObjectUtils.pick(product, [
    'name',
    'gender',
    'brand',
    'category1',
    'category2',
    'sizeUnit',
    'images',
    'country',
    'price',
    'currency',
    'color',
    'composition',
    'description',
    'publishTime',
    'sourceUrl',
    'retailer',
    'retailerSpuSn',
    'spuStatus',
    'createBy',
    'updateBy',
    'sourceId',
    'styleId',
    'userId',
    'collabDesigner',
  ]),
  unclassified: false,
  spuLock: true,
} : {
  name: '',
  gender: undefined,
  brand: undefined,
  category1: undefined,
  category2: undefined,
  sizeUnit: undefined,
  images: [],
  country: undefined,
  price: undefined,
  currency: undefined,
  color: '',
  composition: '',
  description: '',
  publishTime: undefined,
  sourceUrl: '',
  retailer: '',
  retailerSpuSn: '',
  spuStatus: SpuState.Available,
  unclassified: false,
  styleId: '',
  createBy: '',
  updateBy: '',
  spuLock: true,
  sourceId: '',
  userId: undefined,
  collabDesigner: [],
}

export const productFormFields: (CrudListFormConfig<ProductCreation> | CrudListFormDivider)[] = [
  {
    divider: '基础信息',
  },
  {
    name: 'name',
    type: 'custom',
    render: ({ value, onChange }) => (
      <Input value={value} onChange={e => onChange(e.target.value)} />
    ),
    label: t('form.spuName'),
  },
  {
    name: 'gender',
    label: t('form.gender'),
    type: 'select',
    options: GENDERS.map(value => ({
      value,
      label: t(`value.gender.${value}`),
    })),
  },
  {
    name: 'category1',
    label: t('form.category1'),
    type: 'select',
    deps: ['gender'],
    options: (keyword, { gender }) => gender ? getCategory1Options({ keyword, gender }) : [],
  },
  {
    name: 'category2',
    label: t('form.category2'),
    type: 'select',
    deps: ['gender', 'category1'],
    options: (keyword, { gender, category1 }) => gender ? getCategory2Options({ keyword, gender, category1 }) : [],
  },
  {
    name: 'brand',
    label: t('form.brand'),
    type: 'select',
    deps: ['gender'],
    options: (keyword, { gender }) => gender ? getAllDesigners({ gender, keyword }) : [],
  },
  {
    name: 'collabDesigner',
    label: '联名品牌',
    type: 'mult_select',
    deps: ['gender'],
    options: (keyword, { gender }) => gender ? getAllDesigners({ gender, keyword }) : [],
    optional: true,
  },
  {
    name: 'country',
    label: t('form.country'),
    type: 'select',
    options: (keyword) => getRegions({ keyword }),
    optional: true,
  },
  {
    name: 'sizeUnit',
    label: t('form.sizeUnit'),
    type: 'select',
    deps: ['gender', 'category1', 'category2'],
    options: (_, { gender, category1, category2 }) => gender && category1 && category2 ? getSizes({
      gender, category1, category2
    }) : [],
  },
  {
    name: 'publishTime',
    label: t('form.publishTime'),
    type: 'time',
    optional: true,
  },
  {
    divider: '图片信息',
  },
  {
    name: 'images',
    label: t('form.imgUrls'),
    type: 'images',
  },
  {
    divider: '详细信息',
  },
  {
    name: 'color',
    label: t('form.color'),
    type: 'input',
    optional: true,
  },
  {
    name: 'composition',
    label: t('form.composition'),
    type: 'input',
    optional: true,
  },
  {
    name: 'description',
    label: t('form.description'),
    type: 'textarea',
  },
  {
    divider: '价格信息',
  },
  {
    name: 'price',
    label: t('form.price'),
    type: 'int',
    optional: true,
  },
  {
    name: 'currency',
    label: t('form.currency'),
    type: 'select',
    options: (keyword) => getCurrencies({ keyword }),
    optional: true,
  },
  {
    divider: '来源信息',
  },
  {
    name: 'sourceUrl',
    label: t('form.sourceUrl'),
    type: 'input',
    optional: true,
  },
  {
    name: 'userId',
    label: t('form.retailer'),
    type: 'select',
    options: (keyword) => getAllWebUsers({ keyword }),
    optional: true,
  },
  {
    name: 'retailerSpuSn',
    label: t('form.retailerSpuSn'),
    type: 'input',
    optional: true,
  },
  {
    name: 'styleId',
    label: t('form.serialNumber'),
    type: 'input',
    optional: true,
  },
  {
    name: 'sourceId',
    label: 'source id',
    type: 'input',
    optional: true,
  },
  {
    divider: '运营操作',
  },
  {
    name: 'spuStatus',
    label: t('form.spuStatus'),
    type: 'select',
    options: SPU_STATUSES.map(value => ({
      value,
      label: t(`value.spuStatus.${value}`),
    }))
  },
  {
    divider: '商品信息锁定',
  },
  {
    name: 'spuLock',
    label: t('form.spuLock'),
    type: 'switch',
  },
];


export const productUpdateFormFields: (CrudListFormConfig<ProductCreation> | CrudListFormDivider)[] = [
  {
    divider: '基础信息',
  },
  {
    name: 'name',
    type: 'custom',
    render: ({ value, onChange }) => (
      <Input value={value} onChange={e => onChange(e.target.value)} />
    ),
    label: t('form.spuName'),
  },
  {
    name: 'country',
    label: t('form.country'),
    type: 'select',
    options: (keyword) => getRegions({ keyword }),
    optional: true,
  },
  {
    divider: '图片信息',
  },
  {
    name: 'images',
    label: t('form.imgUrls'),
    type: 'images',
  },
  {
    divider: '详细信息',
  },
  {
    name: 'color',
    label: t('form.color'),
    type: 'input',
    optional: true,
  },
  {
    name: 'composition',
    label: t('form.composition'),
    type: 'input',
    optional: true,
  },
  {
    name: 'description',
    label: t('form.description'),
    type: 'textarea',
  },
  {
    divider: '价格信息',
  },
  {
    name: 'price',
    label: t('form.price'),
    type: 'int',
    optional: true,
  },
  {
    name: 'currency',
    label: t('form.currency'),
    type: 'select',
    options: (keyword) => getCurrencies({ keyword }),
    optional: true,
  },
  {
    divider: '来源信息',
  },
  {
    name: 'sourceUrl',
    label: t('form.sourceUrl'),
    type: 'input',
    optional: true,
  },
  {
    name: 'userId',
    label: t('placeholder.userId'),
    type: 'select',
    options: (keyword) => getAllWebUsers({ keyword }),
    optional: true,
  },
  {
    name: 'retailerSpuSn',
    label: t('form.retailerSpuSn'),
    type: 'input',
    optional: true,
  },
  {
    name: 'styleId',
    label: t('form.serialNumber'),
    type: 'input',
    optional: true,
  },
  {
    name: 'sourceId',
    label: 'source id',
    type: 'input',
    optional: true,
  },
  {
    divider: '运营操作',
  },
  {
    name: 'spuStatus',
    label: t('form.spuStatus'),
    type: 'select',
    options: SPU_STATUSES.map(value => ({
      value,
      label: t(`value.spuStatus.${value}`),
    }))
  },
  {
    divider: '商品信息锁定',
  },
  {
    name: 'spuLock',
    label: t('form.spuLock'),
    type: 'switch',
  },
];