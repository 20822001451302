import { isPlainObject } from "@@/utils";

export const camelToSnakeCase = (str: string) => str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);

export const snakeToCamelCase = (str: string) => str
  .replace(/[_]([a-z])/g, letter => letter.toUpperCase())
  .replace(/_/g, '');

export function deepMapKeys(mapper: (str: string) => string) {
  function runMapping(input: Record<string, any>) {
    if (!isPlainObject(input)) return input;
    let isMutated = false;
    const output: Record<string, any> = {};
    for (const [key, value] of Object.entries(input)) {
      const mappedKey = mapper(key);
      const mappedValue = runMapping(value);
      if (!Object.is(mappedKey, key) || !Object.is(mappedValue, value)) {
        isMutated = true;
      }
      output[mappedKey] = mappedValue;
    }
    return isMutated ? output : input;
  };
  return runMapping;
}

export const deepMapCamelToSnake = deepMapKeys(camelToSnakeCase);
export const deepMapSnakeToCamel = deepMapKeys(snakeToCamelCase);
