import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { ConfigProvider } from 'antd';
import * as serviceWorker from './serviceWorker';
import { StoreProvider } from './store';
import { AppView } from './view';
import zhCN from 'antd/lib/locale/zh_CN';
import moment from 'moment';
import 'moment/locale/zh-cn';

import 'antd/dist/antd.less';
import './index.less';
import './style/iconfont/iconfont.less';
import './style/font/font.less';
import './antd-overrides.less';

moment.locale('zh-cn');

ReactDOM.render(
  <ConfigProvider locale={zhCN}>
    <DndProvider backend={HTML5Backend}>
      <BrowserRouter>
        <StoreProvider>
          <AppView />
        </StoreProvider>
      </BrowserRouter>
    </DndProvider>
  </ConfigProvider>,
  document.getElementById('root')
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
