import React, { FC, useRef, useState } from 'react';
import { getDesignerSize, batchPutDesignerSize, deleteDesignerSize } from '@/service';
import { CrudList, CrudListMethods } from "@/component/crud-list";
import { adaptDesignerSizeCreation, designerSizeColumns, designerSizeFilters, designerSizeFormFields } from './config';
import { DesignerSize, DesignerSizeQuery, DesignerSizeCreation } from "@/interface";
import { Button } from 'antd';
import { BatchDesignerSizeModal } from './component/batch-designer-size-modal';

const designerSizeList: FC = () => {
  const [batchEditing, setBatchEditing] = useState(false);

  const crudRef = useRef<CrudListMethods>();

  return (
    <>
      <CrudList<DesignerSize, DesignerSizeCreation, DesignerSizeQuery>
        ref={crudRef}
        title="designerSize"
        rowKey="id"
        auditable
        filters={designerSizeFilters}
        columns={designerSizeColumns}
        read={getDesignerSize}
        del={({ id }, actionConfig) => deleteDesignerSize({
          designerSizeId: id,
          ...actionConfig,
        })}
        update={({ id }, creation) => batchPutDesignerSize({
          designerSizes: [{
            id,
            ...creation,
          }],
        })}
        adaptCreation={adaptDesignerSizeCreation}
        fields={designerSizeFormFields}
        tableScroll={{
          x: 960
        }}
      >
        <Button type="primary" onClick={() => setBatchEditing(true)} >批量新建或编辑</Button>
      </CrudList>
      <BatchDesignerSizeModal visible={batchEditing} onClose={() => setBatchEditing(false)} onShouldRefresh={() => {
        crudRef.current.reload();
      }} />
    </>
  );
}

export default designerSizeList;

