import React, { FC, useRef } from 'react';
import { Order, OrderQuery } from '@/interface';
import { getOrders } from '@/service';
import { orderColumns, orderFilters } from './config';
import { CrudList, CrudListMethods } from '@/component/crud-list';
import { UserStatusModal } from './order-list-modal';
import { useModal } from '@@/react-hooks';
import { OrderStatus } from '@/enum';

const PASS_OPERATION = 'PASS';
const CANCEL_OPERATION = 'CANCEL';
const VIEW_OPERATION = 'VIEW'

export const OrderList: FC = () => {
  const [modalState, openOperationModal, closeOperationModal] = useModal<{
    operation: string;
    item: Order
  }>(null);

  const crudListMethodsRef = useRef<CrudListMethods>();

  return (
    <>
      <CrudList<Order, null, OrderQuery>
        ref={crudListMethodsRef}
        title="web user"
        rowKey="id"
        filters={orderFilters}
        columns={orderColumns}
        read={getOrders}
        operations=
        {
          ({ orderStatus }) => orderStatus == OrderStatus.PendingCapture ?
            [{
              operation: PASS_OPERATION,
              name: '确认'
            },
            {
              operation: CANCEL_OPERATION,
              name: '取消',
            },
            {
              operation: VIEW_OPERATION,
              name: '查看',
            }] : [{
              operation: VIEW_OPERATION,
              name: '查看',
            }]}
        onOperate={(operation, item) => {
          switch (operation) {
            case PASS_OPERATION:
              openOperationModal({
                operation,
                item
              });
            case CANCEL_OPERATION:
              openOperationModal({
                operation,
                item
              });
            case VIEW_OPERATION:
              openOperationModal({
                operation,
                item
              });
            default:
              break
          }
        }}
        tableScroll={{
          x: 1200
        }}
      />
      <UserStatusModal
        visible={modalState.visible}
        data={modalState.data?.item}
        cancel={modalState.data?.operation}
        onClose={closeOperationModal}
        onFinished={() => crudListMethodsRef.current.reload()}
      />
    </>
  )
}

export default OrderList;
