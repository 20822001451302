import moment, { Moment } from 'moment';
import { DateUtils, DateDataFormat } from '@@/utils';

export const MomentUtils = {
  parse: (input: any, format?: DateDataFormat) => {
    const date = DateUtils.parse(input, format);
    return date && moment(date);
  },
  serialize: (moment: Moment, format?: DateDataFormat) => {
    const date = moment?.toDate();
    return DateUtils.serialize(date, format);
  }
}
