import React, { FC } from 'react';
import { getWebsiteProducts } from '@/service';
import { CrudList } from "@/component/crud-list";
import { websiteProductColumns, productFilters } from './config';
import { Product, ProductsQuery } from '@/interface';

const ProductWebsiteList: FC = () => (
  <CrudList<Product, null, ProductsQuery>
    title="线上商品"
    rowKey="id"
    auditable
    isRequests={false}
    filters={productFilters}
    columns={websiteProductColumns}
    read={getWebsiteProducts}
  />
);

export default ProductWebsiteList;

