import React, { forwardRef, MutableRefObject, ReactElement, useCallback, useImperativeHandle, useMemo } from 'react';
import { Button, Table } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { prevent } from '@@/utils';
import { PagiQuery } from '@/interface';
import { CrudListProps } from './interface';
import styles from './crud-list.module.less';
import { useCrudRead } from './use-crud-read';
import { useCrudColumns } from './use-crud-columns';
import { useModal } from '@@/react-hooks';
import { CreateUpdateModal } from './create-update-modal';
import { DeleteModal } from './delete-modal';
import { CrudFilterItem } from './crud-filter-item';
import { t } from '@/lang';

export * from './interface';

export interface CrudListMethods {
  reload(): void;
}

function renderCrudList<T extends object, C extends object, F>(
  rawProps: CrudListProps<T, C, F>,
  ref: MutableRefObject<CrudListMethods>
): ReactElement {
  // set defaults
  const props = useMemo((): CrudListProps<T, C, F> => ({
    isModal: false,
    filters: [],
    fields: [],
    operations: [],
    auditable: false,
    isRequests: true,
    pageSize: 10,
    deletable: () => true,
    updatable: () => true,
    ...rawProps,
  }), [rawProps]);

  const [{ data: {
    filtersDisplay,
    list,
    total
  }, loading }, dispatch] = useCrudRead(props);

  const [updatingState, openUpdateModal, closeUpdateModal] = useModal<T>(null);
  const [deletingState, openDeleteModal, closeDeleteModal] = useModal<T>(null);

  const onReload = useCallback(() => {
    dispatch({
      type: 'reload',
    })
  }, []);

  useImperativeHandle(ref, () => ({
    reload: onReload,
  }), []);

  const tableColumns = useCrudColumns(props, openUpdateModal, openDeleteModal, onReload);

  const {
    isModal,
    title,
    filters,
    pageSize,
    rowKey,
    create,
    fields,
    auditable,
    update,
    del,
    adaptCreation,
    tableScroll,
  } = props;

  const onChangeFilters = useCallback((overrides: Partial<F>) => {
    dispatch({
      type: 'filter',
      overrides,
    })
  }, []);

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <form
          className={styles.form}
          onSubmit={prevent(() => {
            dispatch({ type: 'submit' });
          })}
        >
          <div className={styles.filter_items}>
            {
              filters.map(config => (
                <CrudFilterItem
                  {...config}
                  key={config.name}
                  filters={filtersDisplay}
                  onChangeFilters={onChangeFilters}
                />
              ))
            }
          </div>
          <div className={styles.filter_control}>
            <div className={styles.filter_buttons}>
              <Button className={styles.filter_button} type="default" onClick={() => dispatch({
                type: 'submit',
              })} >
                {t('button.search')}
              </Button>
              <Button className={styles.filter_button} type="default" onClick={() => dispatch({
                type: 'reset'
              })} >
                {t('button.reset')}
              </Button>
            </div>
            {
              create ? (
                <div>
                  <Button className={styles.create_button} icon={<PlusOutlined />} type="primary" onClick={() => openUpdateModal(null)} >
                    新建{title}
                  </Button>
                </div>
              ) : null
            }
            {props.children || null}
          </div>
        </form>
      </header>
      <Table<T>
        loading={loading}
        rowKey={rowKey}
        scroll={tableScroll}
        columns={tableColumns}
        dataSource={list}
        pagination={{
          current: filtersDisplay.page,
          total,
          pageSize,
          hideOnSinglePage: true,
          showSizeChanger: false,
          onChange: page => dispatch({
            type: 'reload',
            overrides: { page } as Partial<F & PagiQuery>,
          })
        }}
      />
      {
        update || create ? (
          <CreateUpdateModal
            visible={updatingState.visible}
            data={updatingState.data}
            title={title}
            isModal={isModal}
            onClose={closeUpdateModal}
            onReload={onReload}
            fields={fields}
            auditable={auditable}
            create={create}
            update={update}
            adaptCreation={adaptCreation}
          />
        ) : null}
      {
        del ? (
          <DeleteModal
            visible={deletingState.visible}
            data={deletingState.data}
            title={title}
            onClose={closeDeleteModal}
            onReload={onReload}
            auditable={auditable}
            del={del}
          />
        ) : null
      }
    </div>
  )
}

// type of forwardRef is redeclared, credit: https://fettblog.eu/typescript-react-generic-forward-refs/
export const CrudList = forwardRef(renderCrudList);
