import { t } from '@/lang';
import { Button } from 'antd';
import React, { FC } from 'react';
import styles from './form-modal-footer.module.less';

export interface FormModalFooterProps {
  form: string;
  loading?: boolean;
  onCancel(): void;
}

export const FormModalFooter: FC<FormModalFooterProps> = ({
  form,
  loading,
  onCancel
}) => (
  <>
    <Button size="large" className={styles.form_cancel_btn} onClick={onCancel} >
      {t('button.cancel')}
    </Button>
    <Button size="large" form={form} htmlType="submit" type="primary" loading={loading} >
      {t('button.confirm')}
    </Button>
  </>
)

export interface FormSubmitModalFooterProps {
  form: string;
  loading?: boolean;
}

export const FormSubmitModalFooter: FC<FormSubmitModalFooterProps> = ({
  form,
  loading,
}) => (
  <>
    <Button size="large" form={form} htmlType="submit" type="primary" loading={loading} >
      发送
    </Button>
  </>
)
