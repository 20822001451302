import React, { FC, useEffect } from 'react';
import { Button, Form, Input, Modal, Select } from 'antd';
import { useFlow } from '@@/react-hooks';
import { Report, ReportOffer } from '@/interface';
import { t } from '@/lang';
import { putReportAudit } from '@/service';
import { AuditStatus } from '@/enum';

const { TextArea } = Input;

const FORM_ID = 'report-audit-form';

interface FormValues {
  auditComment: string
  auditOption: string
}

export interface ReportAuditModalProps {
  visible: boolean;
  data: Report | ReportOffer;
  pass: boolean;
  reportType: string;
  onFinished(): void;
  onClose(): void;
}

export const ReportAuditModal: FC<ReportAuditModalProps> = ({
  visible,
  data,
  pass,
  reportType,
  onFinished,
  onClose,
}) => {
  const [{ loading }, submitFormValues] = useFlow<void, FormValues>(
    null,
    function* ({ call, block }, { auditOption, auditComment }) {
      yield block();
      yield call(putReportAudit, {
        id: data.id,
        data: {
          auditOption,
          auditComment,
          auditStatus: pass ? AuditStatus.Pass : AuditStatus.Reject
        }
      });
      onFinished();
      onClose();
    }
  );

  const [form] = Form.useForm<FormValues>();

  useEffect(() => {
    form.resetFields();
  }, [visible]);

  return (
    <Modal
      width="50vw" title={pass ? '通过report' : '拒绝report'}
      visible={visible}
      onCancel={onClose}
      footer={
        <>
          <Button size="large" onClick={onClose} >
            {t('button.cancel')}
          </Button>
          <Button size="large" form={FORM_ID} htmlType="submit" type="primary" loading={loading} >
            {t('button.confirm')}
          </Button>
        </>
      }>
      <Form<FormValues> id={FORM_ID} form={form} onFinish={submitFormValues} >
        <div>{pass ? `通过该report, 被举报的 ${reportType} 将被移除` : `拒绝该 report, 被举报的 ${reportType} 不受影响。其它举报该 offer 的 report 也将一并被拒绝。`}</div>
        <br />
        <div>{
          pass && reportType == 'offer' ?
            <Form.Item labelAlign='left' label={pass ? '通过理由' : '拒绝理由'} name="auditOption">
              <Select>
                <Select.Option key='Inauthentic Item' value='Inauthentic Item' >Inauthentic Item</Select.Option>
                <Select.Option key='Potential Item' value='Potential Item' >Potential Item</Select.Option>
                <Select.Option key='Other' value='Other' >Other</Select.Option>
              </Select>
            </Form.Item> : null
        }</div>
        <br />
        <Form.Item
          labelAlign='left'
          name="auditComment"
          label={pass ? '通过理由' : '拒绝理由'}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <TextArea rows={5} />
        </Form.Item>
      </Form>
    </Modal>
  )
}
