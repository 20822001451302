// export type MappedObject<T extends Record<string, any>, U extends Record<string, any>> = 
import { Option } from "@/interface";

export function mapKeys(source: Record<string, any>, map: Record<string, string>) {
  const output = {};
  for (const [fromKey, toKey] of Object.entries(map)) {
    output[toKey] = source[fromKey];
  }
  return output as Record<string, any>;
}

export const matchOptions = <T>(options: Option<T>[], keyword: string = '', matchValue = false): Option<T>[] => {
  const trimmed = keyword.trim().toLowerCase();
  return trimmed ? options.filter(({ label, value }) =>
    label.toLowerCase().includes(trimmed) ||
    (matchValue && String(value).includes(trimmed))
  ) : options;
};
