import React, { FC } from 'react';
import { message } from 'antd';
import { useFlow } from '@@/react-hooks';
import { postEmail } from '@/service';
import { EmailCreation } from '@/interface';
import { CreateUpdateForm } from '@/component/crud-list/create-update-form';
import { emailFormFields } from './config';
import styles from './send-email-modal.module.less';
import { Async } from '@/component/async';
import { useFlowEffect } from '@reversible/common/es/react-hooks';
import { FormSubmitModalFooter } from '@/component/form-modal-footer';


const FORM_ID = 'email-form';

const EmailForm: FC = () => {

  const dataState = useFlowEffect<EmailCreation>(null, function* ({
    call,
    put
  }) {
    yield put(null);

    yield put({
      'templateName': undefined,
      'templateData': '{}',
      'subject': undefined,
      'fromAddress': 'support@reversible.com',
      'fromAddressName': 'REVERSIBLE',
      'toAddresses': undefined,
    })
  }, []);

  const [{ loading }, dispatch] = useFlow<void, EmailCreation>(null, function* ({ call, block }, creation) {
    yield block();
    try {
      yield call(postEmail, creation)
      message.success('发送成功');
    }
    catch (error) {
      message.error(error.msg);
    }
  });

  return (
    <div className={styles.page_modal}>
      <div className={styles.modal}>
        <Async source={dataState} >
          {
            data => (
              <CreateUpdateForm
                initialValues={data}
                fields={emailFormFields}
                id={FORM_ID}
                onFinish={fields => dispatch(fields)}
                labelAlign="left"
                labelCol={{
                  span: 3
                }}
              />
            )
          }
        </Async>
        <span className={styles.submit}>
          <FormSubmitModalFooter form={FORM_ID} loading={loading} />
        </span>
      </div>
    </div>
  )
}

export default EmailForm;