import { PlusOutlined } from '@ant-design/icons';
import { Input, Tag, Tooltip } from 'antd';
import React, { FC, useEffect, useRef, useState } from 'react';

export const TagsEditor: FC<{
  value: string;
  onChange(value: string): void;
}> = ({
  value,
  onChange
}) => {
    const Tags = value ? value.split('##') : []

    const [inputVisible, setInputVisible] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [editInputIndex, setEditInputIndex] = useState(-1);
    const [editInputValue, setEditInputValue] = useState('');

    const inputRef = useRef(null);
    const editInputRef = useRef(null);

    useEffect(() => {
      if (inputVisible) {
        inputRef.current?.focus();
      }
    }, [inputVisible]);

    useEffect(() => {
      editInputRef.current?.focus();
    }, [inputValue]);

    const handleClose = (removedTag) => {
      const newTags = Tags.filter((tag) => tag !== removedTag);
      console.log(newTags);
      onChange(newTags.join('##'))
    };

    const showInput = () => {
      setInputVisible(true);
    };

    const handleInputChange = (e) => {
      setInputValue(e.target.value);
    };

    const handleInputConfirm = () => {
      if (inputValue && Tags.indexOf(inputValue) === -1) {
        onChange(Tags.length >= 1 ? Tags.join('##') + '##' + inputValue : inputValue)
      }
      setInputVisible(false);
      setInputValue('');
    };

    const handleEditInputChange = (e) => {
      setEditInputValue(e.target.value);
    };

    const handleEditInputConfirm = () => {
      const newTags = [...Tags];
      newTags[editInputIndex] = editInputValue;
      setEditInputIndex(-1);
      setInputValue('');
      onChange(newTags.join('##'))
    };

    return (
      <>
        {Tags.map((tag, index) => {
          if (editInputIndex === index) {
            return (
              <Input
                ref={editInputRef}
                key={tag}
                size="middle"
                className="tag-input"
                value={editInputValue}
                onChange={handleEditInputChange}
                onBlur={handleEditInputConfirm}
                onPressEnter={handleEditInputConfirm}
              />
            );
          }
          const isLongTag = tag.length > 20;
          const tagElem = (
            <Tag
              className="edit-tag"
              key={tag}
              closable={true}
              onClose={() => handleClose(tag)}
            >
              <span
                onDoubleClick={(e) => {
                  setEditInputIndex(index);
                  setEditInputValue(tag);
                  e.preventDefault();
                }}
              >
                {isLongTag ? `${tag.slice(0, 20)}...` : tag}
              </span>

            </Tag>
          );
          return isLongTag ? (
            <Tooltip title={tag} key={tag}>
              {tagElem}
            </Tooltip>
          ) : (
            tagElem
          );
        })}
        {inputVisible && (
          <Input
            style={{ 'top': '10px' }}
            ref={inputRef}
            type="text"
            size="middle"
            className="tag-input"
            value={inputValue}
            onChange={handleInputChange}
            onBlur={handleInputConfirm}
            onPressEnter={handleInputConfirm}
          />
        )}
        {!inputVisible && (
          <Tag className="site-tag-plus" onClick={showInput}>
            <PlusOutlined /> New Variant
          </Tag>
        )}
      </>
    );
  }
