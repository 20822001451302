import React, { useEffect, useState } from 'react';
import { Input } from 'antd';
import { CrudListColumnConfig, CrudListFormConfig, CrudListFormDivider } from "@/component/crud-list";
import { Designer, DesignerQuery, DesignerCreation, DesignerSize, DesignerSizeQuery, DesignerSizeCreation, DesignerSizeBatchFormValue } from "@/interface";
import { t } from "@/lang";
import { ObjectUtils, StringUtils } from "@reversible/common/es/utils";
import { GENDERS } from "@reversible/schema";
import { getAllDesigners, getCategory1Options, getCategory2Options, getSizes, getChatGpt, getInsImg } from "@/service";
import { TagsEditor } from './component/tags-editor';
import { CrudListFormItemConfig } from "@/component/crud-list";
import { DesignerStylesList } from './component/designer-styles'
import { useFlowEffect } from '@@/react-hooks';
import { ImageUploader } from '@/ui/images-uploader';
import { LoadingOutlined } from '@ant-design/icons';

const ASCII_CHAR_CODE_a = 97
const ASCII_CHAR_CODE_z = 123

const getPrefix = () => {
  const prefixList = ['0-9'];
  for (let i = ASCII_CHAR_CODE_a; i < ASCII_CHAR_CODE_z; i++) {
    prefixList.push(String.fromCharCode(i));
  }
  return prefixList;
};

export const DesignerFilters: CrudListFormConfig<DesignerQuery>[] = [
  {
    name: 'designer',
    label: t('placeholder.designer'),
    type: 'input',
  },
]

export const DesignerColumns: CrudListColumnConfig<Designer>[] = [
  {
    title: t('table.id'),
    dataIndex: 'id',
    width: 90,
  },
  {
    title: t('table.designer'),
    dataIndex: 'designer',
    width: 100,
  },
  {
    title: 'ins名称',
    dataIndex: 'insName',
    width: 100,
  },
  {
    title: 'logo',
    dataIndex: 'logo',
    width: 60,
    display: 'image',
  },
  {
    title: t('table.gender'),
    dataIndex: 'gender',
    width: 60,
  },
  {
    title: t('table.prefix'),
    dataIndex: 'prefix',
    width: 60,
  },
  {
    title: t('table.variants'),
    dataIndex: 'variants',
    width: 200,
    display: 'multiline',
  },
  {
    title: t('table.alias'),
    dataIndex: 'alias',
    width: 100,
  },
  {
    title: t('table.url_alias'),
    dataIndex: 'urlAlias',
    width: 100,
  },
  {
    title: '风格',
    dataIndex: 'styles',
    width: 100,
    display: (styles, item) => {
      const gender = item['gender']
      const value = typeof (styles) == 'string' || styles == null ? styles : styles[gender].join(',')
      return (
        <>
          <span>{value}</span>
        </>
      )
    }
  },
];

export const adaptDesignerCreation = (data?: Designer): DesignerCreation => data ? ObjectUtils.pick(
  data, [
  'designer',
  'gender',
  'prefix',
  'variants',
  'alias',
  'urlAlias',
  'summary',
  'styles',
  'insName',
  'logo',
]
) : {
  designer: '',
  gender: undefined,
  prefix: '',
  variants: '',
  alias: '',
  urlAlias: '',
  summary: '',
  styles: undefined,
  insName: '',
  logo: undefined,
}

export const generate_random_id = () => {
  let random_id = ''
  for (let index = 0; index < 9; index++) {
    const element = Math.floor(Math.random() * 10)
    random_id += element
  }
  return parseInt(random_id)
}

export const designerFormFields: (CrudListFormItemConfig<DesignerCreation> | CrudListFormDivider)[] = [
  {
    divider: 'designer信息',
  },
  {
    name: 'designer',
    label: t('form.designer'),
    type: 'input',
    overrides: (value: string) => {
      const normalized = StringUtils.normalize(value[0] || '').toLowerCase();
      const prefix = normalized.match(/[0-9]/) ? '0-9' : normalized.match(/[a-z]/) ? normalized : ''
      const urlAlias = value.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/[^a-z0-9 /-]+/g, '').replace(/[ /]+/g, '-')
      return {
        prefix,
        urlAlias
      }
    }
  },
  {
    name: 'insName',
    label: 'ins名称',
    type: 'input',
  },
  {
    name: 'logo',
    label: 'logo',
    type: 'custom',
    deps: ['designer', 'insName'],
    render: ({ value, depValues, onChange }) => {
      let logo = value
      let load = false
      const designer = depValues.designer
      const insName = depValues.insName
      if (designer && insName) {
        const s3_url = useFlowEffect([] as string[], function* ({ call, put }) {
          const { data } = yield call(getInsImg, { 'designer': designer, 'insName': insName });
          yield put(data)
        }, []);
        logo = s3_url.data.length ? s3_url.data : ''
        load = s3_url.loading
        useEffect(() => {
          if (logo) {
            const randomNum = generate_random_id()
            onChange(`${logo}?${randomNum}`)
          }
        }, [logo])
      }
      return <>
        <ImageUploader value={value} onChange={onChange} disabled={load} />
        {load ? <span>&nbsp;&nbsp;&nbsp;&nbsp;<LoadingOutlined /></span> : <></>}
      </>;
    }
  },
  {
    name: 'gender',
    label: t('form.gender'),
    type: 'checkbox',
    options: GENDERS.map(value => ({
      value,
      label: t(`value.gender.${value}`),
    })),
  },
  {
    name: 'prefix',
    label: t('form.prefix'),
    type: 'select',
    options: getPrefix().map(value => ({
      value,
      label: value,
    })),
  },
  {
    name: 'variants',
    label: t('form.variants'),
    type: 'custom',
    render: ({ value, onChange }) => (
      <TagsEditor value={value} onChange={onChange} />
    )
  },
  {
    name: 'alias',
    label: t('form.alias'),
    type: 'input',
  },
  {
    name: 'urlAlias',
    label: t('form.url_alias'),
    type: 'input',
  },
  {
    name: 'summary',
    label: t('form.summary'),
    type: 'custom',
    deps: ['designer', 'insName'],
    render: ({ value, depValues, onChange }) => {
      let load = false
      let summary = value
      const designer = depValues.designer
      const msg = `write a 100 word introduction for ${designer}`
      if (!!!value && designer && depValues.insName) {
        const { data, loading } = useFlowEffect('' as string, function* ({ call, put }) {
          const { data } = yield call(getChatGpt, { 'message': msg });
          yield put(data)
        }, []);
        summary = data.length ? data : ''
        load = loading
        useEffect(() => {
          if (summary) {
            onChange(summary)
          }
        }, [summary])
      }
      return <Input.TextArea
        allowClear
        placeholder={load ? '正在加载中...' : ''}
        autoSize={{ maxRows: 6 }}
        disabled={load ? true : false}
        value={value}
        onChange={onChange ? e => onChange(e.target.value) : undefined}
      />
    }
  },
  {
    name: 'styles',
    label: 'styles',
    type: 'custom',
    optional: true,
    deps: ['gender', 'designer', 'styles'],
    render: ({ value, depValues, onChange }) => {
      return <DesignerStylesList value={value} depValues={depValues} onChange={onChange}></DesignerStylesList>
    }
  },
];

export const designerSizeFilters: CrudListFormConfig<DesignerSizeQuery>[] = [
  {
    name: 'gender',
    label: t('placeholder.gender'),
    type: 'select',
    options: GENDERS.map(value => ({
      value,
      label: t(`value.gender.${value}`),
    })),
  },
  {
    name: 'designer',
    label: t('placeholder.brand'),
    type: 'select',
    deps: ['gender'],
    options: (keyword, { gender }) => gender ? getAllDesigners({ keyword, gender }) : [],
  },
  {
    name: 'category1',
    label: t('form.category1'),
    type: 'select',
    deps: ['gender'],
    options: (keyword, { gender }) => gender ? getCategory1Options({ keyword, gender }) : [],
  },
  {
    name: 'category2',
    label: t('form.category2'),
    type: 'select',
    deps: ['gender', 'category1'],
    options: (keyword, { gender, category1 }) => gender ? getCategory2Options({ keyword, gender, category1 }) : [],
  },
]

export const designerSizeColumns: CrudListColumnConfig<DesignerSize>[] = [
  {
    title: t('table.designerSizeID'),
    dataIndex: 'id',
    width: 90,
  },
  {
    title: t('table.designer'),
    dataIndex: 'designer',
    width: 120,
  },
  {
    title: t('table.category1'),
    dataIndex: 'category1',
    width: 120,
  },
  {
    title: t('table.category2'),
    dataIndex: 'category2',
    width: 120,
  },
  {
    title: t('table.gender'),
    dataIndex: 'gender',
    width: 60,
    display: 'multiline',
  },
  {
    title: t('table.sizeUnit'),
    dataIndex: 'sizeUnit',
    width: 80,
  },
];

export const adaptDesignerSizeCreation = (data?: DesignerSize): DesignerSizeCreation => data ? ObjectUtils.pick(
  data, [
  'designer',
  'category1',
  'category2',
  'gender',
  'sizeUnit',
]
) : {
  designer: '',
  category1: '',
  category2: '',
  gender: undefined,
  sizeUnit: '',
}

export const designerBasicFormFields: CrudListFormConfig<DesignerSizeCreation | DesignerSizeBatchFormValue>[] = [
  {
    name: 'gender',
    label: t('form.gender'),
    type: 'select',
    options: GENDERS.map(value => ({
      value,
      label: t(`value.gender.${value}`),
    })),
  },
  {
    name: 'designer',
    label: t('form.designer'),
    type: 'select',
    deps: ['gender'],
    options: (keyword, { gender }) => gender ? getAllDesigners({ gender, keyword }) : [],
  },
  {
    name: 'category1',
    label: t('form.category1'),
    type: 'select',
    deps: ['gender'],
    options: (keyword, { gender }) => gender ? getCategory1Options({ keyword, gender }) : [],
  },
];

export const designerSizeFormFields: CrudListFormConfig<DesignerSizeCreation>[] = [
  ...designerBasicFormFields,
  {
    name: 'category2',
    label: t('form.category2'),
    type: 'select',
    deps: ['gender', 'category1'],
    options: (keyword, { gender, category1 }) => gender ? getCategory2Options({ keyword, gender, category1 }) : [],
  },
  {
    name: 'sizeUnit',
    label: t('form.sizeUnit'),
    type: 'select',
    deps: ['gender', 'category1', 'category2'],
    options: (_, { gender, category1, category2 }) => gender && category1 && category2 ? getSizes({
      gender: gender, category1: category1, category2: category2
    }) : [],
  },
];