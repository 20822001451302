import React from 'react';
import { CrudListColumnConfig, CrudListFormConfig } from "@/component/crud-list";
import { Campaign, CampaignQuery, CampaignCreation, Topic, TopicCreation, TopicQuery } from "@/interface";
import { t } from "@/lang";
import { CAMPAIGN_STATUSES, CampaignStatus, CAMPAIGN_TYPE, CampaignType, TOPIC_STATUSES, TopicStatus } from "@/enum";
import { ObjectUtils } from "@@/utils";
import { CrudListFormItemConfig } from "@/component/crud-list";
import { FormItemDictList } from "@/component/form-item-list";

export const campaignFilters: CrudListFormConfig<CampaignQuery>[] = [
  {
    name: 'id',
    label: 'campaign ID',
    type: 'int',
  },
  {
    name: 'name',
    label: 'campaign name',
    type: 'input',
  },
  {
    name: 'topicName',
    label: 'topic name',
    type: 'input',
  },
  {
    name: 'status',
    label: 'campaign状态',
    type: 'select',
    options: CAMPAIGN_STATUSES.map(value => ({
      label: t(`value.campaignStatus.${value}`),
      value,
    })),
  },
]

export const campaignColumns: CrudListColumnConfig<Campaign>[] = [
  {
    title: 'campaign ID',
    width: 120,
    dataIndex: 'id',
    fixed: 'left',
  },
  {
    title: 'name',
    width: 120,
    dataIndex: 'name',
  },
  {
    title: '话题',
    width: 120,
    dataIndex: 'topicName',
  },
  {
    title: '封面页',
    width: 160,
    dataIndex: 'coverImageUrl',
    display: 'image',
  },
  {
    title: 'icon(dark模式)',
    width: 160,
    dataIndex: 'darkIconUrl',
    display: 'image',
  },
  {
    title: 'icon(light模式)',
    width: 160,
    dataIndex: 'lightIconUrl',
    display: 'image',
  },
  {
    title: '详情图片(宽屏)',
    width: 160,
    dataIndex: 'wideDetailImageUrl',
    display: 'image',
  },
  {
    title: '图片比例(宽屏)',
    width: 160,
    dataIndex: 'wideDetailImageRatio',
  },
  {
    title: '详情图片(窄屏)',
    width: 160,
    dataIndex: 'narrowDetailImageUrl',
    display: 'image',
  },
  {
    title: '图片比例(窄屏)',
    width: 160,
    dataIndex: 'narrowDetailImageRatio',
  },
  {
    title: '背景图片(宽屏)',
    width: 160,
    dataIndex: 'wideBackgroundImageUrl',
    display: 'image',
  },
  {
    title: '背景图片(窄屏)',
    width: 160,
    dataIndex: 'narrowBackgroundImageUrl',
    display: 'image',
  },
  {
    title: '介绍',
    width: 160,
    dataIndex: 'description',
    display: 'multiline',
  },
  {
    title: '类型',
    width: 100,
    dataIndex: 'type',
    display: type => t(`value.campaignType.${type}`),
  },
  {
    title: '状态',
    width: 90,
    dataIndex: 'status',
    display: status => t(`value.campaignStatus.${status}`),
  },
  {
    title: '优先级',
    width: 100,
    dataIndex: 'priority',
  },
  {
    title: '开始时间(UTC)',
    width: 160,
    dataIndex: 'startTime',
    display: 'time',
  },
  {
    title: '结束时间(UTC)',
    width: 160,
    dataIndex: 'endTime',
    display: 'time',
  },
];

const generate_random_id = () => {
  let random_id = ''
  for (let index = 0; index < 9; index++) {
    const element = Math.floor(Math.random() * 10)
    random_id += element
  }
  return parseInt(random_id)
}

export const adaptCampaignCreation = (data?: Campaign): CampaignCreation => data ? ObjectUtils.pick(
  data,
  [
    'name',
    'coverImageUrl',
    'darkIconUrl',
    'lightIconUrl',
    'wideDetailImageUrl',
    'wideDetailImageRatio',
    'narrowDetailImageUrl',
    'narrowDetailImageRatio',
    'wideBackgroundImageUrl',
    'narrowBackgroundImageUrl',
    'description',
    'type',
    'status',
    'priority',
    'additionalInfo',
    'startTime',
    'endTime',
    'id',
    'topicName'
  ]
) : {
  name: '',
  coverImageUrl: '',
  darkIconUrl: '',
  lightIconUrl: '',
  wideDetailImageUrl: '',
  wideDetailImageRatio: '',
  narrowDetailImageUrl: '',
  narrowDetailImageRatio: '',
  wideBackgroundImageUrl: '',
  narrowBackgroundImageUrl: '',
  description: '',
  type: CampaignType.Look,
  status: CampaignStatus.Available,
  priority: 1,
  additionalInfo: '{}',
  startTime: undefined,
  endTime: undefined,
  id: generate_random_id(),
  topicName: '',
}

export const campaignFormFields: CrudListFormItemConfig<CampaignCreation>[] = [
  {
    name: 'id',
    label: 'id',
    type: 'input',
    disabled: true,
  },
  {
    divider: '活动详情',
  },
  {
    name: 'name',
    label: '活动名称',
    type: 'input',
  },
  {
    name: 'topicName',
    label: '话题',
    type: 'input',
  },
  {
    name: 'coverImageUrl',
    label: '封面页',
    type: 'image',
  },
  {
    name: 'darkIconUrl',
    label: 'icon(dark模式)',
    type: 'image',
  },
  {
    name: 'lightIconUrl',
    label: 'icon(light模式)',
    type: 'image',
  },
  {
    name: 'wideDetailImageUrl',
    label: '详情图片(宽屏)',
    type: 'image',
  },
  {
    name: 'narrowDetailImageUrl',
    label: '详情图片(窄屏)',
    type: 'image',
  },
  {
    name: 'wideBackgroundImageUrl',
    label: '背景图片(宽屏)',
    type: 'image',
    optional: true,
  },
  {
    name: 'narrowBackgroundImageUrl',
    label: '背景图片(窄屏)',
    type: 'image',
    optional: true,
  },
  {
    name: 'description',
    label: '介绍',
    type: 'textarea',
  },
  {
    name: 'type',
    label: '类型',
    type: 'select',
    options: CAMPAIGN_TYPE.map(value => ({
      value,
      label: t(`value.campaignType.${value}`),
    })),
  },
  {
    name: 'status',
    label: '活动状态',
    type: 'select',
    options: CAMPAIGN_STATUSES.map(value => ({
      value,
      label: t(`value.campaignStatus.${value}`),
    })),
  },
  {
    name: 'priority',
    label: '优先级',
    type: 'input',
  },
  {
    name: 'additionalInfo',
    label: '额外信息',
    optional: true,
    type: 'custom',
    render: ({ value, onChange }) => {
      const info = Array.isArray(value) === Array.isArray([]) ? false : true
      let values = []
      if (info) {
        const value_json = JSON.parse(value)
        Object.keys(value_json).forEach(element => {
          values.push({ 'key': element, 'value': value_json[element] })
        });
      } else {
        values = value
      }
      return (
        <FormItemDictList value={values} onChange={onChange} />
      )
    }
  },
  {
    name: 'startTime',
    label: '开始时间(UTC)',
    type: 'time',
  },
  {
    name: 'endTime',
    label: '结束时间(UTC)',
    type: 'time',
  },
]


export const topicFilters: CrudListFormConfig<TopicQuery>[] = [
  {
    name: 'id',
    label: 'topic ID',
    type: 'int',
  },
  {
    name: 'name',
    label: 'topic name',
    type: 'input',
  },
  {
    name: 'status',
    label: 'topic状态',
    type: 'select',
    options: TOPIC_STATUSES.map(value => ({
      label: t(`value.topicStatus.${value}`),
      value,
    })),
  },
]

export const topicColumns: CrudListColumnConfig<Topic>[] = [
  {
    title: 'topic ID',
    width: 120,
    dataIndex: 'id',
    fixed: 'left',
  },
  {
    title: 'name',
    width: 120,
    dataIndex: 'name',
  },

  {
    title: '状态',
    width: 90,
    dataIndex: 'status',
    display: status => t(`value.topicStatus.${status}`),
  },
  {
    title: '创建时间',
    width: 160,
    dataIndex: 'createTime',
    display: 'time',
  },
  {
    title: '更新时间',
    width: 160,
    dataIndex: 'updateTime',
    display: 'time',
  },
];

export const adaptTopicCreation = (data?: Topic): TopicCreation => data ? ObjectUtils.pick(
  data,
  [
    'name',
    'status',
    'createTime',
    'updateTime',
    'id',
  ]
) : {
  name: '',
  status: TopicStatus.Available,
  createTime: undefined,
  updateTime: undefined,
  id: undefined,
}

export const topicFormFields: CrudListFormItemConfig<TopicCreation>[] = [
  {
    divider: 'topic 信息',
  },
  {
    name: 'name',
    label: 'topic 名称',
    type: 'input',
  },
  {
    name: 'status',
    label: 'topic 状态',
    type: 'select',
    options: TOPIC_STATUSES.map(value => ({
      value,
      label: t(`value.topicStatus.${value}`),
    })),
  },

]

