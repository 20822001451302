import React, { useMemo } from "react";
import { isString, ObjectUtils } from "@@/utils";
import { Divider, Form, FormProps } from "antd"
import { FormItem } from "./form-item";
import type { CrudListFormConfig, CrudListFormItemConfig } from "./interface";

export interface CreateUpdateFormProps<C> extends Pick<FormProps<C>, 'labelCol' | 'labelAlign'> {
  id?: string;
  initialValues: C;
  fields: CrudListFormItemConfig<C>[]
  onFinish(values: C): void;
}

export function CreateUpdateForm<C extends object>({
  id,
  fields,
  initialValues,
  onFinish,
  ...rest
}: CreateUpdateFormProps<C>) {
  const [form] = Form.useForm<C>();

  const onValuesChange = useMemo(() => {
    const fieldsWithOverrides = fields.filter((item) => !('divider' in item) && item.overrides) as CrudListFormConfig<C>[];
    return (changedValues: Partial<C>, values: C) => {
      const mergeOverrides = fieldsWithOverrides.reduce<Partial<C>>((c, { overrides, name }) => isString(name) && (name in changedValues) ? {
        ...c,
        ...(overrides(changedValues[name], values) || {}),
      } : c, {});
      if (!ObjectUtils.isEmpty(mergeOverrides)) {
        form.setFieldsValue(mergeOverrides as any);
      }
    }
  }, [fields]);

  return (
    <Form
      initialValues={initialValues}
      id={id}
      form={form}
      onValuesChange={onValuesChange}
      onFinish={onFinish}
      {...rest}
    >
      {
        fields.map((item) => {
          if ('divider' in item) {
            return <Divider key={`divider:${item.divider}`} >{item.divider}</Divider>
          }

          return <FormItem key={`form:${item.name}`} {...item} />
        })
      }
    </Form>
  )
};
