import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import { PlusOutlined } from "@ant-design/icons";
import { useFlow } from "@@/react-hooks";
import { noop, prevent, selectFiles, selectFile, uniqueId } from "@@/utils";
import { ImageUploaderSrc } from "@/interface";
import { UploadBox } from "./upload-box";
import { getBlobKey } from "@/util/blob";

import styles from "./images-uploader.module.less";
import { Button } from "antd";

const ACCEPT = "image/*";

export interface ImagesUploaderProps {
  value?: ImageUploaderSrc[];
  onChange?(images: ImageUploaderSrc[]): void;
}

const zipWithKey = (value: ImageUploaderSrc[]): [ImageUploaderSrc, string][] => {
  let urlSourceCount = 0;
  return (value || []).map((source) => {
    if (source instanceof File) {
      return [source, getBlobKey(source)];
    }
    return [source, String(urlSourceCount++)];
  });
};

export const ImagesUploader: FC<ImagesUploaderProps> = memo(
  ({
    value,
    onChange,
  }) => {
    const onRemove = useCallback(
      (index) => {
        onChange(value.filter((_, i) => i !== index));
      },
      [value]
    );

    const valueWithKey = useMemo(() => zipWithKey(value), [value]);

    const [, dispatch] = useFlow(null, function* ({ block, call }) {
      yield block();
      const files: File[] = yield call(selectFiles, ACCEPT);
      if (files.length) {
        onChange([...value, ...files]);
      }
    });

    const selectorKey = useMemo(uniqueId, []); // the key of the current instance

    const onMove = useCallback(
      (from: number, to: number) => {
        const toBeMoved = value[from];
        const replaced = value.slice();
        replaced[from] = null;
        replaced.splice(to, 0, toBeMoved);
        onChange(replaced.filter((item) => item));
      },
      [value, onChange]
    );

    // prevent window dragover & drop events
    useEffect(() => {
      const callback = prevent(noop);
      window.addEventListener("dragover", callback, false);
      window.addEventListener("drop", callback, false);
      window.addEventListener("dragenter", callback, false);
      return () => {
        window.removeEventListener("dragover", callback);
        window.removeEventListener("drop", callback);
        window.removeEventListener("dragenter", callback);
      };
    }, []);

    return (
      <>
        {
          valueWithKey.length ? (
            <div className={styles.images}>
              {valueWithKey.map(([source, key], index) => (
                <UploadBox
                  key={key}
                  selectorKey={selectorKey}
                  index={index}
                  deletable
                  draggable
                  source={source}
                  onDelete={() => onRemove(index)}
                  onMove={onMove}
                />
              ))}
            </div>
          ) : null
        }
        <Button onClick={dispatch} icon={<PlusOutlined />} >添加图片</Button>
      </>
    );
  }
);

export interface ImageUploaderProps {
  value: ImageUploaderSrc;
  onChange(value: ImageUploaderSrc): void;
  deletable?: boolean;
  disabled?: boolean;
}

export const ImageUploader: FC<ImageUploaderProps> = ({ value, onChange, deletable = true, disabled = false }) => {
  const [, dispatch] = useFlow(null, function* ({ block, call }) {
    yield block();
    const file: File = yield call(selectFile, ACCEPT);
    if (file) {
      onChange(file);
    }
  });

  return value ? (
    <UploadBox
      deletable={deletable}
      source={value}
      onDelete={() => onChange(undefined)}
    />
  ) : (
    <Button onClick={dispatch} icon={<PlusOutlined />} disabled={disabled}>
      上传图片
    </Button>
  )
}

export interface ImageSelectDisplayProps {
  value: ImageUploaderSrc[];
}
export const ImageSelectDisplay: FC<ImageSelectDisplayProps> = memo(
  ({ value }) => {
    const valueWithKey = zipWithKey(value);

    return (
      <div className={styles.image_select}>
        {valueWithKey.map(([source, key]) => (
          <UploadBox key={key} source={source} />
        ))}
      </div>
    );
  }
);
