import React, { FC } from 'react';
import { getTopic, deleteTopic, postTopic, putTopic } from '@/service';
import { CrudList } from "@/component/crud-list";
import { topicColumns, topicFilters, adaptTopicCreation, topicFormFields } from './config';
import { Topic, TopicQuery, TopicCreation } from '@/interface';
import { TopicStatus } from '@/enum';


const TopicList: FC = () => (
  <CrudList<Topic, TopicCreation, TopicQuery>
    title="topic"
    rowKey="id"
    auditable
    filters={topicFilters}
    columns={topicColumns}
    read={getTopic}
    deletable={() => false}
    updatable={() => false}
    // del={({ id }, actionConfig) => deleteTopic({
    //   topicId: id,
    //   ...actionConfig,
    // })}
    // update={({ id }, creation) => putTopic({
    //   topicId: id,
    //   data: creation
    // })}
    create={postTopic}
    adaptCreation={adaptTopicCreation}
    fields={topicFormFields}
    tableScroll={{
      x: 960
    }}
  />
);

export default TopicList;