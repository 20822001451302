import React, { Fragment } from "react";
import { CrudListColumnConfig, CrudListFormConfig, CrudListFormDivider } from "@/component/crud-list";
import { Offer, OfferCreation, OffersQuery, MappingQuery, Mapping } from "@/interface";
import { t } from "@/lang";
import { OFFER_STATUSES, CONDITIONS, ExpireTime, EXPIRE_TIMES, OFFER_TYPES, OfferType, Condition } from "@/enum";
import { Popover } from "antd";
import { ObjectUtils, MaybePromiseUtils } from "@@/utils";
import { mapKeys, matchOptions } from "@/util/data";
import { getAllWebUsersList, getCurrencies, getProduct, getAllWebUsers } from "@/service";
import styles from '@/component/crud-list/crud-list.module.less';
import { Image } from 'antd';
import { StarFilled } from '@ant-design/icons';

export const mappingFilters: CrudListFormConfig<MappingQuery>[] = [
  {
    name: 'spuId',
    label: t('placeholder.spuId'),
    type: 'int',
  },
  {
    name: 'retailer',
    label: 'retailer',
    type: 'select',
    options: (keyword) => getAllWebUsersList({ keyword }),
  },
]


export const mappingColumns: CrudListColumnConfig<Mapping>[] = [
  {
    title: t('table.brand'),
    dataIndex: 'brand',
    width: 85,
  },
  {
    title: t('table.offerImg'),
    dataIndex: 'imgUrl',
    width: 120,
    display: (imgUrl, item) => {
      return (
        <>
          <Image className={styles.cell_img} src={imgUrl} />
          {
            item.isOfficial ? (
              <StarFilled className={styles.icon} />
            ) : null
          }
        </>
      )
    }
  },
  {
    title: t('table.spuId'),
    dataIndex: 'spuId',
    width: 85,
  },
  {
    title: 'Retailer',
    dataIndex: 'retailer',
    width: 85,
  },
  {
    title: t('table.offerName'),
    dataIndex: 'offerName',
    width: 240,
    display: (offerName, item) => (
      <Popover content={
        <dl>
          {['brand', 'gender', 'category1', 'category2'].map(
            key => (
              <Fragment key={key} >
                <dt>
                  {t(`form.${key}`)}
                </dt>
                <dl>
                  {item[key]}
                </dl>
              </Fragment>
            )
          )}
        </dl>
      }>
        <a href={item['offerUrl']} target="_blank">{offerName}</a>
      </Popover>
    )
  },
];

export const offerFilters: CrudListFormConfig<OffersQuery>[] = [
  {
    name: 'offerId',
    label: t('placeholder.offerId'),
    type: 'int',
  },
  {
    name: 'spuId',
    label: t('placeholder.spuId'),
    type: 'int',
  },
  {
    name: 'userId',
    label: 'retailer',
    type: 'select',
    options: (keyword) => getAllWebUsers({ keyword }),
  },
  {
    name: 'offerStatus',
    label: t('placeholder.offerStatus'),
    type: 'select',
    options: OFFER_STATUSES.map(value => ({
      label: t(`value.offerStatus.${value}`),
      value,
    })),
  },
]

export const offerColumns: CrudListColumnConfig<Offer>[] = [
  {
    title: t('table.offerId'),
    dataIndex: 'id',
    width: 85
  },
  {
    title: t('table.brand'),
    dataIndex: 'brand',
    width: 85,
  },
  {
    title: t('table.offerImg'),
    dataIndex: 'imgUrl',
    width: 120,
    display: (imgUrl, item) => {
      return (
        <>
          <Image className={styles.cell_img} src={imgUrl} />
          {
            item.isOfficial ? (
              <StarFilled className={styles.icon} />
            ) : null
          }
        </>
      )
    }
  },
  {
    title: t('table.spuId'),
    dataIndex: 'spuId',
    width: 85,
  },
  {
    title: 'Retailer',
    dataIndex: 'retailer',
    width: 85,
  },
  {
    title: t('table.offerName'),
    dataIndex: 'offerName',
    width: 240,
    display: (offerName, item) => (
      <Popover content={
        <dl>
          {['brand', 'gender', 'category1', 'category2'].map(
            key => (
              <Fragment key={key} >
                <dt>
                  {t(`form.${key}`)}
                </dt>
                <dl>
                  {item[key]}
                </dl>
              </Fragment>
            )
          )}
        </dl>
      }>
        <a href={item['offerUrl']} target="_blank">{offerName}</a>
      </Popover>
    )
  },
  {
    title: t('table.size'),
    dataIndex: 'size',
    width: 60,
  },
  {
    title: t('table.offerType'),
    dataIndex: 'offerType',
    width: 90,
    display: offerType => t(`value.offerType.${offerType}`),
  },
  {
    title: t('table.offerCondition'),
    dataIndex: 'offerCondition',
    width: 80,
    display: condition => t(`value.condition.${condition}`),
  },
  {
    title: t('table.offerStatus'),
    dataIndex: 'offerStatus',
    width: 80,
    display: offerStatus => t(`value.offerStatus.${offerStatus}`),
  }
];

export const adaptOfferCreation = (data?: Offer): OfferCreation => data ? {
  ...ObjectUtils.pick(data, [
    'userId',
    'spuId',
    'size',
    'inventory',
    'images',
    'measurement',
    'offerName',
    'offerDescription',
    'offerType',
    'addressId',
    'retailer',
  ]),
  ...mapKeys(data, {
    offerCondition: 'condition',
    offerValidTime: 'validTime',
    offerPrice: 'price',
    offerCurrency: 'currency',
    offerCountry: 'country',
    offerShipping: 'shipping',
    offerUrl: 'url',
  }) as Pick<OfferCreation, 'condition' | 'validTime' | 'price' | 'currency' | 'country' | 'shipping' | 'url'>,
  addressId: undefined,
  paymentName: '',
  paymentAccount: '',
  skipRatio: false,
  syncSpu: false,
} : {
  userId: undefined,
  spuId: undefined,
  size: undefined,
  inventory: 1,
  images: [],
  measurement: '',
  offerName: '',
  offerDescription: '',
  offerType: OfferType.Ask,
  condition: Condition.BrandNew,
  validTime: ExpireTime.Never,
  price: undefined,
  currency: undefined,
  country: undefined,
  addressId: undefined,
  url: '',
  retailer: '',
  shipping: [],
  paymentName: '',
  paymentAccount: '',
  skipRatio: false,
  syncSpu: false,
}

export const offerFormFields: (CrudListFormConfig<OfferCreation> | CrudListFormDivider)[] = [
  {
    divider: '基础信息',
  },
  {
    name: 'userId',
    label: t('placeholder.userId'),
    type: 'select',
    debounce: 200,
    options: (keyword) => getAllWebUsers({ keyword }),
  },
  {
    name: 'spuId',
    label: t('placeholder.spuId'),
    type: 'int',
    optional: true,
  },
  {
    name: 'size',
    label: t('placeholder.size'),
    type: 'select',
    deps: ['spuId'],
    debounce: 200,
    options: (keyword, { spuId }) => !spuId ? [] : MaybePromiseUtils.map(
      getProduct({
        spuId
      }),
      ({ data }) => matchOptions(data.sizes.map(value => ({
        value,
        label: value,
      })), keyword)
    ),
  },
  {
    name: 'inventory',
    label: t('placeholder.inventory'),
    type: 'int',
  },
  {
    divider: '图片信息',
  },
  {
    name: 'images',
    label: t('form.imgUrl'),
    type: 'images',
  },
  {
    divider: '详细信息',
  },
  {
    name: 'measurement',
    label: t('form.measurement'),
    type: 'input',
    optional: true,
  },
  {
    name: 'offerName',
    label: t('form.offerName'),
    type: 'input',
  },
  {
    name: 'offerType',
    label: t('form.offerType'),
    type: 'select',
    options: OFFER_TYPES.map(value => ({
      value,
      label: t(`value.offerType.${value}`),
    })),
  },
  {
    name: 'condition',
    label: t('form.offerCondition'),
    type: 'select',
    options: CONDITIONS.map(value => ({
      value,
      label: t(`value.condition.${value}`),
    })),
  },
  {
    name: 'url',
    label: t('form.offerUrl'),
    type: 'input',
    optional: true,
    rules: [{
      type: 'url',
      message: '请输入合法的链接',
    }]
  },
  {
    name: 'validTime',
    label: t('form.offerValidTime'),
    type: 'select',
    options: EXPIRE_TIMES.filter(value => value !== ExpireTime.Debug10Min).map(value => ({
      value,
      label: t(`value.expireTime.${value}`),
    })),
  },
  {
    divider: '价格信息',
  },
  {
    name: 'price',
    label: t('form.offerPrice'),
    type: 'int',
  },
  {
    name: 'currency',
    label: t('form.offerCurrency'),
    type: 'select',
    options: keyword => getCurrencies({
      keyword
    }),
  }
]