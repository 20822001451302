export type UrlKey = keyof typeof url;

/**
 * url 的工厂函数组成的模块
 */
export const url = {
  // product
  product: () => '/product',
  productList: () => `${url.product()}/list`,
  productCombine: () => `${url.product()}/combine`,
  productAudit: () => `${url.product()}/audit`,
  productWebsiteList: () => `${url.product()}/website-list`,

  // offer
  offer: () => '/offer',
  offerList: () => `${url.offer()}/list`,
  offerAudit: () => `${url.offer()}/audit`,
  offerWebsiteList: () => `${url.offer()}/website-list`,
  productMappingList: () => `${url.offer()}/product-mapping-list`,

  // coupon
  coupon: () => '/coupon',
  couponList: () => `${url.coupon()}/list`,

  // admin
  admin: () => '/admin',
  adminSysUserList: () => `${url.admin()}/sys-user`,

  // report
  report: () => '/report',
  reportAudit: () => `${url.report()}/audit`,
  reportOfferAudit: () => `${url.report()}/offer`,

  // designer
  designer: () => '/designer',
  designerList: () => `${url.designer()}/list`,
  designerSizeList: () => `${url.designer()}/designer-size`,

   // blog
   blog: () => '/blog',
   blogList: () => `${url.blog()}/list`,

   // copy
   copy: () => '/copy',
   copyList: () => `${url.copy()}/list`,

   // campaign
   campaign: () => '/campaign',
   campaignList: () => `${url.campaign()}/list`,
   topicList: () => `${url.campaign()}/topic-list`,

   //web user
   webUser: () => '/webUser',
   emailForm: () => `${url.webUser()}/email`,
   webUserList: () => `${url.webUser()}/list`,
   userMigrateForm: () => `${url.webUser()}/migrate`,

   //order
   order: () => '/order',
   orderList: () => `${url.order()}/list`,

   //GoogleMerchantList
   googleMerchant: () => '/google_merchant',
   googleMerchantList: ()=> `${url.googleMerchant()}/list`,
};

export const WEB_ORIGIN = {
  local: 'https://www.reversible.dev',
  dev: 'https://www.reversible.dev',
  live: 'https://www.reversible.live',
  prod: 'https://www.reversible.com',
}[process.env.DEPLOY_ENV];
