import React, { FC } from 'react';
import { MaybePromiseUtils } from '@@/utils';
import { AuditCrudList } from '@/component/audit-crud-list';
import { OfferAction, OfferActionQuery } from '@/interface';
import { t } from '@/lang';
import { auditOfferAction, getOfferActions } from '@/service';

const OfferAudit: FC = () => (
  <AuditCrudList<OfferAction, OfferActionQuery>
    title="offer 审核"
    filters={[
      {
        name: 'offerId',
        label: t('placeholder.offerId'),
        type: 'input'
      }
    ]}
    columns={[
      {
        title: t('table.offerId'),
        width: 100,
        dataIndex: 'offerId',
      }
    ]}
    read={getOfferActions}
    onOperate={(actionStatus, { id }, onReload) => {
      MaybePromiseUtils.map(
        auditOfferAction({
          actionId: id,
          actionStatus,
        }),
        onReload
      )
    }}
  />
)

export default OfferAudit;
