import React, { FC, useRef } from 'react';
import { User, UserQuery, UserCreation } from '@/interface';
import { getWebUser, putWebUserStatus } from '@/service';
import { UserColumns, UserFilters, adaptUserCreation, userFormFields } from './config';
import { CrudList, CrudListMethods } from '@/component/crud-list';
import { UserStatusModal } from './user-list-modal';
import { useModal } from '@@/react-hooks';
import { UserStatus } from '@/enum';

const SUSPENED_OPERATION = 'SUSPENED_OPERATION';
const DELETE_OPERATION = 'DELETE';

export const WebUserList: FC = () => {
  const [modalState, openModal, closeModal] = useModal<{
    operation: string;
    item: User
  }>(null);

  const crudListMethodsRef = useRef<CrudListMethods>();

  return (
    <>
      <CrudList<User, UserCreation, UserQuery>
        ref={crudListMethodsRef}
        title="web user"
        rowKey="id"
        filters={UserFilters}
        columns={UserColumns}
        read={getWebUser}
        update={({ id }, creation) => putWebUserStatus({
          userId: id,
          operation: 'email_verified',
          emailVerified: creation.emailVerified,
        })}
        adaptCreation={adaptUserCreation}
        fields={userFormFields}
        operations={
          ({ userStatus }) => userStatus != UserStatus.Suspended ?
            [{
              operation: SUSPENED_OPERATION,
              name: '禁用'
            },
            {
              operation: DELETE_OPERATION,
              name: '删除',
            }] : [{
              operation: DELETE_OPERATION,
              name: '删除',
            }]}
        onOperate={(operation, item) => {
          switch (operation) {
            case SUSPENED_OPERATION:
              openModal({
                operation,
                item
              });
            case DELETE_OPERATION:
              openModal({
                operation,
                item
              });
            default:
              break
          }
        }}
        tableScroll={{
          x: 1400
        }}
      />
      <UserStatusModal
        visible={modalState.visible}
        data={modalState.data?.item}
        del={modalState.data?.operation === DELETE_OPERATION}
        onClose={closeModal}
        onFinished={() => crudListMethodsRef.current.reload()}
      />
    </>
  )
}

export default WebUserList;
