import React, { FC } from 'react';
import { getCopy, deleteCopy, postCopy, putCopy } from '@/service';
import { CrudList } from "@/component/crud-list";
import { copyColumns, copyFilters, adaptCopyCreation, copyFormFields } from './config';
import { Copy, CopyQuery, CopyCreation } from '@/interface';
import { CopyStatus } from '@/enum';

const adapt = (data) => {
  if (data) {
    data['gender'] = data ? data.search.gender : ''
    data['category1'] = data ? data.search.category1 : ''
    data['category2'] = data ? data.search.category2 : ''
    data['keyword'] = data ? data.search.keyword : ''
    data['brand'] = data ? data.search.brand : ''
  }
  return adaptCopyCreation(data)
}

const CopyList: FC = () => (
  <CrudList<Copy, CopyCreation, CopyQuery>
    isModal={true}
    title="copy"
    rowKey="id"
    auditable
    filters={copyFilters}
    columns={copyColumns}
    read={getCopy}
    deletable={({ copyStatus }) => copyStatus !== CopyStatus.Deleted}
    updatable={({ copyStatus }) => copyStatus !== CopyStatus.Deleted}
    del={({ id }, actionConfig) => deleteCopy({
      copyId: id,
      ...actionConfig,
    })}
    update={({ id }, creation) => putCopy({
      copyId: id,
      data: creation
    })}
    create={postCopy}
    adaptCreation={(data) => adapt(data)}
    fields={copyFormFields}
    tableScroll={{
      x: 960
    }}
  />
);

export default CopyList;