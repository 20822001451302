import React, { FC, useMemo } from 'react';
import { Avatar, Breadcrumb, Button, Dropdown, Layout, Menu } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { url, UrlKey } from '@/url';
import { t } from '@/lang';
import { MenuNode, useLayoutMenu } from '@/store/use-layout-menu';
import { useAccount } from '@/store/use-account';

import styles from './nav-layout.module.less';

const menuTitleDict: Record<UrlKey, string> = {
  product: t('route.productManagement'),
  productList: t('route.libraryProductList'),
  productAudit: t('route.productActionList'),
  productCombine: t('route.combineProduct'),
  productWebsiteList: t('route.websiteProductList'),
  offer: t('route.offerManagement'),
  offerList: t('route.libraryOfferList'),
  offerAudit: t('route.offerActionList'),
  offerWebsiteList: t('route.websiteOfferList'),
  productMappingList: t('route.productMappingList'),
  coupon: t('route.coupon'),
  couponList: t('route.couponList'),
  admin: t('route.adminManagement'),
  adminSysUserList: t('route.adminSysUserList'),
  report: t('route.report'),
  reportAudit: t('route.reportAudit'),
  reportOfferAudit: t('route.reportOfferAudit'),
  designer: t('route.designer'),
  designerList: t('route.designerList'),
  designerSizeList: t('route.designerSizeList'),
  blog: t('route.blog'),
  blogList: t('route.blogList'),
  copy: t('route.copy'),
  copyList: t('route.copyList'),
  campaign: t('route.campaign'),
  campaignList: t('route.campaignList'),
  topicList: t('route.topicList'),
  emailForm: t('route.emailForm'),
  webUser: t('route.webUser'),
  webUserList: t('route.webUserList'),
  userMigrateForm: t('route.userMigrateForm'),
  order: t('route.orderManagement'),
  orderList: t('route.orderList'),
  googleMerchant: t('route.googleMerchant'),
  googleMerchantList: t('route.googleMerchantList'),
};

const renderMenuNodes = (nodes: MenuNode[]) => {
  if (!nodes.length) return null;

  return nodes.map(node => {
    if (Array.isArray(node)) {
      const [self, subList] = node;
      return (
        <Menu.SubMenu key={self} title={menuTitleDict[self]} >
          {renderMenuNodes(subList)}
        </Menu.SubMenu>
      )
    }

    return <Menu.Item key={node}>
      <Link to={url[node]()}>
        {menuTitleDict[node]}
      </Link>
    </Menu.Item>
  })
}

export const NavLayout: FC = ({ children }) => {
  const [menuNodes, menuStack] = useLayoutMenu();

  const defaultOpenKeys = useMemo(() => menuStack?.[0] && [menuStack?.[0]], [menuStack]);

  const [{ data: { account } }, dispatchAccountAction] = useAccount();

  return <Layout className={styles.layout} >
    <Layout.Header className={styles.header} >
      <h1 className={styles.title}>
        {t('login.title')}
      </h1>
      <div className={styles.user_info}>
        <Avatar size="small" src={account.avatar} />
        <Dropdown trigger={['click']} arrow overlayClassName={styles.dropdown} overlay={
          <div className={styles.dropdown_content}>
            <dl>
              <dt>当前账号</dt>
              <dd>{account.email}</dd>
            </dl>
            <Button danger onClick={() => dispatchAccountAction({
              type: 'logout'
            })} >退出登录</Button>
          </div>
        } >
          <span className={styles.username}>
            {account.name}
            <DownOutlined className={styles.down_icon} />
          </span>
        </Dropdown>
      </div>
    </Layout.Header>
    <Layout >
      <Layout.Sider width={200} >
        {
          defaultOpenKeys ? (
            <Menu
              className={styles.menu}
              mode="inline"
              defaultOpenKeys={defaultOpenKeys}
              selectedKeys={menuStack}
            >
              {renderMenuNodes(menuNodes)}
            </Menu>
          ) : null
        }
      </Layout.Sider>
      <Layout className={styles.content} >
        <Breadcrumb className={styles.breadcrumb}>
          {
            menuStack.map(id => (
              <Breadcrumb.Item key={id} >
                {menuTitleDict[id]}
              </Breadcrumb.Item>
            ))
          }
        </Breadcrumb>
        {children}
      </Layout>
    </Layout>
  </Layout>
}