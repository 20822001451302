import React, { FC, useMemo, useRef, useState } from 'react';
import { Report, ReportQuery } from '@/interface';
import { getPostReport } from '@/service';
import { reportColumns, reportFilters } from './config';
import { Tabs } from 'antd';
import { t } from '@/lang';
import { CrudList, CrudListColumnConfig, CrudListMethods, CrudListRowOperation } from '@/component/crud-list';
import { AUDIT_STATUSES, AuditStatus } from '@/enum';
import { ReportAuditModal } from './report-audit-modal';
import { useModal } from '@@/react-hooks';
import styles from './report-audit.module.less';

const VIEW_PARAMS_OPERATION = 'VIEW';
const PASS_AUDIT_OPERATION = 'PASS';
const REJECT_AUDIT_OPERATION = 'REJECT';

export const ReportAudit: FC = () => {
  const [modalState, openModal, closeModal] = useModal<{
    operation: string;
    item: Report
  }>(null);

  const crudListMethodsRef = useRef<CrudListMethods>();
  const [activeAuditStatus, setActiveAuditStatus] = useState(AuditStatus.Wait);

  const columns: CrudListColumnConfig<Report>[] = useMemo(() => [
    ...reportColumns,
    ...(
      activeAuditStatus !== AuditStatus.Wait ? [
        {
          title: t('table.auditBy'),
          width: 80,
          dataIndex: 'auditBy',
        },
        {
          title: t('table.auditComment'),
          width: 240,
          dataIndex: 'auditComment',
        }
      ] as const : []
    ),
    {
      title: t('table.reporterName'),
      width: 80,
      dataIndex: 'reporterName',
    },
    {
      title: t('table.createTime'),
      width: 100,
      dataIndex: 'createTime',
      display: 'time',
    },
    {
      title: t('table.updateTime'),
      width: 100,
      dataIndex: 'updateTime',
      display: 'time',
    }
  ], [activeAuditStatus]);

  const operations: CrudListRowOperation[] = useMemo(() => {
    const commonOperations = [
      {
        operation: VIEW_PARAMS_OPERATION,
        name: t('button.linkPostUrl'),
      }
    ];

    return activeAuditStatus === AuditStatus.Wait ? [
      {
        operation: 'PASS',
        name: t('button.pass'),
      },
      {
        operation: 'REJECT',
        name: t('button.reject'),
      },
      ...commonOperations
    ] : commonOperations;
  }, [activeAuditStatus]);

  return (
    <>
      <Tabs
        className={styles.tabs}
        activeKey={String(activeAuditStatus)}
        onChange={raw => setActiveAuditStatus(Number(raw))}
      >
        {
          AUDIT_STATUSES.map(
            auditStatus => (
              <Tabs.TabPane
                key={auditStatus}
                tab={t(`value.auditStatus.${auditStatus}`)}
              />
            )
          )
        }
      </Tabs>
      <CrudList<Report, null, ReportQuery>
        ref={crudListMethodsRef}
        key={activeAuditStatus}
        title="report 审核"
        rowKey="id"
        filters={reportFilters}
        columns={columns}
        read={query => getPostReport({
          ...query,
          auditStatus: activeAuditStatus
        })}
        operations={operations}
        onOperate={(operation, item) => {
          switch (operation) {
            case VIEW_PARAMS_OPERATION:
              window.open(item.postUrl, '_blank')
              break
            case PASS_AUDIT_OPERATION:
            case REJECT_AUDIT_OPERATION:
              openModal({
                operation,
                item
              });
              break;
            default:
              break
          }
        }}
        tableScroll={{
          x: activeAuditStatus === AuditStatus.Wait ? 1400 : 1720
        }}
      />
      <ReportAuditModal
        visible={modalState.visible}
        data={modalState.data?.item}
        pass={modalState.data?.operation === PASS_AUDIT_OPERATION}
        onClose={closeModal}
        reportType='post'
        onFinished={() => crudListMethodsRef.current.reload()}
      />
    </>
  )
}

export default ReportAudit;
