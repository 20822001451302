import { CrudListColumnConfig, CrudListFormConfig } from "@/component/crud-list";
import { Report, ReportQuery, ReportOffer, ReportOfferQuery } from "@/interface";
import { t } from "@/lang";


export const reportFilters: CrudListFormConfig<ReportQuery>[] = [
  {
    name: 'id',
    label: t('placeholder.reportId'),
    type: 'int',
  },
  {
    name: 'reportId',
    label: t('placeholder.postId'),
    type: 'int',
  },
  {
    name: 'startTime',
    label: t('placeholder.reportStartTime'),
    type: 'time',
  },
  {
    name: 'endTime',
    label: t('placeholder.reportEndTime'),
    type: 'time',
  },
]

export const reportOfferFilters: CrudListFormConfig<ReportOfferQuery>[] = [
  {
    name: 'id',
    label: t('placeholder.reportId'),
    type: 'int',
  },
  {
    name: 'reportId',
    label: 'offer ID',
    type: 'int',
  },
  {
    name: 'startTime',
    label: t('placeholder.reportStartTime'),
    type: 'time',
  },
  {
    name: 'endTime',
    label: t('placeholder.reportEndTime'),
    type: 'time',
  },
]

export const reportColumns: CrudListColumnConfig<Report>[] = [
  {
    title: t('table.reportID'),
    width: 85,
    dataIndex: 'id',
    fixed: 'left',
  },
  {
    title: t('table.postID'),
    width: 85,
    dataIndex: 'reportId',
  },
  {
    title: t('table.postStatus'),
    width: 100,
    dataIndex: 'postStatus',
    display: postStatus => t(`value.postStatus.${postStatus}`),
  },
  {
    title: t('table.postImg'),
    width: 120,
    dataIndex: 'postImgs',
    display: 'images',
  },
  {
    title: t('table.postTitle'),
    width: 100,
    dataIndex: 'title',
  },
  {
    title: t('table.postContent'),
    width: 240,
    dataIndex: 'content',
  },
  {
    title: t('table.reportDescription'),
    width: 240,
    dataIndex: 'description',
  },
];

export const ReportOfferColumns: CrudListColumnConfig<ReportOffer>[] = [
  {
    title: t('table.reportID'),
    width: 85,
    dataIndex: 'id',
    fixed: 'left',
  },
  {
    title: 'offer ID',
    width: 85,
    dataIndex: 'reportId',
  },
  {
    title: 'offer 状态',
    width: 100,
    dataIndex: 'offerStatus',
    display: offerStatus => t(`value.offerStatus.${offerStatus}`),
  },
  {
    title: t('table.postImg'),
    width: 120,
    dataIndex: 'imgUrl',
    display: 'image',
  },
  {
    title: '商品名',
    width: 100,
    dataIndex: 'offerName',
  },
  {
    title: t('table.reportDescription'),
    width: 240,
    dataIndex: 'description',
  },
];
