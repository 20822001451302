import React, { FC, ReactNode, useMemo } from 'react';
import { Button, Drawer, message } from 'antd';
import { useFlow } from '@@/react-hooks';
import { CrudListProps } from './interface';
import { ActionConfig } from '@/interface';
import { t } from '@/lang';
import styles from './crud-list.module.less';
import { CreateUpdateForm } from './create-update-form';
import { FormModalFooter } from '../form-modal-footer';
import { Modal } from 'antd';

const FORM_ID = 'crud-form';

export interface CreateUpdateModalContainerProps {
  isModal: boolean;
  visible: boolean;
  title: ReactNode;
  footer: ReactNode;
  onClose(): void;
  children: ReactNode;
}

const DrawerContainer: FC<CreateUpdateModalContainerProps> = ({
  isModal,
  visible,
  title,
  footer,
  onClose,
  children,
}) => {
  return isModal ? (
    <Modal
      visible={visible}
      onCancel={onClose}
      title={title}
      destroyOnClose
      footer={footer}
      width="1000px"
    >{
        children
      }</Modal>
  ) : (
    <Drawer
      visible={visible}
      onClose={onClose}
      title={title}
      footer={footer}
      width="600px"
      footerStyle={{
        textAlign: 'right',
      }}
    >{
        children
      }</Drawer>
  )
}

export interface CreateUpdateModalProps<T extends object, C extends object>
  extends Required<Pick<CrudListProps<T, C, any>, 'isModal' | 'title' | 'auditable' | 'fields' | 'update' | 'create' | 'adaptCreation'>> {
  visible: boolean;
  data: T;
  onReload(): void;
  onClose(): void;
}

export function CreateUpdateModal<T extends object, C extends object>({
  isModal,
  visible,
  data,
  title,
  update,
  create,
  fields,
  adaptCreation,
  onReload,
  onClose,
}: CreateUpdateModalProps<T, C>) {
  const [{ loading }, dispatch] = useFlow<void, C & ActionConfig>(null, function* ({ call, block }, creation) {
    yield block();
    try {
      if (data) {
        yield call(update, data, creation);
      } else {
        yield call(create, creation);
      }
      onReload();
      onClose();
    }
    catch (error) {
      console.log(error)
      message.error(error.msg);
    }
  });

  const initialValues = useMemo(() => adaptCreation(data) as any, [data]);

  return (
    <DrawerContainer
      isModal={isModal}
      visible={visible}
      title={
        <>
          {data ? '编辑' : '新建'}{title}
        </>
      }
      onClose={onClose}
      footer={<FormModalFooter form={FORM_ID} onCancel={onClose} loading={loading} />}
    >
      {
        visible ?
          <CreateUpdateForm
            initialValues={initialValues}
            id={FORM_ID}
            fields={fields}
            onFinish={fields => dispatch(fields)}
            labelAlign="left"
            labelCol={{
              span: isModal ? 3 : 6
            }}
          /> : null
      }
    </DrawerContainer>
  )
}
