import React, { FC } from 'react';
import { Alert, Button, Carousel, Col, Empty, Form, Image, InputNumber, message, Modal, Row, Spin } from 'antd';
import { Product } from '@/interface';
import { t } from '@/lang';
import { useFlowEffect, CallNext, useFlow } from '@@/react-hooks';
import { ScheduleUtils } from '@@/utils';
import { CombineProduct, combineProduct, getProduct } from '@/service';
import styles from './product-combine.module.less';
import { useForm } from 'antd/lib/form/Form';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { OmApiResponseCode } from "@/enum";

const awaitCombineConfirm = ({ code }) => new Promise<boolean>((resolve, reject) => {
  let comfirm_msg = '商品非同一个品牌，是否强制合并?'
  if (code == OmApiResponseCode.UserInteractionsErrorCode) {
    comfirm_msg = '商品存在用户行为，是否强制合并?'
  }
  Modal.confirm({
    title: comfirm_msg,
    icon: <ExclamationCircleFilled />,
    content: '',
    onOk: () => resolve(true),
    onCancel: () => resolve(false),
  });
});

const ProductDisplay: FC<{
  spuId: number;
}> = ({ spuId }) => {
  const { data, loading, error } = useFlowEffect<Product>(
    null,
    function* ({ cancel, call, put }) {
      yield cancel();
      if (!spuId) {
        yield put(null);
        return;
      }
      yield call(ScheduleUtils.timeout, 300);
      const { data }: CallNext<typeof getProduct> = yield call(getProduct, { spuId });
      yield put(data);
    },
    [spuId],
  );

  if (!spuId) return null;

  if (loading) {
    return <Spin />;
  }

  if (error) {
    return <Alert type="error" message={error?.msg || '未知错误'} />
  }

  if (!data) return <Empty >没有找到该商品</Empty>

  return (
    <div className={styles.product_display}>
      <div className={styles.category}>{data.gender} / {data.category1} / {data.category2}</div>
      <div className={styles.brand}>{data.brand}</div>
      <div className={styles.name}>{data.name}</div>
      <Carousel >
        {
          data.images.map(src => (
            <Image className={styles.image} preview={false} src={src} key={src} />
          ))
        }
      </Carousel>
    </div>
  )
}

const ProductCombine: FC = () => {
  const [form] = useForm();

  const [{ loading }, dispatch] = useFlow(null, function* ({ call, block }, query: {
    spuId0: number;
    spuId1: number;
    forceCombine: boolean;
    forceCombineBrand: boolean;
  }) {
    yield block();
    const force_combine = async (query) => {
      try {
        const { data: { toKeep, toDelete, prioritySpuId } }: CallNext<CombineProduct> = await combineProduct({
          ...query,
        });
        Modal.success({
          title: '合并成功',
          content: <div>
            <div>删除商品{toDelete}，保留商品{toKeep}，保留商品如下：</div>
            <ProductDisplay spuId={prioritySpuId} />
          </div>
        });
        form.resetFields();
      } catch (e) {
        if (e && (e.code == OmApiResponseCode.UserInteractionsErrorCode || e.code == OmApiResponseCode.CombineSpuBrandErrorCode)) {
          const result = await awaitCombineConfirm({ code: e.code })
          if (result) {
            if (e.code == OmApiResponseCode.UserInteractionsErrorCode) {
              query.forceCombine = true
              query.forceCombineBrand = true
            } else {
              query.forceCombine = false
              query.forceCombineBrand = true
            }
            await force_combine(query)
          }
        } else {
          Modal.error({
            title: '合并失败',
            content: e?.msg || '未知错误'
          });
        }
      }
    }
    yield call(force_combine, query)
  })

  return (
    <Form form={form} className={styles.form} onFinish={dispatch} >
      <Row >
        <Col span={10}>
          <Form.Item initialValue={""} required rules={[{
            required: true,
            message: '请输入第一个要合并的商品ID'
          }]} name="spuId0" label={t('form.spuId1')} >
            <InputNumber className={styles.input} controls={false} placeholder={t('form.spuId1')} />
          </Form.Item>
        </Col>
        <Col span={10}>
          <Form.Item initialValue={""} required rules={[{
            required: true,
            message: '请输入第二个要合并的商品ID'
          }]} name="spuId1" label={t('form.spuId2')} >
            <InputNumber className={styles.input} controls={false} placeholder={t('form.spuId2')} />
          </Form.Item>
        </Col>
        <Col span={4} >
          <Button htmlType="submit" loading={loading} type="primary" size="large" >合并</Button>
        </Col>
      </Row>
      <Row >
        <Col span={8}>
          <Form.Item noStyle dependencies={['spuId0']}>
            {
              (form) => {
                return <ProductDisplay spuId={form.getFieldValue('spuId0')} />
              }
            }
          </Form.Item>
        </Col>
        <Col span={2} />
        <Col span={8}>
          <Form.Item noStyle dependencies={['spuId1']}>
            {
              (form) => {
                return <ProductDisplay spuId={form.getFieldValue('spuId1')} />
              }
            }
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}

export default ProductCombine;
