import { ObjectUtils } from '@@/utils';
import {
  cache,
  jsonBody,
  mapRes,
  method,
  onResolve,
} from '@@/http';
import * as t from './interface';
import { defaultGetHeadersKey, adaptCase, auth, urlMiddleware as url, createRequest as f, adaptPagi, categoriesMws, mapResolvedData, matchKeyword, uploadImages, enMoment, deMoment, webUserSourceMws, uploadCampaignImages, categoriesListMws, webInternalUserSourceMws, urlTaskMiddleware as urlTask, webUserSourceListMws } from './mw';
import { authorizationCacher } from '@/util/cacher';
import { ApiResponseCode, Gender, ReportType, UserStatus, OrderStatus } from '@/enum';
import { Option } from '@/interface';
import { deepMapSnakeToCamel } from '@/util/format';
import moment from 'moment';

export const getOffers: t.GetOffers = f(
  auth(),
  enMoment({
    'list.*.offerTime': 'iso',
    'list.*.offerAcceptTime': 'iso',
  }),
  adaptPagi('offerList'),
  url('/offer', params => ({
    ...params,
    group: params.userId || params.offerId ? false : true,
  })),
);

export const postOffer: t.PostOffer = f(
  auth(),
  method('post'),
  url('/offer'),
  uploadImages(["images", "*"]),
  jsonBody(({ ...rest }) => ({
    ...rest,
    skipAudit: true,
    forceOnline: true,
    sync_spu: true,
  })),
);

export const postOffers: t.PostOffers = f(
  auth(),
  method('post'),
  url('/offer/official'),
  jsonBody(),
)

export const getOffer: t.GetOffer = f(
  auth(),
  url(({ offerId }) => `/offer/${offerId}`),
)

export const putOffer: t.PutOffer = f(
  auth(),
  method('put'),
  url(({ offerId }) => `/offer/${offerId}`),
  uploadImages(["data", "images", "*"]),
  jsonBody(({ data: { ...rest } }) => ({
    ...rest,
    skipAudit: true,
    forceOnline: true,
    sync_spu: true,
    updateImages: true,
  })),
)

export const deleteOffer: t.DeleteOffer = f(
  auth(),
  method('delete'),
  url(({ offerId }) => `/offer/${offerId}`),
  jsonBody(({ ...rest }) => ({
    ...rest,
    skipAudit: true,
    forceOnline: true,
  })),
)

export const launchOffer: t.LaunchOffer = f(
  auth(),
  method('post'),
  url('/offer/launch'),
  jsonBody(),
)

export const withdrawOffer: t.WithdrawOffer = f(
  auth(),
  method('post'),
  url('/offer/withdraw'),
  jsonBody(),
)

export const getOfferActions: t.GetOfferActions = f(
  auth(),
  adaptPagi('actionList'),
  adaptCase(),
  url('/offer-action', true),
)

export const auditOfferAction: t.AuditOfferAction = f(
  auth(),
  method('post'),
  url(({ actionId }) => `/offer-action/${actionId}/audit`),
  adaptCase(),
  jsonBody(({ action_status }) => ({ action_status })),
)

export const getWebsiteOffers: t.GetWebsiteOffers = f(
  adaptPagi('offerList'),
  enMoment({
    'list.*.offerTime': 'iso',
    'list.*.offerAcceptTime': 'iso',
  }),
  url('/web/offer', params => ({
    ...params,
    group: params.userId || params.offerId ? false : true,
  })),
)

export const getWebsiteOffer: t.GetWebsiteOffer = f(
  url(({ offerId }) => `/web/offer/${offerId}`),
)

export const getProducts: t.GetProducts = f(
  auth(),
  enMoment({
    'list.*.publishTime': 'iso',
    'list.*.launchTime': 'iso',
    'list.*.withdrawTime': 'iso',
  }),
  adaptPagi('spuList'),
  url('/product', params => ({
    ...params,
  })),
)

export const postProduct: t.PostProduct = f(
  auth(),
  method('post'),
  url('/product'),
  uploadImages(["images", "*"]),
  deMoment({
    publishTime: 'iso',
  }),
  jsonBody(({ ...rest }) => ({
    ...rest,
    skipAudit: true,
    forceOnline: true,
  })),
)

export const getProduct: t.GetProduct = f(
  auth(),
  cache({
    forceUpdateWhen: ({ params }) => params.forceUpdate,
  }),
  mapResolvedData(data => ObjectUtils.immutableSet(data, ['sizes'], (sizes: string) => sizes.split(','))),
  url(({ spuId }) => `/product/${spuId}`),
)

export const putProduct: t.PutProduct = f(
  auth(),
  method('put'),
  url(({ spuId }) => `/product/${spuId}`),
  uploadImages(["data", "images", "*"]),
  deMoment({
    'data.publishTime': 'iso',
  }),
  jsonBody(({ data: { images, brand, collabDesigner, ...rest } }) => {
    let newBrands = []
    let updateBrand
    if (collabDesigner) {
      collabDesigner.forEach((item) => {
        if (brand != item) {
          newBrands.push(item)
        }
      })
      updateBrand = [brand].concat(newBrands)
    } else {
      updateBrand = brand
    }
    return ({
      images: images,
      ...rest,
      brand: updateBrand,
      skipAudit: true,
      forceOnline: true,
      updateImages: true,
    })
  }),
)

export const deleteProduct: t.DeleteProduct = f(
  auth(),
  method('delete'),
  url(({ spuId }) => `/product/${spuId}`),
  jsonBody(({ ...rest }) => ({
    ...rest,
    skipAudit: true,
    forceOnline: true,
  })),
);

export const launchProduct: t.LaunchProduct = f(
  auth(),
  method('post'),
  url('/product/launch'),
  jsonBody(),
)

export const withdrawProduct: t.WithdrawProduct = f(
  auth(),
  method('post'),
  url('/product/withdraw'),
  jsonBody(),
)

export const combineProduct: t.CombineProduct = f(
  auth(),
  method('post'),
  url('/product/combine'),
  jsonBody(),
)

export const getProductActions: t.GetProductActions = f(
  auth(),
  adaptPagi('actionList'),
  adaptCase(),
  url('/product-action', true),
)
export const auditProductAction: t.AuditProductAction = f(
  auth(),
  method('post'),
  url(({ actionId }) => `/product-action/${actionId}/audit`),
  adaptCase(),
  jsonBody(({ action_status }) => ({ action_status })),
)

export const getWebsiteProducts: t.GetWebsiteProducts = f(
  adaptPagi('spuList'),
  url('/web/product', params => ({
    ...params,
  })),
)

export const getWebsiteProduct: t.GetWebsiteProduct = f(
  url(({ spuId }) => `/web/product/${spuId}`),
)

export const getCoupons: t.GetCoupons = f(
  auth(),
  enMoment({
    'list.*.startTime': 'iso',
    'list.*.expireTime': 'iso'
  }),
  adaptPagi('coupons'),
  url('/web/coupon', true),
)

export const postCoupon: t.PostCoupon = f(
  auth(),
  method('post'),
  url('/web/coupon'),
  enMoment({
    startTime: 'iso',
    expireTime: 'iso'
  }),
  jsonBody(({ ...rest }) => ({
    ...rest,
    startTime: moment(rest.startTime).utc(true),
    expireTime: moment(rest.expireTime).utc(true)
  })),
)

export const putCoupon: t.PutCoupon = f(
  auth(),
  method('put'),
  url(({ couponId }) => `/web/coupon/${couponId}`),
  enMoment({
    'data.startTime': 'iso',
    'data.expireTime': 'iso'
  }),
  jsonBody(({ data }) => ({
    ...data,
    startTime: moment(data.startTime).utc(true),
    expireTime: moment(data.expireTime).utc(true),
  })),
)

export const getWebUser: t.GetWebUser = f(
  auth(),
  adaptPagi('users'),
  url('/web/user', params => ({
    ...params,
    email: params.email ? params.email : undefined,
    username: params.username ? params.username : undefined,
    fuzzyQuery: true
  })),
)

export const putWebUserStatus: t.PutWebUserStatus = f(
  auth(),
  method('put'),
  url(({ userId }) => `/web/user/${userId}`),
  jsonBody(),
)

export const getWebUserInfo: t.GetWebUserInfo = f(
  auth(),
  url(({ userId }) => `/web/user/${userId}`),
)

export const deleteCoupon: t.DeleteCoupon = f(
  auth(),
  method('delete'),
  url(({ couponId }) => `/web/coupon/${couponId}`),
)

export const login: t.Login = f(
  onResolve(({ data: { token } }) => {
    authorizationCacher.set(token);
  }),
  method('post'),
  url('/sys-user/login'),
  jsonBody(),
)

export const logout: t.Logout = f(
  onResolve(() => {
    authorizationCacher.clear();
  }),
  url('/sys-user/logout'),
)

export const putAccount: t.PutAccount = f(
  auth(),
  method('put'),
  url('/sys-user'),
  uploadImages(['data', 'avatar']),
  jsonBody(),
)

export const postAccount: t.PostAccount = f(
  auth(),
  method('post'),
  url('/sys-user'),
  uploadImages(['avatar']),
  jsonBody(),
)

export const getAccountInfo: t.GetAccountInfo = f(
  auth(false),
  adaptCase(),
  mapRes(res => {
    if ('resolve' in res && ObjectUtils.isEmpty(res.resolve.data)) {
      return {
        reject: {
          code: ApiResponseCode.Unauthorized
        },
        ...ObjectUtils.omit(res, ['resolve'])
      }
    }
    return res;
  }),
  url('/sys-user/info'),
)

export const postAccountPassword: t.PostAccountPassword = f(
  method('post'),
  url('/sys-user/password'),
  jsonBody(),
)

export const getAccounts: t.GetAccounts = f(
  mapResolvedData(data => ObjectUtils.immutableSet(data, ['list', '*'], deepMapSnakeToCamel)),
  adaptPagi('users'),
  url('/sys-user/all', true),
)

export const getCategory1Options: t.GetCategory1Options = f(
  matchKeyword(),
  mapResolvedData((dict, { params: { gender } }) => dict[gender]),
  ...categoriesMws,
)

export const getCategory2Options: t.GetCategory2Options = f(
  matchKeyword(),
  mapResolvedData((dict, { params: { gender, category1 } }) => {
    return dict[gender].find(({ value }) => value === category1)?.children || [];
  }),
  ...categoriesMws,
)

export const getCurrencies: t.GetCurrencies = f(
  matchKeyword(),
  cache({
    getKey: defaultGetHeadersKey,
  }),
  url('/web/property/currency'),
  mapResolvedData((list) => list.map(({ currency, symbol }) => ({ value: currency, label: `${currency} (${symbol})` }))),
)

export const getRegions: t.GetRegions = f(
  matchKeyword(),
  cache({
    getKey: defaultGetHeadersKey,
  }),
  url('/web/property/region'),
  mapResolvedData(({ regions }) => regions.map(value => ({
    value,
    label: value,
  }))),
)

export const getSizes: t.GetSizes = f(
  cache(),
  url('/web/property/size/option', true),
  mapResolvedData(({ sizeOptions, order }) => {
    return order.map((sizeUnit: string) => ({
      value: sizeUnit,
      label: sizeUnit,
      children: sizeOptions[sizeUnit].map((size: string) => ({
        value: size,
        label: size,
      }))
    }));
  })
)

export const getAllDesigners: t.GetAllDesigners = f(
  matchKeyword(),
  mapResolvedData((dict, { params: { gender } }) => dict[gender]),
  cache({
    getKey: defaultGetHeadersKey,
  }),
  url('/web/property/designer'),
  mapResolvedData(({ designers }) => {
    const dict: Record<Gender, Option[]> = {
      [Gender.Men]: [],
      [Gender.Women]: [],
    };
    for (const { gender, designer } of designers) {
      dict[gender].push({
        value: designer,
        label: designer,
      });
    }
    return dict;
  }),
)

export const getAllWebUsers: t.GetUsers = f(
  matchKeyword(),
  ...webUserSourceMws,
)

export const getAllWebUsersList: t.GetUsers = f(
  matchKeyword(),
  ...webUserSourceListMws,
)

export const getOfficialWebUsers: t.GetUsers = f(
  matchKeyword(),
  mapResolvedData(
    (list) => list.filter(({ userStatus }) => userStatus === UserStatus.Official)
  ),
  ...webUserSourceMws,
)

export const getUsernameDict: t.GetUsernameDict = f(
  mapResolvedData(
    (list) => Object.fromEntries(
      list.map(({ value, label }) => [value, label])
    )
  ),
  ...webUserSourceMws,
);

export const getPostReport: t.GetPostReport = f(
  enMoment({
    'list.*.createTime': 'iso',
    'list.*.updateTime': 'iso',
  }),
  adaptPagi('reports'),
  url('/web/report', params => ({
    ...params,
    reportType: ReportType.Post,
    startTime: params.startTime ? params.startTime.utc(true)._d : '2000-01-01T00:00:00.000Z',
    endTime: params.endTime ? params.endTime.utc(true)._d : '2200-01-01T00:00:00.000Z',
  })
  ),
)

export const getOfferReport: t.GetOfferReport = f(
  enMoment({
    'list.*.createTime': 'iso',
    'list.*.updateTime': 'iso',
  }),
  adaptPagi('reports'),
  url('/web/report/offer', params => ({
    ...params,
    reportType: ReportType.Offer,
    startTime: params.startTime ? params.startTime.utc(true)._d : '2000-01-01T00:00:00.000Z',
    endTime: params.endTime ? params.endTime.utc(true)._d : '2200-01-01T00:00:00.000Z',
  })
  ),
)

export const putReportAudit: t.PutReportAudit = f(
  auth(),
  method('put'),
  adaptCase(),
  url(({ id }) => `/web/report/${id}`),
  jsonBody(({ data }) => data),
)

export const getDesigner: t.GetDesigner = f(
  auth(),
  adaptPagi('designers'),
  url('/web/property/designer', true),
)

export const postDesigner: t.PostDesigner = f(
  auth(),
  method('post'),
  url('/web/property/designers'),
  uploadImages(['logo'], 'designer'),
  jsonBody(({ gender, styles, logo, ...rest }) => {
    let styles_dict = {}
    for (const info of gender) {
      styles_dict[info] = styles[info].join(',')
    }
    return ({
      ...rest,
      gender: gender,
      styles: styles_dict,
      logo: logo.split('?')[0],
    })
  }
  ),
)

export const putDesigner: t.PutDesigner = f(
  auth(),
  method('put'),
  url(({ designerId }) => `/web/property/designer/${designerId}`),
  uploadImages(['data', 'logo'], 'designer'),
  jsonBody(({ data: { gender, styles, logo, ...rest } }) => {
    let styles_dict = {}
    const gender_data = typeof (gender) == 'string' ? [gender] : gender
    for (const info of gender_data) {
      styles_dict[info] = styles[info].join(',')
    }
    return ({
      gender: gender_data,
      styles: styles_dict,
      logo: logo.split('?')[0],
      ...rest,
    })
  }),
)

export const deleteDesigner: t.DeleteDesigner = f(
  auth(),
  method('delete'),
  url(({ designerId }) => `/web/property/designer/${designerId}`),
)

export const getDesignerSize: t.GetDesignerSize = f(
  auth(),
  adaptPagi('designerSizes'),
  url('/web/property/designer-size', true),
)

export const batchPostDesignerSizes: t.BatchPostDesignerSizes = f(
  auth(),
  method('post'),
  url('/web/property/designer-size'),
  jsonBody(),
)

export const batchPutDesignerSize: t.BatchPutDesignerSize = f(
  auth(),
  method('put'),
  url('/web/property/designer-size'),
  jsonBody(),
)

export const deleteDesignerSize: t.DeleteDesignerSize = f(
  auth(),
  method('delete'),
  url(({ designerSizeId }) => `/web/property/designer-size/${designerSizeId}`),
)

export const getSizeUnits: t.GetSizeUnits = f(
  auth(),
  url('/web/property/size/all', true),
)

export const getSpuLock: t.GetSpuLock = f(
  auth(),
  url(({ spuId }) => `/product/${spuId}/lock`, true),
)

export const postSpuLock: t.PostSpuLock = f(
  auth(),
  method('post'),
  url(({ spuId }) => `/product/${spuId}/lock`),
  jsonBody(({ ...rest }) => ({
    ...rest,
  })),
)

export const deleteSpuLock: t.DeleteSpuLock = f(
  auth(),
  method('delete'),
  url(({ spuId }) => `/product/${spuId}/lock`),
  jsonBody(({ ...rest }) => ({
    ...rest,
  })),
);


export const getBlogs: t.GetBlogs = f(
  auth(),
  adaptPagi('blogList'),
  url('/web/blog', true),
)

export const getBlogsDetails: t.GetBlogsDetails = f(
  auth(),
  url(({ blogId }) => `/web/blog/${blogId}`, true),
)

export const postBlog: t.PostBlog = f(
  auth(),
  method('post'),
  url('/web/blog'),
  uploadImages(['thumbnail']),
  jsonBody(({ meta, ...rest }) => ({
    ...rest,
    meta: { 'title': meta.title, 'description': meta.description, 'keywords': meta.keywords ? meta.keywords.join(',') : meta.keywords }
  })),
)

export const putBlog: t.PutBlog = f(
  auth(),
  method('put'),
  url(({ blogId }) => `/web/blog/${blogId}`),
  uploadImages(['data', 'thumbnail']),
  jsonBody(({ data }) => {
    const keywords_data = typeof (data.meta.keywords) == 'string' ? data.meta.keywords : data.meta.keywords ? data.meta.keywords.join(',') : data.meta.keywords
    return ({
      ...data,
      meta: { 'title': data.meta.title, 'description': data.meta.description, 'keywords': keywords_data }
    })
  }
  )
)

export const deleteBlog: t.DeleteBlog = f(
  auth(),
  method('delete'),
  url(({ blogId }) => `/web/blog/${blogId}`),
)

export const uploadImage: t.UploadImage = f(
  auth(),
  uploadImages(['file']),
  ({ params }) => ({
    headers: {},
    status: 200,
    statusText: '',
    resolve: params.file
  }),
);

export const getCopy: t.GetCopy = f(
  auth(),
  adaptPagi('copyList'),
  url('/web/copy', ({ key, ...rest }) => key ? { key, ...rest } : rest),
)

export const postCopy: t.PostCopy = f(
  auth(),
  method('post'),
  url('/web/copy'),
  jsonBody(({ keywords, gender, category1, category2, brand, keyword, ...rest }) => ({
    ...rest,
    keywords: keywords ? keywords.join(',') : keywords,
    search: { 'gender': gender, 'category1': category1, 'category2': category2, 'brand': brand, 'keyword': keyword }
  })
  ),
)

export const putCopy: t.PutCopy = f(
  auth(),
  method('put'),
  url(({ copyId }) => `/web/copy/${copyId}`),
  jsonBody(({ data: { keywords, gender, category1, category2, brand, keyword, ...data } }) => {
    return ({
      ...data,
      keywords: typeof (keywords) == 'string' ? keywords : keywords ? keywords.join(',') : keywords,
      search: { 'gender': gender, 'category1': category1, 'category2': category2, 'brand': brand, 'keyword': keyword }
    })
  }),
)

export const deleteCopy: t.DeleteCopy = f(
  auth(),
  method('delete'),
  url(({ copyId }) => `/web/copy/${copyId}`),
)


export const getCampaign: t.GetCampaign = f(
  auth(),
  enMoment({
    'list.*.startTime': 'iso',
    'list.*.endTime': 'iso'
  }),
  adaptPagi('campaignList'),
  url('/web/campaign', true),
)

export const postCampaign: t.PostCampaign = f(
  auth(),
  method('post'),
  url('/web/campaign'),
  enMoment({
    startTime: 'iso',
    endTime: 'iso'
  }),
  uploadCampaignImages(['*']),
  jsonBody(({ ...rest }) => {
    const info = Array.isArray(rest.additionalInfo) === Array.isArray([]) ? true : false
    let additional_info = {}
    if (info) {
      rest.additionalInfo.forEach(element => {
        if (element['key'] && element['value']) {
          additional_info[element['key']] = element['value']
        }
      })
    } else {
      additional_info = rest.additionalInfo
    }
    return ({
      ...rest,
      additionalInfo: JSON.stringify(additional_info),
      startTime: moment(rest.startTime).utc(true),
      endTime: moment(rest.endTime).utc(true)
    })
  }),
)

export const putCampaign: t.PutCampaign = f(
  auth(),
  method('put'),
  enMoment({
    'data.startTime': 'iso',
    'data.endTime': 'iso'
  }),
  uploadCampaignImages(['data', '*']),
  url(({ campaignId }) => `/web/campaign/${campaignId}`),
  jsonBody(({ data: { ...data } }) => {
    const info = Array.isArray(data.additionalInfo) === Array.isArray([]) ? true : false
    let additional_info = {}
    if (info) {
      data.additionalInfo.forEach(element => {
        if (element['key'] && element['value']) {
          additional_info[element['key']] = element['value']
        }
      })
    } else {
      additional_info = data.additionalInfo
    }
    return ({
      ...data,
      additionalInfo: typeof (data.additionalInfo) == 'string' ? data.additionalInfo : JSON.stringify(additional_info),
      startTime: moment(data.startTime).utc(true),
      endTime: moment(data.endTime).utc(true),
    })
  }),
)

export const deleteCampaign: t.DeleteCampaign = f(
  auth(),
  method('delete'),
  url(({ campaignId }) => `/web/campaign/${campaignId}`),
)

export const getTopics: t.GetRegions = f(
  matchKeyword(),
  cache({
    getKey: defaultGetHeadersKey,
  }),
  url('/web/topic/all'),
  mapResolvedData(({ topics }) => {
    return topics.map(value => ({
      value: value['id'],
      label: value['name'],
    }))
  }),
)

export const getTopic: t.GetTopic = f(
  auth(),
  adaptPagi('topicList'),
  url('/web/topic', true),
)

export const postTopic: t.PostTopic = f(
  auth(),
  method('post'),
  url('/web/topic'),
  jsonBody(),
)

export const putTopic: t.PutTopic = f(
  auth(),
  method('put'),
  url(({ topicId }) => `/web/topic/${topicId}`),
  jsonBody(({ data }) => ({
    ...data,
  })),
)

export const deleteTopic: t.DeleteTopic = f(
  auth(),
  method('delete'),
  url(({ topicId }) => `/web/topic/${topicId}`),
)

export const getProductMapping: t.GetProductMapping = f(
  auth(),
  adaptPagi('mappingList'),
  url('/crawler/product/mapping', true),
)

export const postEmail: t.PostEmail = f(
  auth(),
  method('post'),
  url('/web/user/email'),
  jsonBody(({ ...rest }) => {
    const info = Array.isArray(rest.templateData) === Array.isArray([]) ? true : false
    let template_data = {}
    if (info) {
      rest.templateData.forEach(element => {
        if (element['key'] && element['value']) {
          template_data[element['key']] = element['value']
        }
      })
    } else {
      template_data = rest.template_data
    }
    return ({
      ...rest,
      templateData: '{}'
      // templateData: JSON.stringify(template_data),
    })
  }),
)

export const getEmailTemplate: t.GetEmailTemplate = f(
  matchKeyword(),
  cache({
    getKey: defaultGetHeadersKey,
  }),
  url('/web/email/email_template'),
  mapResolvedData((list) => {
    let dataList = []
    const data = list.map((item) => ({ value: item.split('.ejs')[0], label: item.split('.ejs')[0], disabled: false }))
    const tempUse = ['ootd-rickowens23ss', 'jawnflip-welcome', 'growth', 'ai-stylist', 'ootd-balenciaga23ss']
    for (const index in data) {
      dataList.push(data[index])
      if (!tempUse.includes(data[index]['value'])) {
        let d = data[index]
        d['disabled'] = true
        dataList.push(d)
      } else {
        dataList.push(data[index])
      }
    }
    return data
  }),
)

export const getOrders: t.GetOrders = f(
  auth(),
  adaptPagi('orderList'),
  url('/web/order', params => ({
    ...params,
    startTime: params.startTime ? params.startTime.utc(true)._d : '2000-01-01T00:00:00.000Z',
    endTime: params.endTime ? params.endTime.utc(true)._d : '2200-01-01T00:00:00.000Z',
  })),
)

export const executeOrder: t.ExecuteOrder = f(
  auth(),
  method('post'),
  url(({ orderId }) => `/web/order/execute/${orderId}`),
)

export const cancelOrder: t.CancelOrder = f(
  auth(),
  method('post'),
  url(({ orderId }) => `/web/order/cancel/${orderId}`),
)


export const postImport: t.PostImport = f(
  auth(),
  method('post'),
  urlTask(({ taskName }) => `/task/import/${taskName}`),
  jsonBody(),
)

export const getSourceList: t.GetSourceList = f(
  matchKeyword(),
  mapResolvedData((dict) => dict),
  cache({
    getKey: defaultGetHeadersKey,
  }),
  urlTask('/task/source'),
  mapResolvedData((data) => {
    const dict = []
    for (const source of data) {
      dict.push({
        value: source,
        label: source,
      });
    }
    return dict;
  }),
)

export const getAllDesignersList: t.GetAllDesignersList = f(
  matchKeyword(),
  mapResolvedData((dict) => dict),
  cache({
    getKey: defaultGetHeadersKey,
  }),
  url('/web/property/designer'),
  mapResolvedData(({ designers }) => {
    const dict = []
    const brands = []
    for (const { designer } of designers) {
      if (!!!brands.includes(designer)) {
        dict.push({
          value: designer,
          label: designer,
        });
      }
      brands.push(designer)
    }
    return dict;
  }),
)

export const getChatGpt: t.GetChatGpt = f(
  method('post'),
  url('/web/property/chatgpt'),
  jsonBody(),
)

export const getInsImg: t.GetInsImg = f(
  method('post'),
  url('/web/property/designer/ins_image'),
  jsonBody(),
)

export const getCategory1OptionsList: t.GetCategory1OptionsList = f(
  matchKeyword(),
  mapResolvedData((dict) => dict['category1']),
  ...categoriesListMws
)

export const getCategory2OptionsList: t.GetCategory2OptionsList = f(
  matchKeyword(),
  mapResolvedData((dict) => dict['category2']),
  ...categoriesListMws
)

export const getInernalUser: t.GetUsers = f(
  matchKeyword(),
  ...webInternalUserSourceMws,
)


export const getGoogleMerchant: t.GetGoogleMerchant = f(
  auth(),
  adaptPagi('googleMerchantConfig'),
  url('/google_merchant_config', true),
)

export const postGoogleMerchant: t.PostGoogleMerchant = f(
  auth(),
  method('post'),
  url('/google_merchant_config'),
  jsonBody(),
)

export const putGoogleMerchant: t.PutGoogleMerchant = f(
  auth(),
  method('put'),
  url(({ configId }) => `/google_merchant_config/${configId}`),
  jsonBody(({ data }) => ({
    ...data,
  })),
)

export const deleteGoogleMerchant: t.DeleteGoogleMerchant = f(
  auth(),
  method('delete'),
  url(({ configId }) => `/google_merchant_config/${configId}`),
)

export const getEmailDemo: t.GetEmailDemo = f(
  auth(),
  // cache({
  //   getKey: defaultGetHeadersKey,
  // }),
  url(({ emailName }) => `/web/email/demo/${emailName}`),
)

export const getEmailDemoEjs: t.GetEmailDemoEjs = f(
  auth(),
  // cache({
  //   getKey: defaultGetHeadersKey,
  // }),
  url(({ emailName }) => `/web/email/demo_ejs/${emailName}`),
)

export const getEmailParam: t.GetEmailParam = f(
  auth(),
  // cache({
  //   getKey: defaultGetHeadersKey,
  // }),
  url(({ emailName }) => `/web/email/param/${emailName}`),
)