import React, { FC, useRef } from 'react';
import { getBlogs, deleteBlog } from '@/service';
import { CrudList, CrudListMethods } from "@/component/crud-list";
import { blogColumns, blogFilters } from './config';
import { Blog, BlogQuery } from '@/interface';
import { useModal } from '@reversible/common/es/react-hooks';
import { BlogEditModal } from './component/blog-edit-modal';
import { Button } from 'antd';
import styles from './blog-list.module.less';
import { BlogStatus } from '@/enum';

const EDIT_OPERATION = 'edit';

const BlogList: FC = () => {
  const [editModalState, openModal, closeModal] = useModal<Blog>();

  const crudRef = useRef<CrudListMethods>();

  const onShouldUpdate = () => {
    crudRef.current.reload();
  };

  return (
    <>
      <CrudList<Blog, null, BlogQuery>
        ref={crudRef}
        title="blog"
        rowKey="id"
        auditable
        isRequests={true}
        filters={blogFilters}
        columns={blogColumns}
        read={getBlogs}
        del={({ id }) => deleteBlog({ blogId: id })}
        deletable={({ blogStatus }) => blogStatus !== BlogStatus.Deleted}
        tableScroll={{
          x: 1400
        }}
        operations={
          ({ blogStatus }) => blogStatus !== BlogStatus.Deleted ?
            [{
              operation: EDIT_OPERATION,
              order: -1,
              name: '编辑'
            }
            ] : []}
        onOperate={(operation, row) => {
          switch (operation) {
            case EDIT_OPERATION:
              openModal(row);
              break;
            default:
              break;
          }
        }}
      >
        <Button className={styles.create_btn} type="primary" onClick={() => openModal()} >
          新建 Blog
        </Button>
      </CrudList>
      <BlogEditModal
        visible={editModalState.visible}
        data={editModalState.data}
        onClose={closeModal}
        onSuccess={onShouldUpdate}
      />
    </>
  );
}

export default BlogList;

