import { Blog, BlogCreation } from '@/interface';
import { CallNext, useFlow, useFlowEffect } from '@reversible/common/es/react-hooks';
import { message, Modal } from 'antd';
import React, { FC } from 'react';
import * as service from '@/service';
import { GetBlogsDetails } from '@/service';
import { ObjectUtils } from '@reversible/common/es/utils';
import { Async } from '@/component/async';
import styles from './blog-edit-modal.module.less';
import { blogFormFields } from './config';
import { CreateUpdateForm } from '@/component/crud-list/create-update-form';
import { RichContent } from '@/interface/rich-content';
import { FormModalFooter } from '@/component/form-modal-footer';

const FORM_ID = 'blog-edit-form';

function mutableValidifyContent(content: RichContent) {
  for (const item of content) {
    if ('text' in item) continue;
    if (!item.children.length) {
      item.children = [{ text: '' }];
    } else {
      mutableValidifyContent(item.children as RichContent);
    }
  }
}

export interface BlogEditModalProps {
  visible: boolean;
  data: Blog;
  onSuccess(): void;
  onClose(): void;
}

export const BlogEditModal: FC<BlogEditModalProps> = ({
  visible,
  onClose,
  onSuccess,
  data,
}) => {
  const dataState = useFlowEffect<BlogCreation>(null, function* ({
    call,
    put
  }) {
    if (data) {
      yield put(null);

      const { data: details }: CallNext<GetBlogsDetails> = yield call(
        service.getBlogsDetails,
        { blogId: data.id }
      )

      mutableValidifyContent(details.content);

      yield put({
        ...ObjectUtils.pick(details, [
          'title',
          'thumbnail',
          'brief',
          'author',
          'key',
          'meta',
          'content',
          'relatedInfo',
        ]),
      });
    } else {
      yield put({
        title: '',
        thumbnail: '',
        brief: '',
        author: '',
        key: '',
        meta: {
          title: '',
          keywords: '',
          description: ''
        },
        content: [{
          type: 'p',
          children: [{ text: '' }]
        }],
        relatedInfo: {
          designer: [],
          category1: [],
          category2: [],
          spuId: [],
          keyword: [],
        }
      })
    }
  }, [data]);

  const [{ loading }, dispatch] = useFlow<void, BlogCreation>(null, function* ({
    call,
    block,
  }, action) {
    yield block();
    try {
      if (data) {
        yield call(service.putBlog, {
          blogId: data.id,
          data: action,
        });
      } else {
        yield call(service.postBlog, action);
      }
      onSuccess();
      onClose();
    }
    catch (error) {
      message.error(error.msg);
    }
  });

  return (
    <Modal
      title={data ? '编辑 Blog' : '新建 Blog'}
      centered width="1000px"
      visible={visible}
      onCancel={onClose}
      destroyOnClose
      footer={<FormModalFooter form={FORM_ID} onCancel={onClose} loading={loading} />}
    >
      <div className={styles.modal}>
        <Async source={dataState} >
          {
            data => (
              <CreateUpdateForm<BlogCreation>
                initialValues={data}
                fields={blogFormFields}
                id={FORM_ID}
                onFinish={dispatch}
                labelAlign="left"
                labelCol={{
                  span: 3
                }}
              />
            )
          }
        </Async>
      </div>
    </Modal>
  )
};
