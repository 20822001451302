import { CrudListColumnConfig, CrudListFormConfig } from "@/component/crud-list";
import { CouponExtended, CouponCreation, CouponQuery } from "@/interface";
import { t } from "@/lang";
import { getAllDesigners, getCategory1Options, getCategory2Options, getOfficialWebUsers, getRegions } from "@/service";
import { ObjectUtils } from "@@/utils";
import { GENDERS } from "@reversible/schema";

export const couponFilters: CrudListFormConfig<CouponQuery>[] = [
  {
    name: 'userId',
    label: t('form.user'),
    type: 'select',
    options: (keyword) => getOfficialWebUsers({ keyword }),
  }
];

export const couponColumns: CrudListColumnConfig<CouponExtended>[] = [
  {
    title: t('table.couponId'),
    dataIndex: 'id'
  },
  {
    title: t('form.user'),
    dataIndex: 'username',
  },
  {
    title: t('table.coupon'),
    dataIndex: 'coupon',
  },
  {
    title: t('table.startTime'),
    dataIndex: 'startTime',
    display: 'time',
  },
  {
    title: t('table.expireTime'),
    dataIndex: 'expireTime',
    display: 'time',
  },
  {
    title: t('table.brand'),
    dataIndex: 'brand',
  },
  {
    title: t('table.category1'),
    dataIndex: 'category1',
  },
  {
    title: t('table.category2'),
    dataIndex: 'category2',
  },
  {
    title: t('table.description'),
    dataIndex: 'description',
  },
  {
    title: t('table.details'),
    dataIndex: 'details',
  },
]

export const adaptCouponCreation = (data?: CouponExtended): CouponCreation => data ? ObjectUtils.pick(
  data,
  [
    'userId',
    'coupon',
    'startTime',
    'expireTime',
    'region',
    'gender',
    'brand',
    'category1',
    'category2',
    'description',
    'details',
  ]
) : {
  userId: undefined,
  coupon: '',
  startTime: undefined,
  expireTime: undefined,
  region: '',
  gender: undefined,
  brand: undefined,
  category1: undefined,
  category2: undefined,
  description: '',
  details: '',
}

export const couponFormFields: CrudListFormConfig<CouponCreation>[] = [
  {
    name: 'userId',
    label: t('form.user'),
    type: 'select',
    options: (keyword) => getOfficialWebUsers({ keyword }),
  },
  {
    name: 'coupon',
    label: t('form.coupon'),
    type: 'input',
    optional: true,
  },
  {
    name: 'startTime',
    label: t('form.startTime'),
    type: 'time',
  },
  {
    name: 'expireTime',
    label: t('form.expireTime'),
    type: 'time',
  },
  {
    name: 'region',
    label: t('form.region'),
    type: 'select',
    options: (keyword) => getRegions({ keyword }),
    optional: true,
  },
  {
    name: 'gender',
    label: t('form.gender'),
    type: 'select',
    options: GENDERS.map(value => ({
      value,
      label: t(`value.gender.${value}`),
    })),
    optional: true,
  },
  {
    name: 'brand',
    label: t('form.brand'),
    type: 'select',
    deps: ['gender'],
    options: (keyword, { gender }) => gender ? getAllDesigners({ keyword, gender }) : [],
    optional: true,
  },
  {
    name: 'category1',
    label: t('form.category1'),
    type: 'select',
    deps: ['gender'],
    options: (keyword, { gender }) => gender ? getCategory1Options({ gender, keyword }) : [],
    optional: true,
  },
  {
    name: 'category2',
    label: t('form.category2'),
    type: 'select',
    deps: ['gender', 'category1'],
    options: (keyword, { gender, category1 }) => gender && category1 ? getCategory2Options({ gender, keyword, category1 }) : [],
    optional: true,
  },
  {
    name: 'description',
    label: t('form.description'),
    type: 'input',
  },
  {
    name: 'details',
    label: t('form.details'),
    type: 'textarea',
  }
]
