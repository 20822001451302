import React, { FC } from 'react';
import { MaybePromiseUtils } from '@@/utils';
import { AuditCrudList } from '@/component/audit-crud-list';
import { ProductAction, ProductActionQuery } from '@/interface';
import { t } from '@/lang';
import { getProductActions, auditProductAction } from '@/service';

const ProductAudit: FC = () => (
  <AuditCrudList<ProductAction, ProductActionQuery>
    title="SPU 审核"
    filters={[
      {
        name: 'spuId',
        label: t('placeholder.spuId'),
        type: 'input'
      }
    ]}
    columns={[
      {
        title: t('table.spuId'),
        width: 100,
        dataIndex: 'spu_id',
      }
    ]}
    read={getProductActions}
    onOperate={(actionStatus, { id }, onReload) => {
      MaybePromiseUtils.map(
        auditProductAction({
          actionId: id,
          actionStatus,
        }),
        onReload
      )
    }}
  />
)

export default ProductAudit;
