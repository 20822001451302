import React, { useState, useEffect } from 'react';
import { EmailCreation } from "@/interface";
import { CrudListFormItemConfig } from "@/component/crud-list";
import { FormItemList } from "@/component/form-item-list";
import { getEmailTemplate } from '@/service';
import { Checkbox } from 'antd';
import styles from './send-email-modal.module.less';
import { FormItemDictList } from "@/component/form-item-list";
import { Modal, Button } from 'antd';
import { getEmailDemo, getEmailDemoEjs, getEmailParam } from "@/service";


export const emailFormFields: CrudListFormItemConfig<EmailCreation>[] = [
  {
    name: 'subject',
    label: '主题',
    type: 'input',
  },
  {
    name: 'fromAddress',
    label: '发送人',
    type: 'input',
  },
  {
    name: 'fromAddressName',
    label: '发送人名字',
    type: 'input',
  },
  {
    name: 'toAddresses',
    label: '接收人',
    type: 'custom',
    render: ({ value, onChange }) => {
      const keywords = typeof (value) == 'string' ? value.split(',') : value
      return (
        <>
          <FormItemList value={keywords} onChange={onChange} />
          <li className={styles.row}>
            <Checkbox onChange={(e) => e.target.checked ? onChange(['all']) : onChange([])} >发送到所有用户</Checkbox>
          </li>
        </>
      )
    }
  },
  {
    name: 'templateName',
    label: '模版',
    type: 'radio',
    options: (keyword) => getEmailTemplate({ keyword }),
  },
  {
    name: 'demo',
    label: ' ',
    type: 'custom',
    optional: true,
    deps: ['templateName'],
    render: ({ depValues, onChange }) => {
      const ejs = require('ejs')
      const [visible, setVisible] = useState(false);
      const [loading, setLoading] = useState(false);
      const [emailDemo, setEmailDemo] = useState('');
      const [emailParam, setEmailParam] = useState({});
      const templateName = depValues.templateName
      const get_demo = async () => {
        setLoading(true)
        const demo = (await getEmailDemoEjs({ 'emailName': templateName })).data
        const param = (await getEmailParam({ 'emailName': templateName })).data
        setEmailParam(param)
        setEmailDemo(demo)
        setVisible(true)
        setLoading(false)
      }
      const temp = ejs.render(emailDemo, emailParam)
      useEffect(() => {
        setVisible(false)
        setLoading(false)
        setEmailDemo('')
        setEmailParam({})
      }, [templateName])
      return visible ? (<Modal width="50vw" title='预览' visible={visible} footer={null} onCancel={() => setVisible(false)}>
        <div dangerouslySetInnerHTML={{ __html: temp }} onChange={onChange} ></div>
      </Modal>) : templateName ? <Button size="large" onClick={() => get_demo()} loading={loading}>预览</Button> : <></>
    }
  },
]
