import React from 'react';
import { CrudListColumnConfig, CrudListFormConfig } from "@/component/crud-list";
import { Copy, CopyQuery, CopyCreation } from "@/interface";
import { t } from "@/lang";
import { COPY_STATUSES } from "@/enum";
import { ObjectUtils } from "@@/utils";
import { CrudListFormItemConfig } from "@/component/crud-list";
import { RichContentEditor } from '@/component/rich-content-editor';
import { FormItemList, FormItemRichList } from "@/component/form-item-list";
import { GENDERS } from "@reversible/schema";
import { getCategory1Options, getCategory2Options, getAllDesigners } from "@/service";

export const copyFilters: CrudListFormConfig<CopyQuery>[] = [
  {
    name: 'id',
    label: 'copy ID',
    type: 'int',
  },
  {
    name: 'key',
    label: 'key',
    type: 'input',
  },
  {
    name: 'copyStatus',
    label: 'copy状态',
    type: 'select',
    options: COPY_STATUSES.map(value => ({
      label: t(`value.copyStatus.${value}`),
      value,
    })),
  },
]

export const copyColumns: CrudListColumnConfig<Copy>[] = [
  {
    title: 'copy ID',
    width: 85,
    dataIndex: 'id',
    fixed: 'left',
  },
  {
    title: 'key',
    width: 120,
    dataIndex: 'key',
  },
  {
    title: 'header',
    width: 160,
    dataIndex: 'header',
  },
  {
    title: 'meta 标题',
    width: 120,
    dataIndex: 'title',
  },
  {
    title: '描述',
    width: 240,
    dataIndex: 'description',
  },
  {
    title: '关键词',
    width: 120,
    dataIndex: 'keywords',
  },
  {
    title: 'copy状态',
    width: 90,
    dataIndex: 'copyStatus',
    display: copyStatus => t(`value.copyStatus.${copyStatus}`),
  },
  {
    title: t('table.createBy'),
    width: 100,
    dataIndex: 'createBy',
  },
  {
    title: t('table.updateBy'),
    width: 100,
    dataIndex: 'updateBy',
  },
  {
    title: t('table.createTime'),
    width: 120,
    dataIndex: 'createTime',
    display: 'time',
  },
  {
    title: t('table.updateTime'),
    width: 120,
    dataIndex: 'updateTime',
    display: 'time',
  },
];

export const adaptCopyCreation = (data?: Copy): CopyCreation => {
  if (data) {
    const raw = ObjectUtils.pick(
      data,
      [
        'key',
        'title',
        'description',
        'keywords',
        'header',
        'introduction',
        'footer',
        'search',
        'gender',
        'category1',
        'category2',
        'brand',
        'keyword',
      ]
    );

    if (raw.introduction && 'text' in raw.introduction[0]) {
      return {
        ...raw,
        introduction: [{
          type: 'p',
          children: raw.introduction,
        }]
      }
    }

    return raw;
  }

  return {
    key: '',
    title: '',
    description: '',
    keywords: '',
    header: '',
    introduction: [{
      type: 'p',
      children: [{ text: '' }]
    }],
    footer: [],
    search: { gender: undefined, category1: undefined, category2: undefined, brand: undefined, keyword: undefined },
    gender: undefined,
    category1: undefined,
    category2: undefined,
    brand: undefined,
    keyword: undefined,
  }
}

export const copyFormFields: CrudListFormItemConfig<CopyCreation>[] = [
  {
    name: 'key',
    label: 'key',
    type: 'input',
  },
  {
    divider: 'Search 信息',
  },
  {
    name: ['gender'],
    label: t('form.gender'),
    type: 'select',
    options: GENDERS.map(value => ({
      value,
      label: t(`value.gender.${value}`),
    })),
    optional: true,
  },
  {
    name: ['category1'],
    label: t('form.category1'),
    type: 'select',
    deps: ['gender'],
    options: (keyword, { gender }) => {
      return gender ? getCategory1Options({ keyword, gender }) : []
    },
    optional: true,
  },
  {
    name: ['category2'],
    label: t('form.category2'),
    type: 'select',
    deps: ['gender', 'category1'],
    options: (keyword, { gender, category1 }) => {
      return gender ? getCategory2Options({ keyword, gender, category1 }) : []
    },
    optional: true,
  },
  {
    name: 'brand',
    label: t('form.brand'),
    type: 'mult_select',
    deps: ['gender'],
    options: (keyword, { gender }) => gender ? getAllDesigners({ gender, keyword }) : [],
    optional: true,
  },
  {
    name: ['keyword'],
    label: 'keyword',
    type: 'input',
    optional: true,
  },
  {
    divider: 'Meta 信息',
  },
  {
    name: 'title',
    label: '标题',
    type: 'input',
    optional: true,
  },
  {
    name: 'description',
    label: '描述',
    type: 'textarea',
    optional: true,
  },
  {
    name: 'keywords',
    label: '关键词',
    type: 'custom',
    optional: true,
    render: ({ value, onChange }) => {
      const keywords = typeof (value) == 'string' ? value.split(',') : value
      return (
        <FormItemList value={keywords} onChange={onChange} />
      )
    }
  },
  {
    divider: '正文',
  },
  {
    name: 'header',
    label: 'header标题',
    type: 'input',
  },
  {
    divider: 'introduction信息',
  },
  {
    name: 'introduction',
    label: '',
    type: 'custom',
    render: ({ value, onChange }) => (
      <RichContentEditor
        attribute={['link', 'h2']}
        value={value}
        onChange={onChange}
        placeholder="header"
      />
    )
  },
  {
    divider: 'footer信息',
  },
  {
    name: 'footer',
    label: '',
    optional: true,
    type: 'custom',
    render: ({ value, onChange }) => {
      return (
        <FormItemRichList value={value} onChange={onChange} />
      )
    }
  },
]
