import React, { FC } from 'react';
import { message } from 'antd';
import { useFlow } from '@@/react-hooks';
import { postImport } from '@/service';
import { UserMigrateCreation } from '@/interface';
import { CreateUpdateForm } from '@/component/crud-list/create-update-form';
import { userMigrateFormFields } from './config';
import styles from './user-migrate-modal.module.less';
import { Async } from '@/component/async';
import { useFlowEffect } from '@reversible/common/es/react-hooks';
import { t } from '@/lang';
import { Button } from 'antd';


const FORM_ID = 'migrate-form';

const UserMigrateForm: FC = () => {

  const dataState = useFlowEffect<UserMigrateCreation>(null, function* ({
    call,
    put
  }) {
    yield put(null);

    yield put({
      'taskName': undefined,
      'email': undefined,
      'userId': undefined,
    })
  }, []);

  const [{ loading }, dispatch] = useFlow<void, UserMigrateCreation>(null, function* ({ call, block }, creation) {
    yield block();
    try {
      yield call(postImport, creation)
      message.success('提交成功');
    }
    catch (error) {
      message.error(error.msg);
    }
  });

  return (
    <div className={styles.page_modal}>
      <div className={styles.modal}>
        <Async source={dataState} >
          {
            data => (
              <CreateUpdateForm
                initialValues={data}
                fields={userMigrateFormFields}
                id={FORM_ID}
                onFinish={fields => dispatch(fields)}
                labelAlign="left"
                labelCol={{
                  span: 3
                }}
              />
            )
          }
        </Async>
        <span className={styles.submit}>
          <Button size="large" form={FORM_ID} htmlType="submit" type="primary" loading={loading} >
            迁移用户
          </Button>
        </span>
      </div>
    </div>
  )
}

export default UserMigrateForm;