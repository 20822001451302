import React, { ComponentType, FC, useEffect } from 'react';
import { Switch, Redirect } from 'react-router';
import { Route } from 'react-router-dom';
import { AuthStatus } from '@/enum';
import { useAccount } from '@/store/use-account';
import { PureAntdLoading } from '@/ui/pure-antd-loading';
import { url } from '@/url';
import { NavLayout } from '@/layout/nav-layout';
import { MenuKey, MenuNode, useLayoutMenu } from '@/store/use-layout-menu';

// views
import Login from './login/login';
import ProductList from './product/product-list';
import ProductCombine from './product/product-combine';
import ProductWebsiteList from './product/product-website-list';
import ProductAudit from './product/product-audit';
import OfferList from './offer/offer-list';
import OfferWebsiteList from './offer/offer-website-list';
import ProductMappingList from './offer/product-mapping-list';
import OfferAudit from './offer/offer-audit';
import CouponList from './coupon/coupon-list';
import AdminSysUser from './admin/admin-sys-user';
import ReportAudit from './report/report-audit';
import ReportOfferAudit from './report/report-offer-audit';
import DesignerList from './designer/designer-list';
import designerSizeList from './designer/designer-size'
import BlogList from './blog/blog-list'
import CopyList from './copy/copy-list'
import CampaignList from './campaign/campaign-list'
import TopicList from './campaign/topic-list'
import EmailForm from './email/send-email'
import WebUserList from './user/web-user-list';
import UserMigrateForm from './user/user-migrate';
import OrderList from './order/order-list';
import GoogleMerchantList from './google_merchant/google-merchant-list';

const Wrapper: FC = ({ children }) => <>{children}</>;

const LayoutComponentDict: Record<MenuKey, ComponentType<{}>> = {
  product: Wrapper,
  productList: ProductList,
  productWebsiteList: ProductWebsiteList,
  productCombine: ProductCombine,
  productAudit: ProductAudit,
  offer: Wrapper,
  offerList: OfferList,
  offerWebsiteList: OfferWebsiteList,
  productMappingList: ProductMappingList,
  offerAudit: OfferAudit,
  coupon: Wrapper,
  couponList: CouponList,
  admin: Wrapper,
  adminSysUserList: AdminSysUser,
  report: Wrapper,
  reportAudit: ReportAudit,
  reportOfferAudit: ReportOfferAudit,
  designer: Wrapper,
  designerList: DesignerList,
  designerSizeList: designerSizeList,
  blog: Wrapper,
  blogList: BlogList,
  copy: Wrapper,
  copyList: CopyList,
  campaign: Wrapper,
  campaignList: CampaignList,
  topicList: TopicList,
  webUser: Wrapper,
  emailForm: EmailForm,
  webUserList: WebUserList,
  userMigrateForm: UserMigrateForm,
  order: Wrapper,
  orderList: OrderList,
  googleMerchant: Wrapper,
  googleMerchantList: GoogleMerchantList,
}

const RegisterMenu: FC<{
  id: MenuKey;
}> = ({ id }) => {
  const [, , setMenuStack] = useLayoutMenu();

  useEffect(() => {
    setMenuStack(prev => [...prev, id]);
    return () => {
      setMenuStack(prev => prev.filter(item => item !== id));
    }
  }, [id]);

  return null;
}

const renderMenuRoute = (menuNodes: MenuNode[]) => {
  if (!menuNodes.length) {
    return null;
  }

  const firstNode = menuNodes[0];
  const redirectTo = url[Array.isArray(firstNode) ? firstNode[0] : firstNode]();
  return (
    <Switch >
      {
        menuNodes.map(item => {
          const [self, subList] = Array.isArray(item) ? item : [item, []];
          const Component = LayoutComponentDict[self];
          return <Route path={url[self]()} key={self}>
            <RegisterMenu id={self} />
            <Component>
              {renderMenuRoute(subList)}
            </Component>
          </Route>
        })
      }
      <Redirect to={redirectTo} />
    </Switch>
  )
}

export const AppView: FC = () => {
  const [{ data: { status }, error }] = useAccount();

  const [menuNodes] = useLayoutMenu();

  if (error) {
    return (
      <div>error</div>
    )
  }

  switch (status) {
    case AuthStatus.Unknown:
      return (
        <PureAntdLoading />
      )
    case AuthStatus.LoggedIn:
      return (
        <NavLayout>
          {renderMenuRoute(menuNodes)}
        </NavLayout>
      )
    case AuthStatus.NotLoggedIn:
      return (
        <Switch >
          <Route path="*" >
            <Login />
          </Route>
        </Switch>
      );
    default:
      return null;
  }
}