import React, { FC, useEffect, useState } from 'react';
import { DESIGNER_STYLE_WOMEN, DESIGNER_STYLE_MEN, Gender } from "@/enum";
import { FormItemSelect } from '../../../../../src/component/crud-list/form-item'
import { useFlowEffect } from '@@/react-hooks';
import { getChatGpt } from "@/service";

export interface FormItemListProps {
  value;
  depValues;
  onChange;
}

export const DesignerStylesList: FC<FormItemListProps> = ({
  value,
  depValues,
  onChange
}) => {
  let values = value == undefined ? {} : value
  const gender = depValues.gender
  const designer = depValues.designer
  const change_gender = (e, current_gender) => {
    let data = {}
    data[current_gender] = e
    let gender_data = current_gender == 'women' ? 'men' : 'women'
    if (Object.keys(values).includes(gender_data)) {
      data[gender_data] = values[gender_data]
    }
    onChange(data)
  }
  let load = false
  if (depValues.styles == undefined && designer && gender) {
    const men_msg = `choose from the styles in [Luxury, Avant Garde, Streetwear, Techwear, Gothic, Glam Rock, Artistic, Athleisure, Classic, Bohemian, Punk, Grunge, Minimalism, Denim, Workwear, Preppy, Maximalism, Utilitarian, Eclectic, Casual], which ones is the designer brand ${designer} "men" categorized in? only answer the style name from the given options separated by comma if applicable, for example, Classic, Techwear. Do not add other words other than the style names.`
    const women_msg = `choose from the styles in [Luxury, Artistic, Avant Garde, Streetwear, Casual, Classic, Utilitarian, Gothic, Athleisure, Glam, Feminine, Bohemian, Baroque, Grunge, Punk, Minimalism, Maximalism, Preppy, Eveningwear, Eclectic], which ones is the designer brand ${designer} "women" categorized in? only answer the style name from the given options separated by comma if applicable, for example, Classic, Techwear. Do not add other words other than the style names.`
    const menDesignerStyles = useFlowEffect('' as string, function* ({ call, put }) {
      const { data } = yield call(getChatGpt, { 'message': men_msg });
      yield put(data)
    }, []);
    const womenDesignerStyles = useFlowEffect('' as string, function* ({ call, put }) {
      const { data } = yield call(getChatGpt, { 'message': women_msg });
      yield put(data)
    }, []);
    if (menDesignerStyles.data.length && !!!Object.keys(values).includes('men')) {
      values['men'] = menDesignerStyles.data.split(',')
    }
    if (womenDesignerStyles.data.length && !!!Object.keys(values).includes('women')) {
      values['women'] = womenDesignerStyles.data.split(',')
    }
    load = womenDesignerStyles.loading && menDesignerStyles.loading
    useEffect(() => {
      if (menDesignerStyles.data.length && womenDesignerStyles.data.length && onChange) {
        onChange(values)
      }
    }, [values])
  }
  const women_options = DESIGNER_STYLE_WOMEN.map(value => ({ value, label: value }))
  const men_options = DESIGNER_STYLE_MEN.map(value => ({ value, label: value }))
  const options = (Array.isArray(gender) && gender.includes(Gender.Women)) || gender == Gender.Women ?
    women_options : (Array.isArray(gender) && gender.includes(Gender.Men)) || gender == Gender.Men ? men_options : []
  if (Array.isArray(gender) && gender.length == 2) {
    return (
      <>
        <div>Women</div> <FormItemSelect load={load} type='mult_select' options={women_options} value={values ? values['women'] : values} depValues={depValues} mode='multiple' onChange={e => change_gender(e, 'women')} />
        <div>Men</div>  <FormItemSelect load={load} type='mult_select' options={men_options} value={values ? values['men'] : values} depValues={depValues} mode='multiple' onChange={e => change_gender(e, 'men')} />
      </>
    )
  }
  return (
    <>
      <FormItemSelect load={load} type='mult_select' options={options} value={values ? values[gender] : values} depValues={depValues} mode='multiple' onChange={e => change_gender(e, gender)} />
    </>
  )
};
