import React, { ReactNode, useMemo } from 'react';
import { Button, Image, Carousel } from 'antd';
import moment from 'moment';
import { BuiltInRenderName, CrudListProps } from './interface';
import { ColumnProps } from 'antd/lib/table';
import { isFunction } from '@@/utils';
import styles from './crud-list.module.less';

const columnBuiltInRenderDict: Record<BuiltInRenderName, ColumnProps<any>['render']> = {
  string: (field) => field != null ? <span className={styles.cell_string}>{String(field)}</span> : null,
  image: (field) => field != null ? <Image className={styles.cell_img} src={String(field)} /> : null,
  images: (field) => field != null ? <Image.PreviewGroup>
    <Carousel>
      {
        field.map(src => {
          if (src == field[0]) {
            return <Image className={styles.cell_img} src={src} key={src} />
          }
          else {
            return <Image className={styles.cell_img} src={src} key={src} style={{ 'display': 'none' }} />
          }
        })
      }
    </Carousel>
  </Image.PreviewGroup> : null,
  link: (field) => <a href={String(field)} target="_blank" >{String(field)}</a>,
  time: (field) => <span className={styles.cell_time}>{moment(field).format('YYYY-MM-DD HH:mm')}</span>,
  multiline: (field) => field != null ? <div className={styles.cell_multiline}>{String(field)}</div> : null,
};

export const useCrudColumns = <T extends object, C extends object>(
  { columns, del, deletable, update, updatable, operations, onOperate }: CrudListProps<T, C, any>,
  onUpdate: (item: T) => void,
  onDelete: (item: T) => void,
  onReload: () => void,
) => {
  return useMemo((): ColumnProps<T>[] => [
    ...columns.map(({
      title,
      dataIndex,
      display = 'string',
      align,
      width,
      fixed,
    }) => ({
      title,
      dataIndex,
      key: dataIndex,
      align,
      width,
      fixed,
      render: isFunction(display) ? display : columnBuiltInRenderDict[display],
    })),
    ...(update || del || !Array.isArray(operations) || operations.length ? [{
      title: '操作',
      key: 'operations',
      width: '90px',
      align: 'center',
      fixed: 'right',
      render: (item: T) => {
        const operationList: {
          children: ReactNode;
          danger?: boolean;
          order?: number;
          onClick(): void;
        }[] = [];

        if (update && updatable(item)) {
          operationList.push({
            children: '编辑',
            order: operationList.length,
            onClick: () => onUpdate(item)
          });
        }

        if (del && deletable(item)) {
          operationList.push({
            children: '删除',
            danger: true,
            order: operationList.length,
            onClick: () => onDelete(item),
          })
        }

        operationList.push(
          ...(Array.isArray(operations) ? operations : operations(item)).map(
            ({ name, operation, ...rest }) => ({
              ...rest,
              children: name,
              onClick: () => onOperate(operation, item, onReload)
            })
          )
        )

        operationList.sort(
          ({ order: a }, { order: b }) => {
            if (a != null && b != null) return a - b;
            if (a != null) return -1;
            if (b != null) return 1;
            return 0;
          }
        )

        return (
          <>
            {operationList.map(({
              danger,
              children,
              onClick
            }, i) => (
              <Button
                key={i}
                size="small"
                className={styles.operator}
                danger={danger}
                onClick={onClick}
              >
                {children}
              </Button>
            ))}
          </>
        )
      }
    } as ColumnProps<T>] : []),
  ], [columns, !!del, deletable, !!update, updatable, onUpdate, onDelete, onReload, onOperate]);
}