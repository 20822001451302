import React, { FC, ReactNode, memo, useCallback, useMemo } from 'react';
import { createEditor, Editor, Range, Transforms } from "slate";
import { Image, Tooltip } from 'antd';
import { withHistory } from "slate-history";
import { Editable, withReact, Slate, RenderLeafProps, RenderElementProps, useFocused, useSelected } from "slate-react";
import { RichContent, RichContentElement, RichContentEmbedded, RichContentImg, RichText } from '@/interface/rich-content';
import styles from './rich-content-editor.module.less';
import { RichAttr, Toolbar } from './toolbar';
import { isLink } from './utils';
import { EditContextProvider } from './edit-context';
import classNames from 'classnames';
import { LinkOutlined } from '@ant-design/icons';
import isHotKey from 'is-hotkey';

const Leaf: FC<RenderLeafProps> = memo(({
  leaf,
  children,
  attributes,
}) => {
  const isLeafLink = isLink(leaf);
  const { u, b, i } = leaf as RichText;
  let node: ReactNode = children;
  if (u || isLeafLink) {
    node = <u>{node}</u>
  }
  if (b) {
    node = <b>{node}</b>
  }
  if (i) {
    node = <i>node</i>
  }
  if (isLink(leaf)) {
    return (
      <span {...attributes} >
        <Tooltip title={<>
          <LinkOutlined className={styles.link_icon} />{leaf.href}
        </>} >
          {node}
        </Tooltip>
      </span>
    )
  }

  return (
    <span {...attributes}>{node}</span>
  )
});

const ImgElement: FC<RenderElementProps> = ({ element, attributes, children }) => {
  const { src, alt, caption } = element as RichContentImg;

  const focused = useFocused();
  const selected = useSelected();

  return (
    <div {...attributes} className={classNames(styles.block, {
      [styles.block_focused]: focused && selected,
    })} >
      {children}
      <figure contentEditable={false} >
        <Image preview={false} src={src} alt={alt} />
        {caption ? <figcaption>{caption}</figcaption> : null}
      </figure>
    </div>
  );
}

const EmbeddedElement: FC<RenderElementProps> = ({ element, attributes, children }) => {
  const { html } = element as RichContentEmbedded;

  const focused = useFocused();
  const selected = useSelected();

  return (
    <div {...attributes} className={classNames(styles.block, {
      [styles.block_focused]: focused && selected,
    })} >
      {children}
      <div contentEditable={false} dangerouslySetInnerHTML={{ __html: html }} />
    </div>
  );
}

const Element: FC<RenderElementProps> = (props) => {
  const {
    attributes,
    element,
    children
  } = props;

  const el = element as RichContentElement;

  switch (el.type) {
    case "h2":
      return <h2 {...attributes} >{children}</h2>;
    case "h3":
      return <h3 {...attributes} >{children}</h3>;
    case "p":
      return <p {...attributes} >{children}</p>;
    case 'embedded': {
      return <EmbeddedElement {...props} />;
    }
    case "img": {
      return <ImgElement {...props} />;
    }
    default:
      return (
        <p {...attributes}>{children}</p>
      );
  }
}

export interface RichContentEditorProps {
  value: RichContent;
  placeholder?: string;
  attribute?: RichAttr[];
  onChange(value: RichContent): void
}

export const RichContentEditor: FC<RichContentEditorProps> = ({
  value,
  placeholder,
  onChange,
  attribute
}) => {
  const editor = useMemo(() => {
    const e = withHistory(withReact(createEditor()));

    // to make image selectable, img nodes need to be marked as voids
    const { isVoid } = e;

    e.isVoid = (element: RichContentElement) => {
      return ['img', 'embedded'].includes(element?.type) ? true : isVoid(element);
    }

    return e;
  }, []);

  const renderElement = useCallback(props => <Element {...props} />, []);
  const renderLeaf = useCallback(props => <Leaf {...props} />, []);

  const onKeyDown: React.KeyboardEventHandler<HTMLInputElement> = ({ nativeEvent }) => {
    const { selection } = editor;
    // when keydown space at the end of a link, insert an empty text node to break the link
    if (isHotKey('space', nativeEvent) && selection && Range.isCollapsed(selection)) {
      const match = Editor.node(editor, selection);
      if (isLink(match?.[0]) && Editor.isEnd(editor, Range.end(selection), match[1])) {
        nativeEvent.preventDefault();
        Transforms.insertNodes(editor, [{ text: ' ' }], { at: selection });
        Transforms.move(editor, {
          distance: 1,
          unit: 'character',
        });
      }
    }
    if (isHotKey('enter', nativeEvent) && selection && Range.isCollapsed(selection)) {
      const match = Editor.node(editor, selection);
      if (Editor.isEnd(editor, Range.end(selection), match[1])) {
        nativeEvent.preventDefault();
        const new_node = {
          type: 'p',
          children: [{ text: '' }],
        };
        Transforms.insertNodes(editor, new_node)
      }
    }
  }

  return (
    <Slate editor={editor} value={value} onChange={onChange} >
      <EditContextProvider >
        <Toolbar attribute={attribute} />
        <Editable
          className={styles.editable}
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          placeholder={placeholder}
          spellCheck
          onKeyDown={onKeyDown}
        />
      </EditContextProvider>
    </Slate>
  )
};

