import React, { FC } from 'react';
import { getProductMapping } from '@/service';
import { CrudList } from "@/component/crud-list";
import { mappingFilters, mappingColumns } from './config';
import { MappingQuery, Mapping } from '@/interface';
import { useModal } from '@reversible/common/es/react-hooks';
import { SpuUpdateModal } from './update-modal';


const EDIT_OPERATION = 'update';

const ProductMappingList: FC = () => {
  const [editModalState, openModal, closeModal] = useModal<Mapping>(null);

  return (
    <>
      <CrudList<Mapping, null, MappingQuery>
        title="Mapping"
        rowKey="crawlerId"
        auditable
        isRequests={false}
        filters={mappingFilters}
        read={getProductMapping}
        columns={mappingColumns}
        operations={
          [{
            operation: EDIT_OPERATION,
            order: -1,
            name: '更新到spu'
          }
          ]}
        onOperate={(operation, row) => {
          switch (operation) {
            case EDIT_OPERATION:
              openModal(row);
              break;
            default:
              break;
          }
        }}
      >
      </CrudList>
      <SpuUpdateModal
        visible={editModalState.visible}
        data={editModalState.data}
        onClose={closeModal}
        onSuccess={closeModal}
      />
    </>
  );
};

export default ProductMappingList;
