import React, { FC } from 'react';
import { getWebsiteOffers, deleteOffer, putOffer, postProduct, getWebsiteProduct, getWebsiteOffer } from '@/service';
import { CrudList } from "./component/offer-expand";
import { adaptOfferCreation, offerFilters, offerColumns, offerFormFields } from './config';
import { Offer, OffersQuery, OfferCreation } from '@/interface';
import { SpuStatus } from '@reversible/schema';
import { useModal } from '@reversible/common/es/react-hooks';
import { SpuUpdateModal } from './update-modal';

const updateOffer = async (id, creation) => {
  if (!creation.spuId) {
    const offerId = id
    const offer = await (await getWebsiteOffer({ offerId })).data
    const spuId = offer.spuId
    const spu = await (await getWebsiteProduct({ spuId })).data
    const spu_data = {
      "name": creation.offerName, "brand": offer.brand, "gender": spu.gender, "category1": spu.category1, "spuStatus": SpuStatus.Available,
      "category2": spu.category2, "sizeUnit": spu.sizeUnit, "images": creation.images, "skipAudit": true, "forceOnline": true,
      "description": offer.offerDescription, "price": creation.price, "currency": creation.currency, 'unclassified': true,
      "sourceUrl": creation.url, "retailer": "", "retailerSpuSn": "", "styleId": "", "color": "", "composition": "",
      "country": offer.offerCountry, "publishTime": undefined, "createBy": "", "updateBy": ""
    }
    const new_spu = await (await postProduct(spu_data)).data
    creation.spuId = new_spu.id
  }
  return putOffer({
    offerId: id,
    data: creation
  })
}

const EDIT_OPERATION = 'update';

const OfferWebsiteList: FC = () => {
  const [editModalState, openModal, closeModal] = useModal<Offer>(null);

  return (
    <>
      <CrudList<Offer, OfferCreation, OffersQuery>
        title='线上Offer'
        rowKey="id"
        isRequests={false}
        isExpandable={true}
        filters={offerFilters}
        fields={offerFormFields}
        columns={offerColumns}
        read={getWebsiteOffers}
        del={({ id }, actionConfig) => deleteOffer({
          offerId: id,
          ...actionConfig,
        })}
        update={({ id }, creation) => updateOffer(id, creation)}
        adaptCreation={adaptOfferCreation}
        operations={
          [{
            operation: EDIT_OPERATION,
            order: -1,
            name: '更新到spu'
          }
          ]}
        onOperate={(operation, row) => {
          switch (operation) {
            case EDIT_OPERATION:
              openModal(row);
              break;
            default:
              break;
          }
        }}
      >
      </CrudList>
      <SpuUpdateModal
        visible={editModalState.visible}
        data={editModalState.data}
        onClose={closeModal}
        onSuccess={closeModal}
      />
    </>
  );
}

export default OfferWebsiteList;
