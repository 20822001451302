import { ActionStatus, ActionType, AuditStatus, AuthorityId, BlogStatus, CopyStatus, CampaignStatus, CampaignType, OrderStatus, GoogleMerchantStatus, GoogleMerchantType } from "@/enum";
import { Condition, ExpireTime, Gender, OfferStatus, OfferType, SpuStatus } from "@reversible/schema";

export default {
  route: {
    dashboard: '首页',

    webUserManagement: '用户管理',
    webUserCreat: '创建用户',
    webUserEdit: '编辑用户',
    webUserList: '用户列表',

    productManagement: '商品管理',
    libraryCreateProduct: '创建商品',
    combineProduct: '合并商品',
    libraryEditProduct: '编辑商品',
    libraryProductList: '离线商品列表',
    websiteProductList: '商城商品列表',
    productActionList: '商品审核记录',

    offerManagement: 'Offer管理',
    libraryCreateOffer: '创建Offer',
    libraryEditOffer: '编辑Offer',
    libraryOfferList: '离线Offer列表',
    websiteOfferList: '商城Offer列表',
    offerActionList: 'Offer审核记录',
    productMappingList: 'mapping列表',

    orderManagement: '订单管理',
    orderList: '订单列表',

    operationManagement: '运营管理',
    operationPositionList: '运营位管理',
    operationCandidateList: '候选集管理',

    algorithmTool: '算法工具',
    imageSearchProducts: '商品图片搜索',

    adminManagement: '系统管理',
    adminMonitorList: '系统监控',
    adminPermissionList: '权限管理',
    adminSysUserList: '系统用户管理',

    coupon: '优惠券管理',
    couponList: '优惠券列表',

    errorPages: '错误页面',
    page401: '401',
    page404: '404',
    profile: '个人中心',

    report: 'report管理',
    reportAudit: 'post审核记录',
    reportOfferAudit: 'offer审核记录',

    designer: 'designer管理',
    designerList: 'designer列表',
    designerSizeList: '品牌Size列表',

    blog: 'blog管理',
    blogList: 'blog列表',

    copy: 'copy管理',
    copyList: 'copy列表',

    campaign: 'campaign管理',
    campaignList: 'campaign列表',
    topicList: 'topic列表',
    googleMerchant: 'google商家管理',
    googleMerchantList: '商家配置',
    emailForm: '群发邮件',
    webUser: 'user管理',
    userMigrateForm: '用户迁移',
  },
  login: {
    title: 'Reversible 运营后台',
    logIn: '登录',
    username: '账号',
    password: '密码',
    tips: '暂不开放注册，请联系管理员开通账号！'
  },
  navbar: {
    dashboard: '首页',
    logOut: '退出登录',
    profile: '个人中心',
    size: '布局大小'
  },
  dialog: {
    newUser: '创建用户',
    editUser: '编辑用户',
    editRule: '编辑策略',
    newRule: '创建策略'
  },
  form: {
    spuId: '商品ID',
    spuId1: '商品ID 1',
    spuId2: '商品ID 2',
    spuName: '商品名',
    gender: '性别',
    category1: '一级分类',
    category2: '二级分类',
    brand: '品牌',
    country: '国家',
    sizeUnit: '尺码单元',
    publishTime: '发布时间',
    imgUrls: '图片URLs',
    color: '颜色',
    composition: '材质',
    description: '描述',
    price: '价格',
    currency: '币种',
    sourceUrl: '来源URL',
    retailer: '卖家',
    retailerSpuSn: '卖家商品标识',
    serialNumber: '商品编码',
    spuState: '商品状态',
    spuStatus: '商品状态',
    skipAudit: '跳过审核',
    sizes: '尺码',
    launchTime: '上线时间',
    withdrawTime: '撤回时间',
    updateTime: '更新时间',
    createBy: '创建人',
    updateBy: '更新人',
    hasStory: '有Story',
    measurement: 'measurement',
    offerDescription: '详情',
    userId: '用户ID',
    user: '用户',
    offerCountry: '国家',
    inventory: '库存',
    offerPrice: '价格',
    offerCurrency: '币种',
    usdPrice: '美刀价',
    offerShipping: '邮费',
    offerTime: '创建时间',
    offerValidDeltaTime: '有效时间',
    offerAcceptTime: '成单时间',
    offerName: 'Offer名',
    size: '尺码',
    imgUrl: '封面图',
    offerType: 'Offer类型',
    offerCondition: '成色',
    offerUrl: 'Offer URL',
    offerValidTime: 'Offer有效时间',
    addressId: '地址ID',
    addressName: '地址名称',
    offerStatus: 'Offer状态',
    coupon: '优惠券',
    details: '详情',
    startTime: '生效时间',
    expireTime: '过期时间',
    region: '地区',
    authorityId: '权限',

    designer: '品牌',
    prefix: '前缀',
    variants: '品牌variants',
    alias: '别名',
    url_alias: 'url别名',
    summary: '概要',

    spuLock: '是否锁定',

    title: '标题',
    thumbnail: '首图',
    brief: '摘要',
    author: '作者',
    meta: 'meta',
    content: '内容',

    text: '内容',
    href: 'url',
    caption: 'caption',
    src: 'src',
    alt: 'alt',

    city: '城市',
    address: '地址',
    mobile: '电话',
    zip_code: '邮编',
  },
  divider: {
    core: '基本详情',
    description: '材质描述',
    times: '时间详情',
    history: '历史详情',
    source: '来源详情'
  },
  table: {
    authorityId: '权限',
    uid: 'UID',
    expand: '详情',
    avatar: '头像',
    userName: '用户名',
    email: '邮箱',
    gender: '性别',
    region: '地域',
    registerTime: '注册时间',
    lastLoginTime: '最后登录时间',
    userStatus: '用户类型',
    operation: '操作',
    password: '密码',
    mobile: '手机号',
    firstName: '姓',
    lastName: '名',
    birthday: '生日',
    biography: '签名',
    phone: '手机号',

    spuId: '商品ID',
    spuName: '商品名',
    brand: '品牌',
    category1: '一级分类',
    category2: '二级分类',
    spuState: '商品状态',
    spuStatus: '商品状态',
    availableTime: '发售时间',
    size: '尺码',
    sizes: '尺码',
    sizeUnit: '尺码单元',
    currency: '币种',
    price: '价格',
    publishTime: '发布时间',
    isOnline: '发布状态',
    launchTime: '上线时间',

    scene: '场景',
    channel: '频道',
    strategy: '策略',
    position: '位置',
    extra: '详情',
    selectMethod: '选择策略',
    exposeMethod: '展现策略',
    startTime: '生效时间(UTC)',
    endTime: '结束时间',
    createTime: '创建时间',
    updateTime: '更新时间',

    candidateMode: '类型',

    offerId: 'OfferID',
    offerName: 'Offer名称',
    offerType: 'Offer类型',
    offerCondition: '成色',
    inventory: '数量',
    offerPrice: '价格',
    offerStatus: '状态',

    paymentAccount: '支付账号',
    userReview: '评价数',
    userTransaction: '订单数',
    priority: '优先级',

    userAccountStatus: '用户账户状态',

    productImg: '商品图',
    offerImg: 'Offer图',
    auditStatus: '审核状态',

    mode: '模式',

    userId: '用户ID',
    userIP: '用户IP',
    userLoginTime: '登录时间',
    operationSystem: '操作系统',
    explore: '浏览器版本',

    title: '标题',
    type: '类型',
    id: '序号',

    actionType: 'Action类型',
    description: '描述',
    createBy: '创建人',
    auditBy: '审核人',
    actionStatus: '操作状态',

    couponId: '优惠券ID',
    coupon: '优惠券',
    details: '详情',
    expireTime: '过期时间(UTC)',

    reportID: 'report ID',
    reporterName: '创建人',
    postImg: '缩略图',
    postID: 'post ID',
    postTitle: 'post 标题',
    postContent: 'post 内容',
    reportDescription: 'report 描述',
    auditComment: '理由',
    postStatus: 'post 状态',

    designer: '品牌',
    prefix: '前缀',
    variants: '品牌variants',
    alias: '别名',
    url_alias: 'url别名',
    summary: '概要',

    designerSizeID: 'designerSizeID',

    blogId: 'blog ID',
    blogStatus: 'blog 状态',
    thumbnail: '首图',
    brief: '摘要',
    author: '作者',
    meta: 'meta',
    content: '内容',
    updateBy: '更新人',
  },
  placeholder: {
    country: '国家',
    sizeUnit: '尺码单元',
    publishTime: '发布时间',
    imgUrls: '图片URL',
    color: '颜色',
    composition: '材质',
    description: '描述',
    price: '价格',
    currency: '币种',
    sourceUrl: '来源URL',
    retailer: '卖家',
    retailerSpuSn: '卖家商品标识',
    serialNumber: '商品编码',
    skipAudit: '跳过审核',

    actionId: '操作ID',
    actionType: '操作类型',
    category: '商品分类',
    uid: '用户ID',
    email: '邮箱',
    userName: '用户名',
    password: '密码',
    mobile: '手机号',
    firstName: '姓',
    lastName: '名',
    selectGender: '请选择性别',
    selectRegion: '请选择国家',
    selectUserStatus: '请选择用户状态',
    startTime: '生效时间',
    endTime: '结束时间',
    extra: '详情',
    mode: '模式',
    birthday: '生日',
    region: '地域',
    userStatus: '用户类型',
    biography: '请输入...',
    spuId: '商品ID',
    spuName: '商品名',
    gender: '性别',
    brand: '品牌',
    category1: '一级分类',
    category2: '二级分类',
    scene: '场景',
    channel: '频道',
    strategy: '策略组',
    spuState: '商品状态',
    spuStatus: '商品状态',
    createBy: '创建人',
    auditBy: '审核人',
    offerId: 'OfferID',
    offerStatus: 'Offer状态',
    userId: '用户ID',
    size: '尺码',
    inventory: '库存',
    imgUrl: '图片',
    measurement: 'measurement',
    offerName: 'Offer名',
    offerType: 'Offer类型',
    offerCondition: '成色',
    offerUrl: 'OfferURL',
    offerTime: '创建时间',
    offerValidTime: 'Offer有效时间',
    offerAcceptTime: 'Offer成单时间',
    offerPrice: '价格',
    offerCurrency: '币种',
    usdPrice: '美刀价',
    offerCountry: '国家',
    addressId: '地址ID',
    addressName: '地址名称',
    offerShipping: '邮费',

    reportId: 'report ID',
    postId: 'post ID',
    auditStatus: '审核状态',
    reportStartTime: '开始时间',
    reportEndTime: '截止时间',

    designer: '品牌',
    designerSizeID: 'designerSizeID',

    blogId: 'blog ID',
    blogStatus: 'blog 状态'
  },
  button: {
    cancel: '取消',
    confirm: '提交',
    search: '搜索',
    reset: '重置',
    newCandidateRule: '新策略',
    newPositionRule: '新策略',
    newProduct: '新建商品',
    newOffer: '新建Offer',
    newUser: '创建用户',
    edit: '编辑',
    editProduct: '编辑',
    editOffer: '编辑',
    resetPassword: '重置密码',
    pass: '通过',
    launch: '通过并上线',
    reject: '拒绝',
    submit: '提交',
    linkProductIndex: '查看',
    linkOfferIndex: '查看',
    viewParams: '查看参数',
    linkPostUrl: '查看',
  },
  value: {
    authorityId: {
      [AuthorityId.Admin]: '管理员',
      [AuthorityId.Member]: '成员',
    },
    gender: {
      undefined: '未知',
      [Gender.Men]: '男',
      [Gender.Women]: '女'
    },
    userStatus: {
      0: '未知',
      1: '普通',
      2: '管理员',
      3: '商家',
      6: '禁用'
    },
    userType: {
      1: 'member',
    },
    isOnline: {
      undefined: '未知', // fixme
      0: '离线',
      1: '在线'
    },
    offerType: {
      [OfferType.Ask]: '出售',
      [OfferType.Bid]: '求购',
    },
    condition: {
      [Condition.Acceptable]: '可接受',
      [Condition.BrandNew]: '全新',
      [Condition.LikeNew]: '几乎全新',
      [Condition.Good]: '成色好',
      [Condition.VeryGood]: '成色很好',
    },
    auditStatus: {
      undefined: '未知', // fixme
      [AuditStatus.Wait]: '待审',
      [AuditStatus.Pass]: '通过',
      [AuditStatus.Reject]: '拒绝'
    },
    spuStatus: {
      [SpuStatus.Unavailable]: '不可用',
      [SpuStatus.Available]: '正常',
      [SpuStatus.Unclassified]: '=值已废弃=',
      [SpuStatus.PendingAsk]: '创建中(出售)',
      [SpuStatus.PendingBid]: '创建中(求购)',
      [SpuStatus.Deleted]: '已删除'
    },
    offerStatus: {
      [OfferStatus.Available]: '正常',
      [OfferStatus.Unavailable]: '不可用',
      [OfferStatus.Deleted]: "已删除",
      [OfferStatus.Rejected]: '已拒绝',
      [OfferStatus.Completed]: '已完成',
      [OfferStatus.Expired]: '已过期',
      [OfferStatus.Preapproved]: '预扣款',
      [OfferStatus.Removed]: '已移除',
    },
    actionStatus: {
      [ActionStatus.Wait]: '待审',
      [ActionStatus.Pass]: '通过',
      [ActionStatus.Auto]: '自动',
      [ActionStatus.Reject]: '拒绝'
    },
    actionType: {
      [ActionType.Create]: '创建',
      [ActionType.Update]: '更新',
      [ActionType.Delete]: '删除',
    },
    expireTime: {
      [ExpireTime.FourteenDays]: '14天',
      [ExpireTime.Never]: '永不',
      [ExpireTime.OneDay]: '1天',
      [ExpireTime.SevenDays]: '7天',
      [ExpireTime.SixHours]: '6小时',
      [ExpireTime.ThirtyDays]: '30天',
      [ExpireTime.TwoDays]: '2天',
    },
    postStatus: {
      undefined: '未知', // fixme
      0: '不可用',
      1: '正常',
      2: '已删除',
    },
    blogStatus: {
      [BlogStatus.Available]: '可用',
      [BlogStatus.Deleted]: '已删除',
    },
    emailVerifiedStatus: {
      0: '未认证',
      1: '认证',
    },
    copyStatus: {
      [CopyStatus.Available]: '可用',
      [CopyStatus.Deleted]: '已删除',
    },
    orderStatus: {
      [OrderStatus.PaymentPending]: '支付中',
      [OrderStatus.ShipmentPending]: '待发货',
      [OrderStatus.DeliveryPending]: '待交货',
      [OrderStatus.Completed]: '已完成',
      [OrderStatus.Cancelled]: '已取消',
      [OrderStatus.RefundPending]: '待退款',
      [OrderStatus.Refunded]: '已退款',
      [OrderStatus.PartialRefunded]: '已部分退款',
      [OrderStatus.PendingCapture]: '待确认',
    },
    campaignStatus: {
      [CampaignStatus.Available]: '有效',
      [CampaignStatus.Unavailable]: '无效',
      [CampaignStatus.Expired]: '过期',
    },
    campaignType: {
      [CampaignType.Look]: '穿搭活动',
    },
    topicStatus: {
      [CampaignStatus.Available]: '有效',
      [CampaignStatus.Unavailable]: '无效',
    },
    googleMerchantStatus: {
      [GoogleMerchantStatus.Available]: '有效',
      [GoogleMerchantStatus.Delete]: '无效',
    },
    googleMerchantType: {
      [GoogleMerchantType.BrandBlackList]: '品牌黑名单',
      [GoogleMerchantType.BrandWhiteList]: '品牌白名单',
      [GoogleMerchantType.SpuIdBlackList]: '商品黑名单',
      [GoogleMerchantType.SpuIdWhiteList]: '商品白名单',
      [GoogleMerchantType.UserIdBlackList]: '用户黑名单',
      [GoogleMerchantType.UserIdWhiteList]: '用户白名单',
    },
  },
  tagsView: {
    refresh: '刷新',
    close: '关闭',
    closeOthers: '关闭其它',
    closeAll: '关闭所有'
  },
  settings: {
    title: '系统布局配置',
    theme: '主题色',
    tagsView: '开启 Tags-View',
    fixedHeader: '固定 Header',
    sidebarLogo: '侧边栏 Logo'
  },
  commons: {
    url: '链接地址'
  },
  offer: {
    createOffer: {
      confirm_model_title: '确认提交',
      confirm_model_content: '是否创建offer',
      confirm_model_success: '创建 offer 中',
      confirm_model_cancel: '取消创建'
    }
  },
  spu: {
    createSpu: {
      confirm_model_title: '确认提交',
      confirm_model_content: '是否创建商品',
      confirm_model_success: '创建商品中',
      confirm_model_cancel: '取消创建'
    },
    combine: {
      confirm_model_title: '确认提交',
      confirm_model_content: '是否合并 spuId2 到 spuId1, 并删除 spuId2',
      confirm_model_success: '合并中',
      confirm_model_cancel: '取消合并'
    }
  }
}
