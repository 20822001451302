import React from 'react';
import { CrudListColumnConfig, CrudListFormConfig, CrudListFormItemConfig } from "@/component/crud-list";
import { UserQuery, User, UserMigrateCreation, UserCreation } from "@/interface";
import { t } from "@/lang";
import { getSourceList } from "@/service";
import { ObjectUtils } from "@reversible/common/es/utils";

export const UserFilters: CrudListFormConfig<UserQuery>[] = [
  {
    name: 'username',
    label: '用户名',
    type: 'input',
  },
  {
    name: 'email',
    label: '邮箱',
    type: 'input',
  },
]

export const UserColumns: CrudListColumnConfig<User>[] = [
  {
    title: t('table.id'),
    dataIndex: 'id',
    width: 90,
  },
  {
    title: '邮箱',
    dataIndex: 'email',
    width: 120,
  },
  {
    title: '用户名',
    dataIndex: 'username',
    width: 90,
  },
  {
    title: '头像',
    dataIndex: 'avatarUrl',
    width: 90,
    display: 'image'
  },
  {
    title: '区域',
    dataIndex: 'region',
    width: 90,
  },
  {
    title: '状态',
    dataIndex: 'userStatus',
    width: 90,
    display: status => t(`value.userStatus.${status}`),
  },
  {
    title: '类型',
    dataIndex: 'userType',
    width: 90,
    display: status => t(`value.userType.${status}`),
  },
  {
    title: t('table.gender'),
    dataIndex: 'gender',
    width: 90,
  },
  {
    title: '电话',
    dataIndex: 'mobile',
    width: 90,
  },
  {
    title: 'lastName',
    dataIndex: 'lastName',
    width: 90,
  },
  {
    title: 'firstName',
    dataIndex: 'firstName',
    width: 90,
  },
];


export const userMigrateFormFields: CrudListFormItemConfig<UserMigrateCreation>[] = [
  {
    name: 'taskName',
    label: '导入平台',
    type: 'select',
    options: (keyword) => getSourceList({ keyword }),
  },
  {
    name: 'email',
    label: '商城用户邮箱',
    type: 'input',
  },
  {
    name: 'userId',
    label: '导入账号',
    type: 'input',
  },
]

export const adaptUserCreation = (data?: User): UserCreation => data ? ObjectUtils.pick(
  data,
  [
    'email',
    'username',
    'gender',
    'birthday',
    'mobile',
    'userStatus',
    'lastName',
    'firstName',
    'avatarUrl',
    'biography',
    'region',
    'userType',
    'emailVerified',
    'createTime',
    'updateTime',
  ]
) : {
  email: undefined,
  username: undefined,
  gender: undefined,
  birthday: undefined,
  mobile: undefined,
  userStatus: undefined,
  lastName: undefined,
  firstName: undefined,
  avatarUrl: undefined,
  biography: undefined,
  region: undefined,
  userType: undefined,
  emailVerified: undefined,
  createTime: undefined,
  updateTime: undefined,
}

export const userFormFields: CrudListFormConfig<UserCreation>[] = [
  {
    name: 'emailVerified',
    label: '邮箱认证',
    type: 'select',
    options: [0, 1].map(value => ({
      value,
      label: t(`value.emailVerifiedStatus.${value}`),
    }))
  },
]
