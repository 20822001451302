import { FormItem } from '@/component/crud-list/form-item';
import { DesignerSize, DesignerSizeBatchFormValue, DesignerSizeCreation, Option } from '@/interface';
import { batchPostDesignerSizes, batchPutDesignerSize, getCategory1Options, GetDesignerSize, getDesignerSize, getSizeUnits, GetSizeUnits } from '@/service';
import { CallNext, useFlow } from '@reversible/common/es/react-hooks';
import { ArrayUtils } from '@reversible/common/es/utils';
import { Form, FormItemProps, message, Modal, Select, Spin } from 'antd';
import React, { FC, useCallback, useEffect } from 'react';
import { designerBasicFormFields } from '../../config';
import styles from './batch-designer-size-modal.module.less';

const depFields: (keyof DesignerSizeBatchFormValue)[] = [
  'gender',
  'designer',
  'category1',
];

export interface BatchDesignerSizeModalProps {
  visible: boolean;
  onClose(): void;
  onShouldRefresh(): void;
}

const ALL = 'all';

interface State {
  c2s: Option[];
  prev: DesignerSize[];
  sizeUnitDict: Record<string, string[]>;
}

const formCommonProps: Partial<FormItemProps> = {
  labelAlign: 'left',
  labelCol: {
    span: 10,
  },
  className: styles.form_item
};

const C2SizeFormItem: FC<State & {
  value?: Record<string, string>;
  onChange?(value: Record<string, string>): void;
}> = ({ value, onChange, c2s, sizeUnitDict }) => {

  return (
    <>
      <Form.Item {...formCommonProps} label="批量选择" >
        <Select value={undefined} onChange={(sizeUnit) => onChange({
          ...value,
          ...Object.fromEntries(
            c2s.map(({ value: c2 }) => {
              const c2AvailableSizeUnits = sizeUnitDict[c2] || [];
              if (c2AvailableSizeUnits.includes(sizeUnit)) return [c2, sizeUnit];
              return null;
            }).filter(Boolean)
          )
        })} >
          {
            sizeUnitDict.all.map(sizeUnit => (
              <Select.Option key={sizeUnit} value={sizeUnit} >{sizeUnit}</Select.Option>
            ))
          }
        </Select>
      </Form.Item>
      {
        c2s.map(
          ({ value: c2, label }) => {
            const sizeUnits = sizeUnitDict[c2] || [];
            return (
              <Form.Item name={['designerSize', c2]} key={c2} {...formCommonProps} label={label} >
                <Select allowClear>
                  {
                    sizeUnits.map(sizeUnit => (
                      <Select.Option key={sizeUnit} value={sizeUnit} >{sizeUnit}</Select.Option>
                    ))
                  }
                </Select>
              </Form.Item>
            );
          }
        )
      }
    </>
  )
}

const INITIAL_STATE: State = {
  c2s: [],
  prev: [],
  sizeUnitDict: {},
}

export const BatchDesignerSizeModal: FC<BatchDesignerSizeModalProps> = ({
  visible,
  onClose,
  onShouldRefresh,
}) => {
  const [form] = Form.useForm<DesignerSizeBatchFormValue>();

  const [{ loading, data }, dispatch] = useFlow<State, DesignerSizeBatchFormValue>(INITIAL_STATE, function* ({ put, call }, { gender, designer, category1 }) {
    // clear anyway
    yield put(INITIAL_STATE);
    form.setFieldsValue({
      designerSize: null,
    });

    if (!gender || !designer || !category1) return;

    const { data: c1Options } = yield call(getCategory1Options, {
      gender
    });
    const c2Options = c1Options.find(({ value }) => value === category1)?.children || [];
    const initDict: Record<string, string> = Object.fromEntries(
      c2Options.map(({ value }) => [value, ''])
    );
    const { data: { list } }: CallNext<GetDesignerSize> = yield call(getDesignerSize, {
      gender,
      designer,
      category1,
      page: 1,
      pageSize: 25, // > MAX c2 length
    });
    for (const item of list) {
      initDict[item.category2] = item.sizeUnit;
    }
    form.setFieldsValue({
      designerSize: initDict,
    });
    const { data: { sizeOptions } }: CallNext<GetSizeUnits> = yield call(getSizeUnits, {
      gender,
      category1,
    });
    const allOptions = ArrayUtils.unique(
      Object.values(sizeOptions).flat()
    );
    yield put({
      c2s: c2Options,
      prev: list,
      sizeUnitDict: {
        ...sizeOptions,
        [ALL]: allOptions,
      },
    });
  });

  const onValuesChange = useCallback((changedValues: Partial<DesignerSizeBatchFormValue>, value: DesignerSizeBatchFormValue) => {
    console.log(changedValues, value)
    if (depFields.some(field => field in changedValues)) {
      dispatch(value);
    }
  }, []);

  // submittion
  const [, onFinish] = useFlow<void, DesignerSizeBatchFormValue>(null, function* ({ call, get }, { category1, designer, gender, designerSize }) {
    const c2IdDict = Object.fromEntries(
      data.prev.map((item) => [item.category2, item])
    );

    const updateList: DesignerSize[] = [];
    const createList: DesignerSizeCreation[] = [];

    for (const { value: c2 } of data.c2s) {
      if (designerSize[c2]) {
        const item: DesignerSizeCreation = {
          gender,
          designer,
          category1,
          category2: c2,
          sizeUnit: designerSize[c2],
        };

        const prevItem = c2IdDict[c2];
        if (prevItem) {
          if (item.sizeUnit !== prevItem.sizeUnit) {
            updateList.push({
              ...item,
              id: prevItem.id,
            });
          }
        } else {
          createList.push(item);
        }
      }
    }

    if (!updateList.length && !createList.length) {
      message.info('未提交任何变更');
      onClose();
      return;
    }

    const messageStrs = [];
    if (updateList.length) {
      yield call(batchPutDesignerSize, {
        designerSizes: updateList,
      });
      messageStrs.push(`更新${updateList.length}条数据`);
    }

    if (createList.length) {
      yield call(batchPostDesignerSizes, {
        designerSizes: createList,
      });
      messageStrs.push(`创建${createList.length}条数据`);
    }

    message.success(`成功${messageStrs.join('，')}`);
    onClose();
    onShouldRefresh();
  });

  useEffect(() => {
    // reset state on invisible
    if (!visible) {
      form.resetFields();
      dispatch(form.getFieldsValue());
    }
  }, [visible]);

  return (
    <Modal
      visible={visible}
      title="批量新建/编辑DesignerSize"
      onCancel={onClose}
      onOk={() => form.submit()}
    >
      <Form form={form} onValuesChange={onValuesChange} onFinish={onFinish} >
        {
          designerBasicFormFields.map(item => (
            <FormItem key={item.name} {...item} />
          ))
        }
        <Form.Item rules={[{
          required: true,
          message: '请等待二级分类数据加载完成后再提交'
        }]} required labelAlign='left' labelCol={{ span: 6 }} label="二级分类" name="designerSize" >
          {
            loading ? (
              <Spin />
            ) : data.c2s.length ? (
              <C2SizeFormItem {...data} />
            ) : '请先选择性别/分类/品牌'
          }
        </Form.Item>
      </Form>
    </Modal>
  )
}
