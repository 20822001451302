import { RichContentLink } from '@/interface/rich-content';
import { Modal } from 'antd';
import React, { FC, useCallback } from 'react';
import { CrudListFormItemConfig } from '../crud-list';
import { CreateUpdateForm } from '../crud-list/create-update-form';
import { FormModalFooter } from '../form-modal-footer';
import { RichContentModalProps } from './interface';
import { Editor, Transforms } from 'slate';
import { useSlate } from 'slate-react';
import { isLink } from './utils';

export type LinkModalProps = RichContentModalProps<RichContentLink>;

type LinkFormValues = RichContentLink;

const FORM_ID = 'rich-content-link-form';

const fields: CrudListFormItemConfig<LinkFormValues>[] = [
  {
    name: 'text',
    label: '文本',
    type: 'input'
  },
  {
    name: 'href',
    label: '链接',
    type: 'input'
  },
]

export const LinkModal: FC<LinkModalProps> = ({ data, onClose }) => {
  const isEditing = !!data.text;
  const editor = useSlate();
  const { selection } = editor;

  const onSubmit = useCallback((values: LinkFormValues) => {
    if (isEditing) {
      const match = Editor.node(editor, editor.selection);

      if (!match) return;

      if (isLink(match[0])) {
        // for prev link node
        // remove the original link first
        Transforms.delete(editor, { at: match[1] });
        // insert the new link
        if (editor.selection == null) {
          Transforms.insertNodes(editor, values);
        }
        else {
          const match_node = Editor.node(editor, editor.selection);
          if (match_node[1][0] != match[1][0]) {
            Transforms.insertNodes(editor, values, { at: match[1] });
          } else {
            Transforms.insertNodes(editor, values);
          }
        }
      } else {
        // for prev text node, insert frag
        Transforms.insertFragment(editor, [values]);
      }
    } else {
      // insert a new node
      Transforms.insertNodes(editor, values, { at: selection });
    }
    onClose()
  }, [isEditing, selection]);

  return (
    <Modal title={isEditing ? '编辑链接' : '插入链接'} visible onCancel={onClose} footer={<FormModalFooter form={FORM_ID} onCancel={onClose} />} >
      <CreateUpdateForm<LinkFormValues>
        id={FORM_ID}
        initialValues={data}
        fields={fields}
        onFinish={onSubmit}
        labelAlign="left"
        labelCol={{
          span: 3
        }}
      />
    </Modal>
  )
};
