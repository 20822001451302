import React, { FC, useEffect } from 'react';
import { Button, Form, Modal } from 'antd';
import { useFlow } from '@@/react-hooks';
import { Order } from '@/interface';
import { t } from '@/lang';
import { executeOrder, cancelOrder } from '@/service';
import { JSONDisplay } from '../../component/json-display'
import { message } from "antd";

const FORM_ID = 'user-status-form';
const CANCEL_OPERATION = 'CANCEL';
const PASS_OPERATION = 'PASS';
const VIEW_OPERATION = 'VIEW'

export interface UserStatusModalProps {
  visible: boolean;
  data: Order;
  cancel: string;
  onFinished(): void;
  onClose(): void;
}

export const UserStatusModal: FC<UserStatusModalProps> = ({
  visible,
  data,
  cancel,
  onFinished,
  onClose,
}) => {
  const [{ loading }, submitFormValues] = useFlow<void>(
    null,
    function* ({ call, block }) {
      yield block();
      try {
        if (cancel === CANCEL_OPERATION) {
          yield call(cancelOrder, {
            orderId: data.id,
          });
        }
        if (cancel === PASS_OPERATION) {
          yield call(executeOrder, {
            orderId: data.id,
          });
        }
        onFinished();
        onClose();
      }
      catch (err) {
        message.error(err?.msg);
      }
    }
  );

  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
  }, [visible]);

  return (
    cancel === VIEW_OPERATION ?
      <Modal width="50vw" title="详细信息" visible={visible} footer={null} onCancel={onClose}>
        {
          data ? (
            <JSONDisplay data={data} />
          ) : null
        }
      </Modal> :
      <Modal
        title={cancel === CANCEL_OPERATION ? '取消订单' : '确认订单'}
        visible={visible}
        onCancel={onClose}
        footer={
          <>
            <Button size="large" onClick={onClose} >
              {t('button.cancel')}
            </Button>
            <Button size="large" form={FORM_ID} htmlType="submit" type="primary" loading={loading} >
              {t('button.confirm')}
            </Button>
          </>
        }>
        <Form id={FORM_ID} form={form} onFinish={submitFormValues} >
          <div>{cancel === CANCEL_OPERATION ? '确定要取消该订单吗？' : '是否确认当前订单？'}</div>
        </Form>
      </Modal>
  )
}
