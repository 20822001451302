import React, { FC } from 'react';
import { CrudList } from '@/component/crud-list';
import { CouponExtended, CouponCreation, CouponQuery } from '@/interface';
import { getCoupons, putCoupon, deleteCoupon, postCoupon, getUsernameDict } from '@/service';
import { couponFilters, couponColumns, couponFormFields, adaptCouponCreation } from './config';
import { isPromise } from '@@/utils';

const getExtendedCoupons = async (params: CouponQuery) => {
  const dictSource = getUsernameDict();

  const [
    { data: {
      list,
      total,
    } },
    {
      data: idNameDict
    }
  ] = await Promise.all([
    getCoupons(params),
    isPromise(dictSource) ?
      dictSource.catch(
        () => ({ data: {} as Record<number, string> }),
      ) : dictSource
  ])
  return {
    data: {
      list: list.map(item => ({
        ...item,
        username: idNameDict[item.userId] || String(item.userId),
      })),
      total
    }
  }
};

const CouponList: FC = () => (
  <CrudList<CouponExtended, CouponCreation, CouponQuery>
    title="Coupon"
    rowKey="id"
    filters={couponFilters}
    read={getExtendedCoupons}
    fields={couponFormFields}
    columns={couponColumns}
    del={({ id }) => deleteCoupon({ couponId: id })}
    update={({ id }, data) => putCoupon({ couponId: id, data })}
    create={creation => postCoupon(creation)}
    adaptCreation={adaptCouponCreation}
  />
)

export default CouponList;
