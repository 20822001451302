import React, { FC, useEffect } from 'react';
import { Button, Form, Input, Modal } from 'antd';
import { useFlow } from '@@/react-hooks';
import { User } from '@/interface';
import { t } from '@/lang';
import { putWebUserStatus } from '@/service';
import { message } from "antd";

const FORM_ID = 'user-status-form';


export interface UserStatusModalProps {
  visible: boolean;
  data: User;
  del: boolean;
  onFinished(): void;
  onClose(): void;
}

export const UserStatusModal: FC<UserStatusModalProps> = ({
  visible,
  data,
  del,
  onFinished,
  onClose,
}) => {
  const [{ loading }, submitFormValues] = useFlow<void>(
    null,
    function* ({ call, block }) {
      yield block();
      try {
        yield call(putWebUserStatus, {
          userId: data.id,
          operation: del ? 'delete' : 'suspended',
        });
        onFinished();
        onClose();
        if (del) {
          message.success('请求已经提交, 大概5分钟后完成, 请不要重复点击');
        }
      }
      catch (err) {
        message.error(err?.msg);
      }
    }
  );

  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
  }, [visible]);

  return (
    <Modal
      title={del ? '删除user' : '禁用user'}
      visible={visible}
      onCancel={onClose}
      footer={
        <>
          <Button size="large" onClick={onClose} >
            {t('button.cancel')}
          </Button>
          <Button size="large" form={FORM_ID} htmlType="submit" type="primary" loading={loading} >
            {t('button.confirm')}
          </Button>
        </>
      }>
      <Form id={FORM_ID} form={form} onFinish={submitFormValues} >
        <div>{del ? '确定要删除该user吗？' : '确定要禁用该user吗？'}</div>
      </Form>
    </Modal>
  )
}
