import React, { FC, useMemo } from 'react';
import classNames from 'classnames';
import { useDrag, useDrop } from 'react-dnd';
import { ImageUploaderSrc } from '@/interface';
import { Image } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import styles from './images-uploader.module.less';

interface DroppableAreaProps {
  type: 'pre' | 'post';
  index: number;
  selectorKey: string;
  onDrop(targetIndex: number): void;
}
const DroppableArea: FC<DroppableAreaProps> = ({ type, index, selectorKey, onDrop }) => {
  const [{ isOver, canDrop }, dropRef] = useDrop(
    () => ({
      accept: selectorKey,
      collect: monitor => {
        const draggingIndex = (monitor.getItem() as DragItem)?.index;

        return {
          isOver: monitor.isOver(),
          canDrop: monitor.canDrop() && draggingIndex !== index && draggingIndex + 1 !== index,
        };
      },
      drop: (indexDropped: DragItem) => onDrop(indexDropped.index),
    }),
    [index, type, onDrop]
  );

  return (
    <div
      className={classNames(
        styles.drop_area,
        {
          [styles.drop_area_over]: isOver,
          [styles.drop_area_droppable]: canDrop,
        },
        styles[`drop_area_${type}`]
      )}
      ref={dropRef}
    />
  );
};

export interface UploadBoxProps {
  selectorKey?: string;
  source: ImageUploaderSrc;
  deletable?: boolean;
  draggable?: boolean;
  index?: number;
  onMove?(from: number, to: number): void;
  onDelete?(): void;
}

interface DragItem {
  index: number;
}

const blobWeakMap = new WeakMap<Blob, string>();

export const UploadBox: FC<UploadBoxProps> = ({
  selectorKey = '',
  index = 0,
  source,
  deletable = false,
  draggable = false,
  onDelete,
  onMove,
}) => {
  const [{ isDragging }, dragRef] = useDrag(
    {
      type: selectorKey,
      item: { index } as DragItem,
      collect: monitor => ({
        isDragging: monitor.isDragging(),
      }),
      canDrag: draggable,
    },
    [index]
  );

  const stringSource = useMemo(() => {
    if (source instanceof Blob) {
      const cachedObjectURL = blobWeakMap.get(source);
      if (cachedObjectURL) {
        return cachedObjectURL;
      }
      const objectURL = URL.createObjectURL(source);
      blobWeakMap.set(source, objectURL);
      return objectURL;
    }
    return source;
  }, [source]);

  return (
    <div
      className={classNames(styles.box, {
        [styles.box__dragging]: isDragging,
      })}
      ref={dragRef}
    >
      <Image className={styles.img} src={stringSource} />
      <div className={styles.overlays}>
        {deletable ? (
          <a className={styles.btn} onClick={onDelete}>
            <CloseOutlined />
          </a>
        ) : null}
      </div>
      {draggable ? (
        <>
          <DroppableArea selectorKey={selectorKey} index={index} type="pre" onDrop={from => onMove(from, index)} />
          <DroppableArea
            selectorKey={selectorKey}
            index={index + 1}
            type="post"
            onDrop={from => onMove(from, index + 1)}
          />
        </>
      ) : null}
    </div>
  );
};
