import React, { FC } from 'react';
import { getDesigner, postDesigner, putDesigner, deleteDesigner } from '@/service';
import { CrudList } from "@/component/crud-list";
import { adaptDesignerCreation, DesignerColumns, DesignerFilters, designerFormFields, generate_random_id } from './config';
import { Designer, DesignerQuery, DesignerCreation } from "@/interface";

const adapt = (data) => {
  if (data) {
    if (data.gender == 'men') {
      data['styles'] = typeof (data.styles) == 'string' ? { 'men': data.styles.split(',') } : data.styles
    } else {
      data['styles'] = typeof (data.styles) == 'string' ? { 'women': data.styles.split(',') } : data.styles
    }
    const randomNum = generate_random_id()
    data.logo = data.logo != undefined ? `${data.logo}?${randomNum}` : data.logo
  }
  return adaptDesignerCreation(data)
}

const DesignerList: FC = () => (
  <CrudList<Designer, DesignerCreation, DesignerQuery>
    title="designer"
    rowKey="id"
    auditable
    filters={DesignerFilters}
    columns={DesignerColumns}
    read={getDesigner}
    del={({ id }, actionConfig) => deleteDesigner({
      designerId: id,
      ...actionConfig,
    })}
    update={({ id }, creation) => putDesigner({
      designerId: id,
      data: creation
    })}
    create={postDesigner}
    adaptCreation={(data) => adapt(data)}
    fields={designerFormFields}
    tableScroll={{
      x: 960
    }}
  />
);

export default DesignerList;

