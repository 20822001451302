import { CrudListColumnConfig, CrudListFormConfig } from "@/component/crud-list";
import { AuthorityId, AUTHORITY_IDS } from "@/enum";
import { Account, AccountCreation } from "@/interface";
import { t } from "@/lang";

export const sysUserColumns: CrudListColumnConfig<Account>[] = [
  {
    title: t('table.avatar'),
    dataIndex: 'avatar',
    display: 'image',
  },
  {
    title: t('table.email'),
    dataIndex: 'email',
  },
  {
    title: t('table.userName'),
    dataIndex: 'name',
  },
  {
    title: t('table.phone'),
    dataIndex: 'phone',
  },
  {
    title: t('table.authorityId'),
    dataIndex: 'authorityId',
    display: (id) => t(`value.authorityId.${id}`),
  }
]

export const sysUserFormFields: CrudListFormConfig<AccountCreation>[] = [
  {
    name: 'avatar',
    label: t('table.avatar'),
    type: 'image',
  },
  {
    name: 'name',
    label: t('table.userName'),
    type: 'input'
  },
  {
    name: 'phone',
    label: t('table.phone'),
    type: 'input',
    optional: true,
  },
  {
    name: 'email',
    label: t('table.email'),
    type: 'input',
    rules: [{
      type: 'email',
      message: '请输入合法的邮箱地址',
    }],
  },
  {
    name: 'password',
    label: t('table.password'),
    type: 'input',
  },
  {
    name: 'authorityId',
    label: t('table.authorityId'),
    type: 'select',
    options: AUTHORITY_IDS.map(value => ({
      value,
      label: t(`value.authorityId.${value}`),
    })),
  },
];

export const adaptAccountCreation = (): AccountCreation => ({
  email: '',
  name: '',
  phone: '',
  avatar: undefined,
  isActive: true,
  authorityId: AuthorityId.Member,
  password: '',
});
